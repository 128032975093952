import _ from "lodash";
import { BSON } from "realm-web";
import {
  CO_ARCHIVED,
  CO_ARRIVEDATSTARTINGPORT,
  CO_CANCELED,
  CO_HANDLEDATCUSTOMS,
  CO_HANDLEDATWAREHOUSE,
  CO_ORDEREDATSUPPLIER,
  CO_ORDEREDBYCUSTOMER,
  CO_PERFORMINGSERVICES,
  CO_PROCESSINGATWAREHOUSE,
  CO_REQUESTEDBYCUSTOMER,
  CO_REQUESTEDSTOCK,
  CO_SHIPPEDFROMSUPPLIER,
  CO_SHIPPEDTOCUSTOMER,
  CO_SHIPPEDTOWAREHOUSE,
  CO_STATES,
  CO_T_ARCHIVED,
  CO_T_ARRIVEDATSTARTINGPORT,
  CO_T_CANCELED,
  CO_T_CHANGEDETA,
  CO_T_COMMODITIESBOOKED,
  CO_T_CREATED,
  CO_T_CREATEDFORCUSTOMER,
  CO_T_CREATEDFROMCONTRACT,
  CO_T_CUSTOMS,
  CO_T_DOCUMENTREMOVED,
  CO_T_DOCUMENTREPLACED,
  CO_T_DOCUMENTUPLOADED,
  CO_T_ORDERED,
  CO_T_REFERENCEADDED,
  CO_T_REFERENCECHANGED,
  CO_T_REQUESTAPPROVED,
  CO_T_REQUESTED,
  CO_T_REQUESTEDSTOCK,
  CO_T_REQUESTREJECTED,
  CO_T_SERVICE,
  CO_T_SERVICEADDED,
  CO_T_SERVICEPERFORMED,
  CO_T_SHIPPED,
  CO_T_SHIPPEDCUSTOMER,
  CO_T_SHIPPEDWAREHOUSE,
  CO_T_SHIPPINGINFORMATION,
  CO_T_SPLIT,
  CO_T_STOCKORDERCONFIRMED,
  CO_T_TRACKINGINFORMATION,
  CO_T_TRACKINGNOCHANGED,
  CO_T_UPDATEDCOMMODITYSNAPSHOT,
  CO_T_USESTOCK,
  CO_T_WAREHOUSE,
  CO_TIMELINE_STATE_UPDATES,
  CO_TIMELINETYPE,
  CO_TRANSPORT,
  CustomerOrder,
  CustomerOrderTerms,
  CustomerOrderTimelineEntry,
  T_AIRFREIGHT,
  T_EUSTOCK,
  T_RAILFREIGHT,
  T_ROADFREIGHT,
  T_SEAAIRCOMBINED,
  T_SEAFREIGHT,
  T_SPECIALREQUEST,
  T_WAREHOUSE,
  CO_TYPES,
  T_CONTRACT,
  CO_T_CHANGEDTARGETWEEK,
  CO_T_DESTINATIONCHANGED,
  CO_T_CALCULATIONUPDATED,
  CustomerOrderExtended,
} from "../model/customerOrder.types";
import {
  SO_ARCHIVED,
  SO_ARRIVEDATSTARTINGPORT,
  SO_CANCELED,
  SO_HANDLEDATCUSTOMS,
  SO_HANDLEDATWAREHOUSE,
  SO_ORDERCONFIRMED,
  SO_PARTIAL_DONE,
  SO_PARTIAL_STATES,
  SO_REQUESTED,
  SO_SHIPPEDFROMSUPPLIER,
  SO_SHIPPEDTOWAREHOUSE,
  SO_STATES,
  SO_T_ARCHIVED,
  SO_T_ARRIVEDATSTARTINGPORT,
  SO_T_ARRIVEDWAREHOUSE,
  SO_T_BATCHCREATED,
  SO_T_BOLCHECK,
  SO_T_CANCELED,
  SO_T_CHANGEDETA,
  SO_T_CO_CANCELED,
  SO_T_CONFIRMED,
  SO_T_CREATED,
  SO_T_CUSTOMS,
  SO_T_DOCUMENTREMOVED,
  SO_T_DOCUMENTREPLACED,
  SO_T_DOCUMENTUPLOADED,
  SO_T_GOODSINSPECTED,
  SO_T_PARTIALSHIPMENTCREATED,
  SO_T_PARTIALSHIPMENTFINISHED,
  SO_T_RETENTIONSAMPLE,
  SO_T_SHIPPED,
  SO_T_SHIPPEDWAREHOUSE,
  SO_T_TERMSUPDATED,
  SO_T_TRACKINGNOCHANGED,
  SO_T_UPDATEDCOMMODITYSNAPSHOT,
  SO_TIMELINE_STATE_UPDATES,
  SO_TIMELINETYPE,
  SupplierOrder,
  SupplierOrderTerms,
  SupplierOrderTimelineEntry,
  SO_T_BATCHATTACHED,
  SO_T_CHANGEDETD,
  SO_T_EDITED,
  SO_T_CALCULATIONUPDATED,
  SO_T_COCALCULATIONUPDATED,
  SupplierOrderExtended,
  SO_T_CALCULATION_ADJUSTED,
  SO_T_CALCULATION_RESET,
  SO_ETDCONFIRMED,
  SO_T_CONFIRMEDETD,
} from "../model/supplierOrder.types";
import { CustomerCustomerOrder, CustomerCustomerOrderExtended } from "../model/customer/customerCustomerOrder.types";
import { SupplierSupplierOrder, SupplierSupplierOrderExtended } from "../model/supplier/supplierSupplierOrder.types";
import userService from "../services/userService";
import { getCW, getWeeksBetween } from "./dateUtils";
import { DataContextInternalType, DataContextType, isInternalContext, isSupplierContext } from "../context/dataContext";
import { Supplier } from "../model/supplier.types";
import { CUSTOMERORDER, SAMPLEORDER, SUPPLIERORDER } from "../services/dbService";
import { Note, OrderTimeline } from "../model/commonTypes";
import {
  getOrderStateRanking as getSupplierOrderStateRanking,
  SO_FILETYPES,
  SO_SPECIFICATION,
  SupplierTermOptions,
} from "./supplierOrderUtils";
import {
  CO_FILETYPES,
  FALLBACKSUPPLIERPREPARATIONTIME,
  getApproximateDeliveryTime,
  getDocumentVersion,
  getFallbackDeliveryTime,
  getOrderStateRanking as getCustomerOrderStateRanking,
} from "./customerOrderUtils";
import { D_MASTERSPECIFICATION, D_SUPPLIERSPECIFICATION } from "./commodityUtils";
import { Commodity, Price, SupplierPricesExtended, UploadedFileExtended } from "../model/commodity.types";
import { CustomerCommodity } from "../model/customer/customerCommodity.types";
import { CUSTOMER, SUPPLIER } from "./userUtils";
import {
  SAMO_STATE,
  SAMO_T_ARCHIVED,
  SAMO_T_CANCELED,
  SAMO_T_CONFIRMED,
  SAMO_T_CREATED,
  SAMO_T_DOCUMENTREMOVED,
  SAMO_T_DOCUMENTUPLOADED,
  SAMO_T_RATED,
  SAMO_T_REFERENCEADDED,
  SAMO_T_REJECTED,
  SAMO_T_SHIPPED,
  SAMO_TIMELINE_STATE_UPDATES,
  SAMO_TIMELINETYPE,
  SampleOrder,
  SampleOrderExtended,
  SampleOrderTimelineEntry,
} from "../model/sampleOrder.types";
import { CustomerSampleOrder, CustomerSampleOrderExtended } from "../model/customer/customerSampleOrder.types";
import { GroupedCustomerOrder } from "../components/common/CustomTypes";
import {
  CommodityOfferRequest,
  COR_T_APPROVED,
  COR_T_CANCELED,
  COR_T_CREATED,
  COR_T_INREVIEW,
  COR_T_NOTE,
  COR_T_REJECTED,
} from "../model/commodityOfferRequest.types";
import { getOrderStateRanking as getSampleOrderStateRanking, SAMO_FILETYPES } from "./sampleOrderUtils";
import { Call, CustomerContractTerms } from "../model/customerContract.types";
import {
  CC_FILETYPES,
  CC_T_CALLCANCELED,
  CC_T_CALLED,
  CC_T_CALLFINISHED,
  CC_T_CALLSHIPPED,
  CC_T_CLOSED,
  CC_T_COMMODITYSNAPSHOTUPDATED,
  CC_T_CREATED,
  CC_T_DOCUMENTREMOVED,
  CC_T_DOCUMENTUPLOADED,
  CC_T_ORDERED,
  CC_T_READY,
  CC_T_REFERENCEADDED,
  CC_T_REFERENCECHANGED,
} from "./customerContractUtils";
import { CONTRACT_TYPES, EXTENDED_CONTRACT_TYPES } from "./contractUtils";
import { isAnyOrder } from "./contractAndOrderUtils";
import { CustomerTermOptions } from "../components/common/CustomerTerms";
import { SelectOption } from "../components/common/CustomSelect";
import { getSupplierSpecs } from "./productArticleUtils";
import { FinishedProduct, SupplierPricesFinishedProductExtended } from "../model/finishedProduct.types";
import { CustomerFinishedProduct } from "../model/customer/customerFinishedProduct.types";
import { Forwarder } from "../model/forwarder.types";
import { getDocFromCollection } from "./baseUtils";

export type INTERNAL_ORDER_TYPES = CustomerOrder | SupplierOrder | SampleOrder;
export type INTERNAL_EXTENDED_ORDER_TYPES = CustomerOrderExtended | SupplierOrderExtended | SampleOrderExtended;
export type CUSTOMER_EXTENDED_ORDER_TYPES = CustomerCustomerOrderExtended | CustomerSampleOrderExtended;
export type SUPPLIER_EXTENDED_ORDER_TYPES = SupplierSupplierOrderExtended;
export type EXTENDED_ORDER_TYPES =
  | INTERNAL_EXTENDED_ORDER_TYPES
  | CUSTOMER_EXTENDED_ORDER_TYPES
  | SUPPLIER_EXTENDED_ORDER_TYPES;
export type SUPPLIER_ORDER_TYPES =
  | SupplierOrder
  | SupplierSupplierOrder
  | SupplierOrderExtended
  | SupplierSupplierOrderExtended;
export type CUSTOMER_ORDER_TYPES =
  | CustomerOrder
  | CustomerCustomerOrder
  | CustomerOrderExtended
  | CustomerCustomerOrderExtended;
export type SAMPLE_ORDER_TYPES = SampleOrder | SampleOrderExtended | CustomerSampleOrder | CustomerSampleOrderExtended;

export type ORDER_TYPES = INTERNAL_ORDER_TYPES | CustomerCustomerOrder | SupplierSupplierOrder | CustomerSampleOrder;

export const O_FILTEROPTIONS = [
  { value: "singleCustomer", label: "Single Customer" },
  { value: "multipleCustomers", label: "Multiple Customers" },
  { value: "volumeLow", label: "Volume below 10k" },
  { value: "volumeHigh", label: "Volume above 10k" },
  { value: "inTime", label: "In Time" },
  { value: "overdue", label: "Overdue" },
  { value: "etdNotConfirmed", label: "ETD not confirmed" },
];

export const O_SORTOPTIONS = [
  { value: "orderNo", label: "Order Number" },
  { value: "createdAt", label: "Order Date" },
  { value: "commodity.title.en", label: "Commodity Name" },
  { value: "amount", label: "Volume" },
  { value: "targetDate", label: "Target Date" },
];

export const O_ORDERTYPES = [
  { value: "customer", label: "Customer Orders" },
  { value: "supplier", label: "Supplier Orders" },
  { value: "sample", label: "Sample Orders" },
];

export const O_ORDER_TYPES_WITH_CONTRACT = O_ORDERTYPES.concat({ value: "contract", label: "Customer Contracts" });

export const O_ORDERMETHODS = [
  { value: T_AIRFREIGHT, label: "Air Freight" },
  { value: T_SEAFREIGHT, label: "Sea Freight" },
  { value: T_WAREHOUSE, label: "Stock" },
  { value: T_SPECIALREQUEST, label: "Request" },
  { value: T_EUSTOCK, label: "Supplier EU Stock" },
];

export const O_TRANSPORTTYPES = [
  { value: T_AIRFREIGHT, label: "Air Freight" },
  { value: T_SEAFREIGHT, label: "Sea Freight" },
  { value: T_WAREHOUSE, label: "Stock" },
];

export const O_TRANSPORTTYPES_INTERNAL = [
  { value: T_AIRFREIGHT, label: "Air Freight" },
  { value: T_SEAFREIGHT, label: "Sea Freight" },
  { value: T_WAREHOUSE, label: "Stock" },
  { value: T_EUSTOCK, label: "Supplier EU Stock" },
  { value: T_ROADFREIGHT, label: "Road Freight" },
  { value: T_RAILFREIGHT, label: "Rail Freight" },
];

export const O_TRANSPORTTYPES_FOR_STOCK: Array<SelectOption> = [
  { value: T_AIRFREIGHT, label: "Air Freight" },
  { value: T_SEAFREIGHT, label: "Sea Freight" },
  { value: T_EUSTOCK, label: "Supplier EU Stock" },
  { value: T_ROADFREIGHT, label: "Road Freight" },
  { value: T_RAILFREIGHT, label: "Rail Freight" },
];

export const O_TRANSPORTTYPES_FORWARDINGORDER: Array<SelectOption> = [
  { value: T_SEAFREIGHT, label: "Sea" },
  { value: T_AIRFREIGHT, label: "Air" },
  { value: T_EUSTOCK, label: "Road" },
  { value: T_RAILFREIGHT, label: "Rail" },
  { value: T_SEAAIRCOMBINED, label: "Sea / Air combined" },
];

export const O_CD_ACTIVEORDERSTYPES = [
  { value: "orders", label: "Orders" },
  { value: "contracts", label: "Contracts" },
];

export const O_TAB_REQUESTED = "Requested";
export const O_TAB_ORDERREQUIRED = "Order Required";
export const O_TAB_ORDERED = "Ordered";
export const O_TAB_ORDERCONFIRMED = "Order Confirmed";
export const O_TAB_ORDERSHIPPED = "Shipped";
export const O_TAB_ORDERPROCESSING = "Processing";
export const O_TAB_ORDERDELIVERY = "Delivery";
export const O_TAB_ORDERARCHIVED = "Archived";

// in kg
export const AIR_MAX_ORDER_QUANTITY = 1000;
export const SEA_MIN_ORDER_QUANTITY = 100;

export enum ChangeDateAction {
  CHANGETARGETWEEK = "changeTargetWeek",
  CHANGEETA = "changeETA",
  CHANGEETD = "changeETD",
}

export enum DateType {
  CW = "cw",
  FIX = "fix",
}

/**
 * Get the description for the order state in which the given order is.
 * @param order Order whose state should be checked
 * @returns { title: string, subtitle: string } Description for the state
 */
export function getOrderStateDescriptions(order: ORDER_TYPES | EXTENDED_ORDER_TYPES): {
  title: string;
  subtitle: string;
} {
  const view = userService.getUserType();
  switch (order.state) {
    case CO_REQUESTEDBYCUSTOMER:
      if (view === CUSTOMER)
        return { title: "Price Requested", subtitle: "A price was requested and awaits confirmation" };
      return { title: "Special Request", subtitle: "Customer requested a price and awaits confirmation" };
    case CO_REQUESTEDSTOCK:
      return { title: "Stock requested", subtitle: "Stock was requested and awaits confirmation" };
    case CO_ORDEREDBYCUSTOMER:
      if (view === CUSTOMER)
        return { title: "Ordered", subtitle: "Order was placed and is currently prepared by our suppliers" };
      return { title: "Ordered by Customer", subtitle: "Customer requested commodities" };
    case CO_ORDEREDATSUPPLIER:
      if (view === CUSTOMER)
        return { title: "Ordered", subtitle: "Order was placed and is currently prepared by our suppliers" };
      return { title: "Ordered at Supplier", subtitle: "Commodities were ordered at the supplier" };
    case CO_ARRIVEDATSTARTINGPORT:
    case SO_ARRIVEDATSTARTINGPORT:
      if (view === "supplier")
        return { title: "Arrived at your starting point", subtitle: "Commodities arrived at starting point" };
      return { title: "Arrived at starting point", subtitle: "Commodities arrived at starting point" };
    case CO_SHIPPEDFROMSUPPLIER:
    case SO_SHIPPEDFROMSUPPLIER:
      if (view === "supplier") return { title: "Shipped from you", subtitle: "Commodities were shipped by you" };
      return { title: "Shipped from Supplier", subtitle: "Commodities were shipped by the supplier" };
    case SO_HANDLEDATCUSTOMS:
    case CO_HANDLEDATCUSTOMS:
      return { title: "Handled at Customs", subtitle: "Order is currently waiting for handling at customs" };
    case SO_SHIPPEDTOWAREHOUSE:
    case CO_SHIPPEDTOWAREHOUSE:
      return { title: "Shipped to Warehouse", subtitle: "Commodities are currently shipped to warehouse" };
    case SO_HANDLEDATWAREHOUSE:
    case CO_HANDLEDATWAREHOUSE:
      return { title: "Handled at Warehouse", subtitle: "Commodities are currently handled at warehouse" };
    case CO_PROCESSINGATWAREHOUSE:
      if (view === "customer") return { title: "Processing", subtitle: "Your order is being prepared" };
      return { title: "Processing", subtitle: "Order and services are processed" };
    case CO_PERFORMINGSERVICES:
      return { title: "Performing Services", subtitle: "Additional services are performed at warehouse" };
    case CO_SHIPPEDTOCUSTOMER:
      if (view === CUSTOMER) return { title: "Shipped to you", subtitle: "Commodities on the way to you" };
      return { title: "Shipped to Customer", subtitle: "Commodities are shipped to customer" };
    case SO_ARCHIVED:
    case CO_ARCHIVED:
    case SAMO_STATE.ARCHIVED:
      return { title: "Archived", subtitle: "Order is done" };
    case SO_CANCELED:
    case CO_CANCELED:
    case SAMO_STATE.CANCELED:
      return { title: "Canceled", subtitle: "Order was canceled" };
    case SO_REQUESTED:
      if (view === SUPPLIER)
        return {
          title: "Order Requested",
          subtitle: "An order was requested. Please confirm or reject as soon as possible",
        };
      return { title: "Order Requested", subtitle: "Order was requested at the supplier" };
    case SO_ORDERCONFIRMED:
      if (view === SUPPLIER) return { title: "Order Confirmed", subtitle: "You confirmed the order" };
      return { title: "Order Confirmed", subtitle: "Order was confirmed by the supplier" };
    case SAMO_STATE.REQUESTED:
      return { title: "Sample Requested", subtitle: "A sample of the commodity was requested" };
    case SAMO_STATE.CONFIRMED:
      return { title: "Request Confirmed", subtitle: "Request was confirmed" };
    case SAMO_STATE.SHIPPED:
      return {
        title: "Sample Shipped",
        subtitle: view === CUSTOMER ? "Sample is shipped to you" : "Sample is shipped to customer",
      };
    case SAMO_STATE.REJECTED:
      return {
        title: "Rejected",
        subtitle: "Sample order was rejected. Please check the history of the order",
      };
    default:
      return { title: "Unknown", subtitle: "" };
  }
}

/**
 * Get the description of a partial shipment state.
 * @param state State that should be resolved
 * @returns { {title: string, subtitle: string} } Title and subtitle of the state
 */
export function getPartialShipmentStateDescription(state: SO_PARTIAL_STATES): { title: string; subtitle: string } {
  const view = userService.getUserType();
  switch (state) {
    case SO_ETDCONFIRMED:
      return {
        title: "ETD was confirmed",
        subtitle: "The ETD of the shipment was confirmed",
      };
    case SO_ARRIVEDATSTARTINGPORT:
      if (view === "supplier")
        return {
          title: "Arrived at your starting point",
          subtitle: "Commodities were brought to the starting point by you",
        };
      return {
        title: "Arrived at starting point from Supplier",
        subtitle: "Commodities arrived at starting point of supplier",
      };
    case SO_SHIPPEDFROMSUPPLIER:
      if (view === "supplier")
        return {
          title: "Shipped from you",
          subtitle: "Commodities were partly shipped by you",
        };
      return { title: "Shipped from Supplier", subtitle: "Commodities were partly shipped by the supplier" };
    case SO_HANDLEDATCUSTOMS:
      return {
        title: "Handled at Customs",
        subtitle: "Partial shipment is currently waiting for handling at customs",
      };
    case SO_SHIPPEDTOWAREHOUSE:
      return { title: "Shipped to Warehouse", subtitle: "Partial shipment is currently shipped to warehouse" };
    case SO_HANDLEDATWAREHOUSE:
      return { title: "Handled at Warehouse", subtitle: "Partial shipment is currently handled at warehouse" };
    case SO_PARTIAL_DONE:
      return { title: "Done", subtitle: "Partial shipment was handled at warehouse" };
  }
}

/**
 * Filters the orders by the given tab.
 * @param orders Orders that should be selected
 * @param type Determines whether the orders are supplier or customer orders
 * @param tab Tab that the orders should be filtered for
 * @param context Data context - needed for resolving data
 * @returns { Array<T> } Filtered orders
 */
export function getOrdersForTab<T extends INTERNAL_ORDER_TYPES | ORDER_TYPES>(
  orders: Array<T>,
  type: string,
  tab: string,
  context: DataContextType
): Array<T> {
  const customer = type === "customer";
  const supplier = type === "supplier";
  switch (tab) {
    case "All":
      return orders;
    case O_TAB_ORDERREQUIRED:
      return orders.filter((fo) => fo.state === CO_ORDEREDBYCUSTOMER);
    case "Sample Requested":
      return orders.filter((fo) => fo.state === SAMO_STATE.REQUESTED);
    case O_TAB_REQUESTED:
      return orders.filter((fo) => _.includes([CO_REQUESTEDBYCUSTOMER, CO_REQUESTEDSTOCK], fo.state));
    case O_TAB_ORDERED:
      return supplier
        ? orders.filter((fo) => fo.state === SO_REQUESTED)
        : orders.filter((fo) => fo.state === CO_ORDEREDATSUPPLIER);
    case O_TAB_ORDERCONFIRMED:
      return orders.filter((fo) => fo.state === SO_ORDERCONFIRMED);
    case "Request Accepted":
      return orders.filter((fo) => _.includes([SAMO_STATE.CONFIRMED, SAMO_STATE.SHIPPED], fo.state));
    case O_TAB_ORDERSHIPPED:
      if (customer)
        return orders.filter((fo) =>
          _.includes(
            [CO_ARRIVEDATSTARTINGPORT, CO_SHIPPEDFROMSUPPLIER, CO_HANDLEDATCUSTOMS, CO_SHIPPEDTOWAREHOUSE],
            fo.state
          )
        );
      return orders.filter((fo) =>
        _.includes(
          [SO_ARRIVEDATSTARTINGPORT, SO_SHIPPEDFROMSUPPLIER, SO_HANDLEDATCUSTOMS, SO_SHIPPEDTOWAREHOUSE],
          fo.state
        )
      );
    case O_TAB_ORDERPROCESSING:
      if (customer)
        return orders.filter((fo) =>
          _.includes([CO_HANDLEDATWAREHOUSE, CO_PROCESSINGATWAREHOUSE, CO_PERFORMINGSERVICES], fo.state)
        );
      return orders.filter(
        (fo) =>
          fo.state === SO_HANDLEDATWAREHOUSE ||
          ("transport" in fo &&
            fo.transport === T_WAREHOUSE &&
            _.includes([CO_HANDLEDATWAREHOUSE, CO_PROCESSINGATWAREHOUSE, CO_PERFORMINGSERVICES], fo.state))
      );
    case O_TAB_ORDERDELIVERY:
      if (customer) return orders.filter((fo) => _.includes([CO_SHIPPEDTOCUSTOMER], fo.state));
      return orders.filter((fo) => {
        return (
          (!_.includes([SO_ARCHIVED, SO_CANCELED], fo.state) &&
            "customerOrders" in fo &&
            !isSupplierContext(context) &&
            fo.customerOrders.some(
              (cO) => getDocFromCollection(context.customerOrder, cO)?.state === CO_SHIPPEDTOCUSTOMER
            )) ||
          ("transport" in fo && fo.transport === T_WAREHOUSE && fo.state === CO_SHIPPEDTOCUSTOMER)
        ); // include warehouse orders
      });
    case O_TAB_ORDERARCHIVED:
      return orders.filter((fo) =>
        _.includes(
          [
            SO_ARCHIVED,
            CO_ARCHIVED,
            SAMO_STATE.ARCHIVED,
            CO_CANCELED,
            SO_CANCELED,
            SAMO_STATE.CANCELED,
            SAMO_STATE.REJECTED,
          ],
          fo.state
        )
      );
    default:
      return orders;
  }
}

/**
 * Determines if the given order is a customer order.
 * @param order Order that should be checked
 * @returns { boolean } Returns true if the order has type CustomerOrder
 */
export function isCustomerOrder(
  order:
    | ORDER_TYPES
    | EXTENDED_ORDER_TYPES
    | CONTRACT_TYPES
    | EXTENDED_CONTRACT_TYPES
    | CommodityOfferRequest
    | undefined
): order is CustomerOrder | CustomerOrderExtended {
  // Direct check against undefined is intended because function has to return boolean
  return order !== undefined && "noteCustomer" in order && "priceCommodities" in order && "orderNo" in order;
}

/**
 * Determines if the given order is a customer order.
 * @param order Order that should be checked
 * @returns { boolean } Returns true if the order has type CustomerOrder
 */
export function isCustomerCustomerOrder(
  order: ORDER_TYPES | CONTRACT_TYPES | EXTENDED_ORDER_TYPES | EXTENDED_CONTRACT_TYPES
): order is CustomerCustomerOrder | CustomerCustomerOrderExtended {
  return "noteCustomer" in order && !("noteInternal" in order) && "orderNo" in order;
}

/**
 * Determines if the given order is a supplier order.
 * @param order Order that should be checked
 * @returns { boolean } Returns true if the order has type SupplierOrder
 */
export function isSupplierOrder(
  order: ORDER_TYPES | EXTENDED_ORDER_TYPES | CONTRACT_TYPES | EXTENDED_CONTRACT_TYPES | undefined
): order is SupplierOrder | SupplierOrderExtended {
  // Direct check against undefined is intended because function has to return boolean
  return order !== undefined && "customerOrders" in order && "orderNo" in order;
}

/**
 * Determines if the given order is a sample order.
 * @param order Order that should be checked
 * @returns { boolean } Returns true if the order has type SampleOrder or CustomerSampleOrder
 */
export function isSampleOrder(
  order: ORDER_TYPES | EXTENDED_ORDER_TYPES | CONTRACT_TYPES | EXTENDED_CONTRACT_TYPES
): order is SAMPLE_ORDER_TYPES {
  return !("priceCommodities" in order) && "orderNo" in order;
}

/**
 * Resolve which collection the given order belongs to.
 * @param order Order whose collection should be checked
 * @returns { string | null } Name of the collection or null if order did not match valid types
 */
export function resolveOrderCollection(order: ORDER_TYPES | EXTENDED_ORDER_TYPES): string | null {
  if (isCustomerOrder(order)) return CUSTOMERORDER;
  if (isSupplierOrder(order)) return SUPPLIERORDER;
  if (isSampleOrder(order)) return SAMPLEORDER;
  return null;
}

/**
 * Build a note object from the given note string.
 * @param note Content of the note
 * @returns { Note } Note object
 */
export function getNoteObject(note: string): Note {
  return { _id: new BSON.ObjectId(), note, person: userService.getUserId().toString(), date: new Date() };
}

/**
 * Calculates information about the arrival state of the given order
 * @param order Order whose arrival state should be checked
 * @param changedETA optional, new ETA
 * @returns {{ arrivalDate: Date, dateIsCW: boolean, dateIsFix: boolean, cw: number, year: number, target: boolean, late: boolean, weekDiff: number }} Information about the arrival state
 */
export function calculateArrivalInformation(
  order: ORDER_TYPES | CONTRACT_TYPES | EXTENDED_ORDER_TYPES | EXTENDED_CONTRACT_TYPES,
  changedETA?: Date | null
): {
  arrivalDate: Date;
  dateIsCW: boolean;
  dateIsFix: boolean;
  cw: number;
  year: number;
  target: boolean;
  late: boolean;
  weekDiff: number;
} {
  let arrivalDate;
  let isTarget: boolean,
    isLate = false;
  const deliveryDate = isAnyOrder(order)
    ? !isOrderCanceled(order) &&
      ((isCustomerOrder(order) && getCustomerOrderStateRanking(order) >= 7) ||
        (isSampleOrder(order) && getSampleOrderStateRanking(order) >= 3) ||
        (isSupplierOrder(order) && getSupplierOrderStateRanking(order) >= 6))
      ? order.deliveryDate
      : null
    : order.arrivalDate;
  if (deliveryDate) {
    isTarget = false;
    isLate = !!(order.targetDate && deliveryDate.getTime() > order.targetDate.getTime());
    arrivalDate = deliveryDate;
  } else {
    // check if changedETA and targetDate differ
    if (changedETA && order.targetDate && getCW(changedETA) !== getCW(order.targetDate)) {
      // if changedETA is in the past, the order is late
      if (changedETA.getTime() < new Date().getTime()) isLate = true;
      isTarget = true;
      arrivalDate = changedETA;
    } else {
      // no changedETA was given or changedETA and targetDate did not differ
      if (order.targetDate && order.targetDate.getTime() < new Date().getTime()) isLate = true;
      isTarget = true;
      arrivalDate = order.targetDate;
    }
  }
  const cw = arrivalDate ? getCW(arrivalDate) : -1;
  const weekDiff = arrivalDate ? Math.abs(getWeeksBetween(new Date(), arrivalDate)) : NaN;
  const dateIsCW = changedETA
    ? isCustomerOrder(order) && order.changedETAType === DateType.CW
    : isCustomerOrder(order) && order.targetDateType === DateType.CW;
  const dateIsFix = changedETA
    ? isCustomerOrder(order) && order.changedETAType === DateType.FIX
    : isCustomerOrder(order) && order.targetDateType === DateType.FIX;
  return {
    arrivalDate: arrivalDate || new Date(),
    dateIsCW,
    dateIsFix,
    cw,
    year: arrivalDate ? arrivalDate.getFullYear() : new Date().getFullYear(),
    target: isTarget,
    late: isLate,
    weekDiff,
  };
}

/**
 * Groups the open orders that can be matched together.
 * @param openOrders Order that should be checked for groups. For type comparability supplier orders are valid, but are ignored
 * @returns { Array<Array<CustomerOrder | CustomerCustomerOrder>> } Grouped customer orders
 */
export function groupOpenOrders(openOrders: Array<ORDER_TYPES>): Array<Array<CustomerOrder | CustomerCustomerOrder>> {
  const groupedOpenOrders: Array<Array<CustomerOrder | CustomerCustomerOrder>> = [];
  for (let i = 0; i < openOrders.length; i++) {
    const o = openOrders[i];
    if (!isCustomerOrder(o) && !isCustomerCustomerOrder(o)) continue;
    let idx = -1;
    for (let j = 0; j < groupedOpenOrders.length; j++) {
      const oO = groupedOpenOrders[j][0];
      if (checkOrderMatch(oO, o)) {
        idx = j;
        break;
      }
    }
    if (idx !== -1) {
      groupedOpenOrders[idx].push(o);
    } else {
      groupedOpenOrders.push([o]);
    }
  }
  return groupedOpenOrders;
}

/**
 * Get all customer orders without a supplier order from context. Always returns pure CustomerOrder objects since only
 * the internal context has customer orders with a supplier orders field.
 * @param context Context that should be checked
 * @returns { Array<CustomerOrder> }
 */
export function getCustomerOrdersWithoutSupplierOrder(context: DataContextType): Array<CustomerOrder> {
  if (!isInternalContext(context)) return [];
  return context.customerOrder.filter(
    (o) =>
      o.state === CO_ORDEREDBYCUSTOMER &&
      o.transport !== T_WAREHOUSE &&
      !context.supplierOrder.some((sO) => sO.customerOrders.includes(o._id.toString()))
  );
}

/**
 * Quick check if two customer orders match
 * @param order1 an order document
 * @param order2 another order document
 * @param context Data context, if provided the suppliers orders are checked if there are already orders for
 *  the customer orders
 * @returns {boolean} true if order are equal, else false
 */
export function checkOrderMatch(
  order1: CUSTOMER_ORDER_TYPES,
  order2: CUSTOMER_ORDER_TYPES,
  context?: DataContextInternalType
): boolean {
  if (
    context &&
    context.supplierOrder.some((sO) => {
      const cOIDs = sO.customerOrders.map((cO) => cO);
      return cOIDs.includes(order1._id.toString()) || cOIDs.includes(order2._id.toString());
    })
  ) {
    return false;
  }
  return (
    order1.commodity._id.toString() === order2.commodity._id.toString() &&
    order1.transport === order2.transport &&
    order1.unit === order2.unit &&
    ((!order1.supplier && !order2.supplier) || order1.supplier === order2.supplier)
  );
}

/**
 * Get the estimated target date for a supplier order
 * @param transport optional, type of transport
 * @param supplier optional, selected supplier
 * @param price optional, best price from selected supplier
 * @param article optional, ordered commodity or finished product
 * @param etd optional, estimated time of departure from supplier, if given, preparation or lead times don't need to be considered
 * @returns {Promise<Date>} estimated target date
 */
export const getSupplierOrderTargetDate = async (
  transport?: CO_TRANSPORT,
  supplier?: Supplier,
  price?: Price,
  article?: Commodity | CustomerCommodity | FinishedProduct | CustomerFinishedProduct,
  etd?: Date
): Promise<Date> => {
  const today = new Date();
  const entry = article && article.suppliers.find((s) => s.supplier === supplier?._id.toString());
  const approxDelTimeSO = transport ? (await getApproximateDeliveryTime(transport, false)) || 1 : -1; // time needed for transport depending on type (land, air, ship), in days
  const fallbackDeliveryTime = getFallbackDeliveryTime(T_SEAFREIGHT);
  const deliveryTime = approxDelTimeSO !== -1 ? approxDelTimeSO : fallbackDeliveryTime; // use fallback if no method is given or no delivery time was found for method
  const preparationTime =
    price && price.leadTime
      ? price.leadTime // price/amount specific lead time
      : entry && entry.leadTime
      ? entry.leadTime // commodity specific lead time
      : supplier && supplier.transport.preparationTime
      ? supplier.transport.preparationTime
      : FALLBACKSUPPLIERPREPARATIONTIME;
  // if etd (estimated time of departure) is given, use only approximate delivery time since we know when the ware is ready
  // otherwise use preparation time + approximate delivery time
  if (etd) {
    const newETD = new Date(etd);
    return new Date(newETD.setDate(newETD.getDate() + deliveryTime));
  } else {
    return new Date(today.setDate(today.getDate() + preparationTime + deliveryTime));
  }
};

/**
 * Merge and sort timelines of multiple orders
 * @param orders list of supplier or customer order
 * @returns {Array<{order: O; timelineEntry: T; recent: boolean}>} list of flattened and extended timeline entries
 */
export function mergeAndSortOrderTimelines<O extends { timeline: Array<T> }, T extends { date: Date }>(
  orders: Array<O>
): Array<{ order: O; timelineEntry: T; recent: boolean }> {
  return _.orderBy(
    orders.reduce(
      (
        a: Array<{
          order: O;
          timelineEntry: T;
          recent: boolean;
        }>,
        b
      ) =>
        a.concat(
          b.timeline.map((t) => {
            return {
              order: b,
              timelineEntry: t,
              recent: new Date().getTime() - t.date.getTime() < 1000 * 60 * 60 * 2, // less than 2 hours of age
            };
          })
        ),
      []
    ),
    ["timelineEntry.date"],
    ["desc"]
  );
}

/**
 * Resolves the description of the timeline entry.
 * @param entry order timeline entry
 * @param view Optional, determines in which view this function was called (to separate between internal and others)
 * @returns { string } Description of the entry
 */
export const getTimelineEntryText = (entry: OrderTimeline, view?: string): string => {
  switch (entry.type) {
    case CO_T_REQUESTED:
      return "Price requested";
    case CO_T_REQUESTEDSTOCK:
      return "Stock requested";
    case CC_T_CREATED:
      return "Contract created";
    case SO_T_CREATED:
    case CO_T_CREATED:
    case SAMO_T_CREATED:
      return "Order created";
    case SO_T_EDITED:
      return "Order updated";
    case SO_T_CALCULATIONUPDATED:
    case CO_T_CALCULATIONUPDATED:
      return "Calculation was updated";
    case SO_T_CALCULATION_ADJUSTED:
      return `Calculation value for ${entry.payload?.type ?? "Unknown"} was overridden`;
    case SO_T_CALCULATION_RESET:
      return `Calculation value for ${entry.payload?.type ?? "Unknown"} was reset to system value`;
    case SO_T_COCALCULATIONUPDATED:
      return "Calculation of related customer order was updated";
    case CO_T_CREATEDFROMCONTRACT:
      return `Order created from contract ${entry.payload?.name ?? ""}`;
    case CC_T_READY:
      return "Contract is ready";
    case CC_T_CALLED:
      return `A Call-Off was created: ${entry.payload?.name ?? ""}`;
    case CC_T_CALLCANCELED:
      return `A Call-Off was canceled: ${entry.payload?.name ?? ""}`;
    case CC_T_CALLSHIPPED:
      return `A Call-Off was shipped to the customer: ${entry.payload?.name ?? ""}`;
    case CC_T_CALLFINISHED:
      return `A Call-Off was finished: ${entry.payload?.name ?? ""}`;
    case CO_T_CREATEDFORCUSTOMER:
      return view === CUSTOMER ? "Order was created for you" : "Order was created for customer";
    case CO_T_STOCKORDERCONFIRMED:
      return "Stock Order was confirmed";
    case SO_T_CONFIRMED:
    case CO_T_ORDERED:
    case CC_T_ORDERED:
      return "Article was ordered";
    case CO_T_USESTOCK:
      return "Order will be fulfilled by stock";
    case SAMO_T_CONFIRMED:
      return "Sample Order was confirmed";
    case SO_T_CONFIRMEDETD:
      return "ETD was confirmed";
    case SO_T_ARRIVEDATSTARTINGPORT:
    case CO_T_ARRIVEDATSTARTINGPORT:
      return "Order arrived at starting seaport.";
    case SO_T_TRACKINGNOCHANGED:
    case CO_T_TRACKINGNOCHANGED:
      return "Tracking number changed";
    case SAMO_T_SHIPPED:
      return "Sample Order was shipped";
    case SO_T_SHIPPED:
    case CO_T_SHIPPED:
      return "Order was shipped from supplier";
    case SO_T_CUSTOMS:
    case CO_T_CUSTOMS:
      return "Shipment arrived at customs";
    case SO_T_SHIPPEDWAREHOUSE:
    case CO_T_SHIPPEDWAREHOUSE:
      return "Shipment is on the way to the warehouse";
    case SO_T_ARRIVEDWAREHOUSE:
    case CO_T_WAREHOUSE:
      return "Shipment arrived at warehouse";
    case SO_T_ARCHIVED:
    case CO_T_ARCHIVED:
    case SAMO_T_ARCHIVED:
      return "Order was archived";
    case SO_T_CANCELED:
    case CO_T_CANCELED:
    case SAMO_T_CANCELED:
      return "Order was canceled";
    case SAMO_T_RATED:
      return "Sample was rated";
    case CC_T_CLOSED:
      return "Contract was closed";
    case SO_T_DOCUMENTUPLOADED:
    case CO_T_DOCUMENTUPLOADED:
    case SAMO_T_DOCUMENTUPLOADED:
    case CC_T_DOCUMENTUPLOADED:
      return `Document was uploaded: ${getDocumentName(entry)}`;
    case SO_T_DOCUMENTREPLACED:
    case CO_T_DOCUMENTREPLACED:
      return `Document was replaced: ${getDocumentName(entry)}`;
    case SO_T_DOCUMENTREMOVED:
    case CO_T_DOCUMENTREMOVED:
    case SAMO_T_DOCUMENTREMOVED:
    case CC_T_DOCUMENTREMOVED:
      return `Document was removed: ${getDocumentName(entry)}`;
    case SO_T_TERMSUPDATED:
      return "Order terms updated";
    case SO_T_BATCHCREATED:
      return "Batch was created";
    case SO_T_BATCHATTACHED:
      return "Batch was attached";
    case SO_T_GOODSINSPECTED:
      return `Goods were marked as ${entry.payload?.checked ? "inspected" : "not inspected"}`;
    case SO_T_RETENTIONSAMPLE:
      return `Retention sample was marked as ${entry.payload?.checked ? "taken" : "not taken"}`;
    case SO_T_BOLCHECK:
      return `BOL was marked as ${entry.payload?.checked ? "checked" : "not checked"}`;
    case CO_T_SERVICE:
      return "Services are being performed";
    case CO_T_SERVICEPERFORMED:
      return `Service ${entry.payload ? _.upperFirst(entry.payload?.name) : "Unknown"} was performed`;
    case CO_T_SHIPPINGINFORMATION:
      return "Shipping information was updated";
    case CO_T_TRACKINGINFORMATION:
      return "Tracking information was updated";
    case CO_T_SERVICEADDED:
      return `Service ${entry.payload ? _.upperFirst(entry.payload?.name) : "Unknown"}  was booked`;
    case SO_T_PARTIALSHIPMENTCREATED:
      return `A partial shipment is on its way`;
    case SO_T_PARTIALSHIPMENTFINISHED:
      return `A partial shipment arrived at the warehouse`;
    case SO_T_CO_CANCELED:
      return `Customer order ${entry.payload?.name || ""} was canceled and removed`;
    case CO_T_COMMODITIESBOOKED:
      return `Batches were booked out. Shipping is imminent`;
    case CO_T_REQUESTAPPROVED:
      return "Requested price approved";
    case CO_T_SPLIT:
      return "Order was split into two orders";
    case CO_T_REQUESTREJECTED:
    case SAMO_T_REJECTED:
      return `Request rejected: Reason: ${entry.payload?.reason || ""}`;
    case CO_T_SHIPPEDCUSTOMER:
      return "Order was shipped to the customer";
    case SO_T_CHANGEDETA:
    case CO_T_CHANGEDETA:
      return `ETA changed. Reason: ${entry.payload?.reason || ""}`;
    case SO_T_CHANGEDETD:
      return `ETD changed. Reason: ${entry.payload?.reason || ""}`;
    case CO_T_CHANGEDTARGETWEEK:
      return `Original target date changed. Reason: ${entry.payload?.reason || ""}`;
    case SO_T_UPDATEDCOMMODITYSNAPSHOT:
    case CO_T_UPDATEDCOMMODITYSNAPSHOT:
    case CC_T_COMMODITYSNAPSHOTUPDATED:
      return "Article snapshot was updated";
    case CO_T_REFERENCECHANGED:
    case CC_T_REFERENCECHANGED:
      return `Customer reference changed. Reason: ${entry.payload?.reason || ""}`;
    case CO_T_DESTINATIONCHANGED:
      return `Destination changed: ${entry.payload?.reason || ""}`;
    case COR_T_CREATED:
      return "Article order request created";
    case COR_T_CANCELED:
      return "Article order request canceled";
    case COR_T_INREVIEW:
      return "Article order request in review";
    case COR_T_REJECTED:
      return `Article order request rejected. Reason: ${entry.payload?.reason || ""}`;
    case COR_T_APPROVED:
      return "Article order request approved";
    case COR_T_NOTE:
      return "Note of article order request adjusted";
    case CO_T_REFERENCEADDED:
    case CC_T_REFERENCEADDED:
    case SAMO_T_REFERENCEADDED:
      return `${view === CUSTOMER ? "You" : "The customer"} added a reference`;
    default:
      return "Unknown action";
  }
};

/**
 * Get document name for a timeline entry
 * @param entry order timeline entry
 * @returns {string} file name
 */
export const getDocumentName = (entry: OrderTimeline): string => {
  let fileName = "Unknown";
  if (entry.payload && entry.payload.type) {
    const fileType = SO_FILETYPES.concat(CO_FILETYPES)
      .concat(SAMO_FILETYPES)
      .concat(CC_FILETYPES)
      .find((ft) => ft.value === entry.payload?.type);
    if (fileType) fileName = fileType.label;
  }
  return fileName;
};

/**
 * Collect files related to an order
 * @param order any order
 * @returns {Array<{ _id: string; title: string; date: Date; link: string; version: number}>} list of prepared files
 */
export const collectFilesForOrder = (
  order: EXTENDED_ORDER_TYPES
): Array<{ _id: string; title: string; date: Date; link: string; version: number }> => {
  let files: Array<{
    _id: string;
    title: string;
    type: string;
    date: Date;
    link: string;
    version: number;
  }> = [];

  let supplierSpecs: Array<UploadedFileExtended> = [];
  let masterSpec;

  if (order.commodity.documents.length > 0) {
    masterSpec = order.commodity.documents.find((d) => d.type === D_MASTERSPECIFICATION);
    if ((isCustomerOrder(order) || isCustomerCustomerOrder(order)) && order.supplier) {
      supplierSpecs = getSupplierSpecs(order.commodity, order.supplier._id.toString());
    }
  }
  let specFound = false;
  files = files.concat(
    order.files.map((f) => {
      if (!specFound && f.type === SO_SPECIFICATION) specFound = true;
      const title =
        (isCustomerOrder(order) ? CO_FILETYPES : SO_FILETYPES).find((ft) => ft.value === f.type)?.label ?? "Unknown";
      return {
        _id: f._id.toString(),
        title,
        type: f.type,
        date: f.date,
        link: f.path,
        version: getDocumentVersion(order, f.date, f.type),
      };
    })
  );

  // If supplier specs were not overwritten with an uploaded spec, push supplier specs to front
  // If no supplier specs exist, push master specification to front
  if (!specFound && supplierSpecs.length > 0) {
    supplierSpecs.forEach((supplierSpec) => {
      files.unshift({
        _id: supplierSpec._id.toString(),
        title: `Specification ${order.commodity.title.en}`,
        type: D_SUPPLIERSPECIFICATION,
        date: order.createdAt,
        link: supplierSpec.path,
        version: 1,
      });
    });
  } else if (!specFound && masterSpec) {
    files.unshift({
      _id: masterSpec._id.toString(),
      title: `Specification ${order.commodity.title.en}`,
      type: D_MASTERSPECIFICATION,
      date: order.createdAt,
      link: masterSpec.path,
      version: 1,
    });
  }
  return files;
};

/**
 * Get the fastest delivery of all suppliers given. If a supplier is provided the fastest of that one is returned.
 * @param suppliers List of suppliers
 * @param supplier Optional, if given only the delivery times of the supplier are checked
 * @returns { number } Fastest delivery
 */
export function getFastestDelivery(
  suppliers: Array<SupplierPricesExtended | SupplierPricesFinishedProductExtended>,
  supplier?: string
): number {
  if (supplier) suppliers = suppliers.filter((s) => s._id.toString() === supplier);
  return suppliers.reduce((min, sp) => {
    const minSDT = sp.supplier.transport.preparationTime;
    return min < minSDT ? min : minSDT;
  }, Infinity);
}

/**
 * Get order number including prefix
 * @param order any order document
 * @param request optional boolean indicates if number should be handled as request or not
 * @returns {string} order number including prefix
 */
export function getOrderNumber(order: ORDER_TYPES | EXTENDED_ORDER_TYPES | Call, request?: boolean): string {
  return `${"transport" in order && "request" in order && order.request && request ? "RBR" : "RB"}-${order.orderNo}`;
}

/**
 * Get the timeline entry from the last state update of an order
 * @param order any order
 * @returns {CustomerOrderTimelineEntry | SampleOrderTimelineEntry | SupplierOrderTimelineEntry | undefined} last state update timeline entry
 */
export function getLastStateUpdate(
  order: ORDER_TYPES | EXTENDED_ORDER_TYPES
): CustomerOrderTimelineEntry | SampleOrderTimelineEntry | SupplierOrderTimelineEntry | undefined {
  const timeline = [...order.timeline].reverse();
  if (isSampleOrder(order))
    return timeline.find((t) => SAMO_TIMELINE_STATE_UPDATES.includes(t.type as SAMO_TIMELINETYPE));
  else if (isCustomerOrder(order))
    return timeline.find((t) => CO_TIMELINE_STATE_UPDATES.includes(t.type as CO_TIMELINETYPE));
  else return timeline.find((t) => SO_TIMELINE_STATE_UPDATES.includes(t.type as SO_TIMELINETYPE));
}

/**
 * Get the progress for an order
 * @param order any order document
 * @returns {[percent: number, variant: string ]} tuple of percentage and variant for progressbar
 */
export function getOrderProgress(order: ORDER_TYPES): [percent: number, variant: string] {
  const isSupplier = isSupplierOrder(order);
  switch (order.state) {
    case CO_REQUESTEDBYCUSTOMER:
    case CO_REQUESTEDSTOCK:
    case SO_REQUESTED:
    case SAMO_STATE.REQUESTED:
      return [0, "warning"];
    case CO_ORDEREDBYCUSTOMER:
      return [10, "warning"];
    case CO_ORDEREDATSUPPLIER:
    case SO_ORDERCONFIRMED:
      return [25, "warning"];
    case SO_ARRIVEDATSTARTINGPORT:
      return [30, "success"];
    case CO_ARRIVEDATSTARTINGPORT:
      return [25, "warning"];
    case CO_SHIPPEDFROMSUPPLIER:
    case SO_SHIPPEDFROMSUPPLIER:
      if (isSupplier) return [50, "success"];
      return [30, "success"];
    case CO_HANDLEDATCUSTOMS:
    case SO_HANDLEDATCUSTOMS:
      if (isSupplier) return [60, "success"];
      return [35, "success"];
    case CO_SHIPPEDTOWAREHOUSE:
    case SO_SHIPPEDTOWAREHOUSE:
      if (isSupplier) return [70, "success"];
      return [40, "success"];
    case SO_HANDLEDATWAREHOUSE:
    case SAMO_STATE.CONFIRMED:
      if (isSupplier) return [85, "success"];
      return [50, "success"];
    case CO_PROCESSINGATWAREHOUSE:
    case CO_PERFORMINGSERVICES:
    case CO_HANDLEDATWAREHOUSE:
      return [75, "success"];
    case CO_SHIPPEDTOCUSTOMER:
    case SAMO_STATE.SHIPPED:
      return [90, "success"];
    case CO_ARCHIVED:
    case SO_ARCHIVED:
    case SAMO_STATE.ARCHIVED:
      return [100, "success"];
    case CO_CANCELED:
    case SO_CANCELED:
    case SAMO_STATE.CANCELED:
    case SAMO_STATE.REJECTED:
      return [100, "danger"];
    default:
      return [0, "warning"];
  }
}

/**
 * Build payment terms together to display them properly.
 * @param termOptions Terms of a customer order
 * @returns {string} Prepared payment terms
 */
export function getCOPaymentTermsToDisplay(termOptions: CustomerOrderTerms): string {
  return termOptions.paymentTerms + " " + termOptions.paymentTermConditions;
}

/**
 * Get formatted customer order terms for backend out of select and custom values
 * @param termOptions CustomerTermOptions values of the terms to reformat
 * @returns {CustomerOrderTerms} the terms object for the customer order
 */
export function getFormattedCOTerms(termOptions: CustomerTermOptions): CustomerOrderTerms {
  return {
    paymentTerms:
      termOptions.paymentTerm.value === "custom"
        ? termOptions.customPaymentTerm.trim() !== "" && termOptions.customPaymentTerm !== "0"
          ? `${termOptions.customPaymentTerm} days`
          : ""
        : termOptions.paymentTerm.label,
    paymentTermConditions: termOptions.customPaymentTermCondition,
    deliveryTerms: termOptions.deliveryTerm.label,
    note: termOptions.note,
    deliveryCity: termOptions.deliveryCity,
    cleanLabel: termOptions.cleanLabel,
  };
}

/**
 * Get formatted customer contract terms for backend out of select and custom values
 * @param termOptions CustomerTermOptions values of the terms to reformat
 * @returns {CustomerContractTerms} the terms object for the customer contract
 */
export function getFormattedCCTerms(termOptions: CustomerTermOptions): CustomerContractTerms {
  return {
    paymentTerms:
      termOptions.paymentTerm.value === "custom"
        ? termOptions.customPaymentTerm.trim() !== "" && termOptions.customPaymentTerm !== "0"
          ? `${termOptions.customPaymentTerm} days`
          : ""
        : termOptions.paymentTerm.label,
    paymentTermConditions: termOptions.customPaymentTermCondition,
    deliveryTerms: termOptions.deliveryTerm.label,
    note: termOptions.note,
    deliveryCity: termOptions.deliveryCity,
  };
}

/**
 * Get formatted supplier order terms for backend out of select and custom values
 * @param termOptions SupplierTermOptions values of the terms to reformat
 * @returns {SupplierOrderTerms} the terms object for the supplier order
 */
export function getFormattedSOTerms(termOptions: SupplierTermOptions): SupplierOrderTerms {
  return {
    paymentTerms:
      termOptions.paymentTerm.value === "custom"
        ? termOptions.customPaymentTerm.trim() !== "" && termOptions.customPaymentTerm !== "0"
          ? `${termOptions.customPaymentTerm} days`
          : ""
        : termOptions.paymentTerm.label,
    paymentTermConditions: termOptions.customPaymentTermCondition,
    deliveryTerms: termOptions.deliveryTerm.label,
    notify: termOptions.notify._id.toString(),
  };
}

/**
 * Check if an order is a grouped customer order
 * @param order Order that should be checked
 * @returns { boolean } Returns true if the order has type GroupedCustomerOrder
 *  */
export function isGroupedOrder(order: ORDER_TYPES | GroupedCustomerOrder): order is GroupedCustomerOrder {
  return "orders" in order && "totalAmount" in order;
}

/**
 * Get the order type
 * @param order a customer or supplier order
 * @returns {string} the order type
 */
export function getOrderType(order: CUSTOMER_ORDER_TYPES | SUPPLIER_ORDER_TYPES): string {
  const isCustomer = userService.getUserType() === CUSTOMER;
  const type =
    "request" in order && order.request
      ? T_SPECIALREQUEST
      : isCustomer && order.transport === T_EUSTOCK
      ? T_WAREHOUSE
      : order.transport;
  return O_ORDERMETHODS.find((ot) => ot.value === type)?.label || _.upperFirst(order.transport);
}

/**
 * Check if an order is inactive, i.e. archived or canceled
 * @param order any order document
 * @returns {boolean} true if order is inactive, else false
 */
export function isOrderInactive(order: ORDER_TYPES): boolean {
  return (
    [
      CO_ARCHIVED,
      CO_CANCELED,
      SAMO_STATE.CANCELED,
      SAMO_STATE.ARCHIVED,
      SAMO_STATE.REJECTED,
      SO_CANCELED,
      SO_ARCHIVED,
    ] as Array<CO_STATES | SAMO_STATE | SO_STATES>
  ).includes(order.state);
}

/**
 * Check if an order is canceled or rejected
 * @param order any order document
 * @returns {boolean} true if order is canceled, else false
 */
export function isOrderCanceled(order: ORDER_TYPES | EXTENDED_ORDER_TYPES): boolean {
  return (
    [CO_CANCELED, SAMO_STATE.CANCELED, SAMO_STATE.REJECTED, SO_CANCELED] as Array<CO_STATES | SAMO_STATE | SO_STATES>
  ).includes(order.state);
}

/**
 * Resolves the label for a given transport method
 * @param transportMethod the transport method
 * @returns {string | undefined} the matching label or undefined if no label for the given transport method was found
 */
export function getInternalTransportTypeLabel(transportMethod: CO_TRANSPORT | string): string | undefined {
  const transport = O_TRANSPORTTYPES_INTERNAL.find((obj) => obj.value === transportMethod);
  return transport?.label;
}

/**
 * Resolves the forwarder for a given order by checking if a forwarding order including the given order exists and if a forwarder is given there
 * @param context Data context for resolving forwarders and forwardingOrders
 * @param order the supplier or customer order to check
 * @returns {Forwarder | undefined} the matching forwarder if found; undefined if context was not internal or no forwarder could be found or resolved
 */
export function getForwarderFromOrder(
  context: DataContextType,
  order: SUPPLIER_ORDER_TYPES | CUSTOMER_ORDER_TYPES
): Forwarder | undefined {
  if (!isInternalContext(context)) return undefined;
  const forwardingOrder = context.forwardingOrder.find((fwo) =>
    fwo.orderInformation.some((oI) => oI.orderId === order._id.toString())
  );
  return forwardingOrder?.forwarder
    ? context.forwarder.find((f) => f._id.toString() === forwardingOrder.forwarder)
    : undefined;
}

/**
 * Resolves the name of the given order type.
 * @param method Order Type
 * @returns {string}
 */
export function getOrderTypeName(method: CO_TYPES): string {
  switch (method) {
    case T_SEAFREIGHT:
      return "Seafreight";
    case T_AIRFREIGHT:
      return "Airfreight";
    case T_WAREHOUSE:
      return "Warehouse";
    case T_EUSTOCK:
      return "EU Stock";
    case T_CONTRACT:
      return "Contract";
    case T_SPECIALREQUEST:
      return "Special Request";
    default:
      return "";
  }
}
