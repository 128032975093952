import { formatDate, getDocFromCollection } from "./baseUtils";
import { I_SUPPLIERINVOICE, Invoice } from "../model/invoice.types";
import { Commodity } from "../model/commodity.types";
import { PropertyType, resolveProperties } from "./propertyUtils";
import { SelectOption } from "../components/common/CustomSelect";
import { Company } from "../model/company.types";
import { Supplier } from "../model/supplier.types";
import { Seaport } from "../model/seaport.types";
import { getDefaultAddress } from "./addressUtils";
import { AddressType } from "../model/commonTypes";
import { DataContextInternalType, DataContextType } from "../context/dataContext";
import { getUserName } from "./userUtils";

export const DEFAULTSEPARATOR = ";";

const INVOICE_HEADERS = [
  "Rechnungsnummer",
  "Betrag Brutto gruppiert nach USt.",
  "Debitorennummer",
  "Buchungskonto",
  "Erstelldatum Rechnung",
  "Fälligkeitsdatum Rechnung",
  "Währung",
  "RA Firma",
];

const COMMODITY_HEADERS = [
  "_id",
  "Name",
  "Duty",
  "Category",
  "Content / Purity",
  "Specific Rotation",
  "Production / Extraction Solvent",
  "HS-Code",
  "CAS-Number",
  "Novel Food",
  "CITES",
  "ECHA",
  "Hazardous",
  "Botanical Name",
  "Suitable for vegans / vegetarians",
  "Allergens",
  "Halal",
  "Kosher",
  "Bulk Density",
  "Part",
  "Grade",
  "Particle Size",
  "Analysis Method",
  "Appearance",
  "Odor",
  "Containing the max allowed Heavy metals / tolerance",
  "Containing the max allowed Microbiology / tolerance",
  "Containing the max allowed ETO (Ethylenoxid) / tolerance",
  "Loss on drying",
  "Ash",
  "Active Substances",
  "Content of active substance",
  "Shelf Life",
  "Limits",
  "Storage Conditions",
  "Transport Condition Types",
  "Transport Conditions",
  "Packaging",
  "Total residual organic solvents",
  "Aflatoxins B1,B2,G1,G2",
  "Regulatory Data+Y:YY2Y:ZY:ZY:AAY2Y:Z",
  "PAH 4",
  "Benzopyrene",
  "Carrier",
  "Does the raw material contain or is it derived from or could it be cross-contaminated by any of the following ingredients",
  "Ratio Extract",
  "Origin Country",
  "Organic",
  "BTI Reference Number",
  "German VAT Rate",
];

const CUSTOMER_HEADERS = [
  "Name",
  "Internal Contact",
  "Rating",
  "VAT",
  "Credit Limit",
  "Payment Target",
  "Mail",
  "Phone",
  "Address Name",
  "Address Street",
  "Address HouseNo",
  "Address Postal Code",
  "Address City",
  "Address Country",
  "Primary Person",
  "Notes",
];

const SUPPLIER_HEADERS = [
  "Name",
  "Internal Contact",
  "Rating",
  "Currency",
  "VAT",
  "Mail",
  "Phone",
  "Address Name",
  "Address Street",
  "Address HouseNo",
  "Address Postal Code",
  "Address City",
  "Address Country",
  "Primary Person",
  "Notes",
];

const SEAPORT_HEADERS = ["LOCODE", "Name", "Country", "Cost in $", "20' Cont.", "40' Cont.", "40' HC Cont."];

export const ALLOWED_SEPARATORS: Array<SelectOption> = [
  { value: ";", label: ";" },
  { value: ",", label: "," },
];

/**
 * Generates for the given invoices a csv string.
 * @param invoices Invoices that should be exported
 * @param context Data context - needed to resolve supplier or company
 * @returns { string } Invoices in CSV format
 */
export function generateInvoicesCSVString(invoices: Array<Invoice>, context: DataContextInternalType): string {
  const content: Array<Array<string>> = [];
  for (let i = 0; i < invoices.length; i++) {
    const inv = invoices[i];
    const data: Array<string> = [];
    data.push(inv.invoiceNumber);
    data.push(inv.total.toString());
    data.push(""); // This field is inserted by tax consultant later
    data.push(""); // This field is inserted by tax consultant later
    data.push(formatDate(inv.invoiceDate));
    const paymentDate = inv.invoiceDate;
    paymentDate.setDate(paymentDate.getDate() + inv.paymentTarget);
    data.push(formatDate(paymentDate));
    data.push(inv.currency);
    const company = getDocFromCollection<Supplier | Company>(
      inv.type === I_SUPPLIERINVOICE ? context.supplier : context.company,
      inv.company._id
    );
    data.push(company ? company.name : "Unknown");
    content.push(data);
  }
  return generateCSVString(INVOICE_HEADERS, content, DEFAULTSEPARATOR, true);
}

/**
 * Generates for the given commodities a csv string.
 * @param commodities Commodities that should be exported as csv
 * @param context the context to resolve transportConditions and properties
 * @returns { string } csv as string
 */
export function generateCommoditiesCSVString(commodities: Array<Commodity>, context: DataContextType): string {
  const content: Array<Array<string>> = [];
  for (let i = 0; i < commodities.length; i++) {
    const c = commodities[i];
    const resolvedProperties = resolveProperties(c.properties, context.property);
    const category = resolvedProperties.find((p) => p.type === PropertyType.CATEGORY);
    const solvent = resolvedProperties.find((p) => p.type === PropertyType.SOLVENT);
    const analysisMethod = resolvedProperties.find((p) => p.type === PropertyType.ANALYSISMETHOD);
    const odor = resolvedProperties.find((p) => p.type === PropertyType.ODOR);
    const packaging = resolvedProperties.find((p) => p.type === PropertyType.PACKAGING);
    const carrier = resolvedProperties.find((p) => p.type === PropertyType.CARRIER);
    const allergens = resolvedProperties.filter((p) => p.type === PropertyType.ALLERGEN).map((a) => a.name.en);
    const grade = [];
    if (c.foodGrade) grade.push("Food Grade");
    if (c.pharmaceuticalGrade) grade.push("Pharmaceutical Grade");
    const aS = [];
    const aSContent = [];
    for (let j = 0; j < c.activeSubstances.length; j++) {
      const a = c.activeSubstances[j];
      const activeSubstance = getDocFromCollection(context.activeSubstance, a.substance);
      if (activeSubstance) {
        aS.push(activeSubstance.name.en);
        aSContent.push(a.percentage + "%");
      }
    }
    const transportConditions = [];
    const transportConditionsTypes = [];
    if (c.transportConditions) {
      for (let j = 0; j < c.transportConditions.length; j++) {
        const tc = c.transportConditions[j];
        const transportCondition = context.property.find((p) => p._id.toString() === tc.property);
        if (transportCondition) {
          transportConditions.push(`${transportCondition.name.en}: ${transportCondition.description.en}`);
          transportConditionsTypes.push(tc.type);
        }
      }
    }

    const data: Array<string> = [];
    data.push(c._id.toString());
    data.push(c.title.en.replaceAll(/(\r\n|\r|\n)/g, " "));
    data.push(c.duty.percentage + "%" + (c.duty.dumpingFee ? " + " + c.duty.dumpingFee + " EUR" : ""));
    data.push(category ? category.name.en : "");
    data.push(c.purity.en.replaceAll(/(\r\n|\r|\n)/g, " "));
    data.push(c.specificRotation.en.replaceAll(/(\r\n|\r|\n)/g, " "));
    data.push(solvent ? solvent.name.en : "");
    data.push(c.hsCode);
    data.push(c.casNumber.join(" / "));
    data.push(c.novelFood ? "Yes" : "No");
    data.push(c.cites ? "Yes" : "No");
    data.push(c.echa ? "Yes" : "No");
    data.push(c.hazardMaterial ? "Yes" : "No");
    data.push(c.botanicalName.replaceAll(/(\r\n|\r|\n)/g, " "));
    data.push(c.vegan ? "Yes" : "No"); // Born Excel just has a single field for vegan/vegetarian
    data.push(allergens.join(", "));
    data.push(c.halal ? "Yes" : "No");
    data.push(c.kosher ? "Yes" : "No");
    data.push(c.density.min + "-" + c.density.max + "kg/m³");
    data.push(c.part.en.replaceAll(/(\r\n|\r|\n)/g, " "));
    data.push(grade.join(","));
    data.push(c.particleSize.en.replaceAll(/(\r\n|\r|\n)/g, " "));
    data.push(analysisMethod ? analysisMethod.name.en : "");
    data.push(c.appearance.en.replaceAll(/(\r\n|\r|\n)/g, " "));
    data.push(odor ? odor.name.en : "");
    data.push(c.maxAllowedHeavyMetals.en.replaceAll(/(\r\n|\r|\n)/g, " "));
    data.push(c.maxAllowedMicrobiology.en.replaceAll(/(\r\n|\r|\n)/g, " "));
    data.push(c.maxAllowedWETO.en.replaceAll(/(\r\n|\r|\n)/g, " "));
    data.push((c.lossOnDrying.lessThan ? "≤ " : "") + c.lossOnDrying.amount);
    data.push((c.ash.lessThan ? "≤ " : "") + c.ash.amount);
    data.push(aS.join(","));
    data.push(aSContent.join(","));
    data.push(c.shelfLife + " Months");
    data.push(c.limits.en.replaceAll(/(\r\n|\r|\n)/g, " "));
    data.push(c.storageConditions.en.replaceAll(/(\r\n|\r|\n)/g, " "));
    data.push(transportConditionsTypes.join(",").replaceAll(/(\r\n|\r|\n)/g, " "));
    data.push(transportConditions.join(",").replaceAll(/(\r\n|\r|\n)/g, " "));
    data.push(packaging ? packaging.name.en : "");
    data.push(c.totalResidualOrganicSolvents.en.replaceAll(/(\r\n|\r|\n)/g, " "));
    data.push(c.aflatoxins.en.replaceAll(/(\r\n|\r|\n)/g, " "));
    data.push(c.regulatoryData.en.replaceAll(/(\r\n|\r|\n)/g, " "));
    data.push(c.pah4.en.replaceAll(/(\r\n|\r|\n)/g, " "));
    data.push(c.benzoypyrene.en.replaceAll(/(\r\n|\r|\n)/g, " "));
    data.push(carrier ? carrier.name.en : "");
    data.push(c.possibleCrossContamination.en.replaceAll(/(\r\n|\r|\n)/g, " "));
    data.push(c.ratioExtract ?? "");
    data.push(c.country.code);
    data.push(c.organic ? "Yes" : "No");
    data.push(c.btiRefNo ? c.btiRefNo : "-");
    data.push(c.vatPercentage ? c.vatPercentage.toString() : "-");
    content.push(data);
  }
  return generateCSVString(COMMODITY_HEADERS, content, DEFAULTSEPARATOR, true);
}

/**
 * Generates for the given customers a csv string.
 * @param customers Customers that should be exported
 * @param context Data context - needed to resolve users
 * @param exampleMode Optional, if set the examples are returned
 * @returns { string } Customers as csv
 */
export function generateCustomersCSVString(
  customers: Array<Company>,
  context: DataContextInternalType,
  exampleMode?: boolean
): string {
  const content: Array<Array<string>> = [];
  if (exampleMode) {
    content.push([
      "Iffland Holding",
      "Fabian Geisler",
      "5",
      "DE-3256346",
      "400000",
      "14 Days",
      "ci@private-label-factory.com",
      "0172666666",
      "",
      "Wörthstr.",
      "33",
      "45894",
      "Gelsenkirchen",
      "Germany",
      "Christian Iffland",
      "Really nice customer!",
    ]);
    content.push([
      "Haag Handschuh Näherei",
      "Fabian Geisler",
      "0",
      "DE-2353463",
      "0",
      "In Advance",
      "jh@novacode.dev",
      "01720101010",
      "",
      "Einsteinstr.",
      "55",
      "89077",
      "Ulm",
      "Germany",
      "Julian Haag",
      "Pays after you remind him 3 times",
    ]);
  } else {
    for (let i = 0; i < customers.length; i++) {
      const c = customers[i];
      const address = c.address.length > 0 ? c.address[0] : getDefaultAddress(undefined);
      const data: Array<string> = [];
      data.push(c.name);
      const iC = getDocFromCollection(context.userData, c.internalContact);
      data.push(getUserName(iC));
      data.push(c.rating.toString());
      data.push(c.vat);
      data.push(c.creditLimit.toString());
      data.push(c.paymentTarget === -1 ? "In Advance" : c.paymentTarget + " Days");
      data.push(c.mail);
      data.push(c.phone);
      data.push(address.name);
      data.push(address.street);
      data.push(address.houseNo);
      data.push(address.postalCode);
      data.push(address.city);
      data.push(address.country);
      const pP = getDocFromCollection(context.userData, c.primaryPerson);
      data.push(getUserName(pP));
      data.push(c.notes);
      content.push(data);
    }
  }
  return generateCSVString(CUSTOMER_HEADERS, content, DEFAULTSEPARATOR, true);
}

/**
 * Exports the given suppliers as csv.
 * @param suppliers Suppliers that should be exported
 * @param context Data context - needed to resolve users
 * @param exampleMode Optional, if set the examples are returned
 * @returns { string } Suppliers as csv
 */
export function generateSuppliersCSVString(
  suppliers: Array<Supplier>,
  context: DataContextInternalType,
  exampleMode?: boolean
): string {
  const content: Array<Array<string>> = [];
  if (exampleMode) {
    content.push([
      "Krasse Rohstoffe GmbH & Co. KG",
      "Fabian Geisler",
      "5",
      "EUR",
      "DE-23874",
      "supplier@example.com",
      "0172666666",
      "",
      "Wagnerstraße",
      "59",
      "89077",
      "Ulm",
      "DE",
      "John Smith",
      "Really krasser Stuff!",
    ]);
    content.push([
      "Wischi Waschi Rohstoffe GbR",
      "Fabian Geisler",
      "0",
      "EUR",
      "DE-2353463",
      "hp@example.com",
      "01720101010",
      "",
      "Ingeborg-Bachmann-Straße",
      "71-73",
      "89134",
      "Blaustein",
      "DE",
      "Hans-Peter Geerdes",
      "Sometimes the fish is slimy",
    ]);
  } else {
    for (let i = 0; i < suppliers.length; i++) {
      const s = suppliers[i];
      const address = s.address.length > 0 ? s.address[0] : getDefaultAddress(undefined, AddressType.A_PRIMARY);
      const data: Array<string> = [];
      data.push(s.name);
      const iC = getDocFromCollection(context.userData, s.internalContact);
      data.push(getUserName(iC));
      data.push(s.rating.toString());
      data.push(s.currency);
      data.push(s.vat);
      data.push(s.mail);
      data.push(s.phone);
      data.push(address.name);
      data.push(address.street);
      data.push(address.houseNo);
      data.push(address.postalCode);
      data.push(address.city);
      data.push(address.country);
      const pP = getDocFromCollection(context.userData, s.primaryPerson);
      data.push(getUserName(pP));
      data.push(s.notes);
      content.push(data);
    }
  }
  return generateCSVString(SUPPLIER_HEADERS, content, DEFAULTSEPARATOR, true);
}

/**
 * Exports the given seaports as CSV.
 * @param seaports List of seaports that should be exported
 * @returns { string } Seaports as CSV
 */
export function generateSeaportsCSVString(seaports: Array<Seaport>): string {
  const content: Array<Array<string>> = [];
  for (let i = 0; i < seaports.length; i++) {
    const s = seaports[i];
    const data: Array<string> = [];
    data.push(s.locode);
    data.push(s.name);
    data.push(s.country);
    data.push(s.cost?.toString() ?? "");
    data.push(s.containerCost?.["20"].toString() ?? "");
    data.push(s.containerCost?.["40"].toString() ?? "");
    data.push(s.containerCost?.["40HC"].toString() ?? "");
    content.push(data);
  }
  return generateCSVString(SEAPORT_HEADERS, content, DEFAULTSEPARATOR, true);
}

/**
 * Parses the given csv with the given separator.
 * @param csv Fields that are separated by line
 * @param separator Separator that was used in the csv
 * @returns { Array<Array<string>> } Parsed csv
 */
export function parseCSV(csv: Array<string>, separator: string): Array<Array<string>> {
  const content: Array<Array<string>> = [];
  for (let i = 0; i < csv.length; i++) {
    content.push(csv[i].split(separator));
  }
  return content;
}

/**
 * Generate a CSV from the given data.
 * @param headers Headers of the CSV, separate so that length mismatches can be detected
 * @param content Content of the CSV
 * @param separator Optional, separator for the values
 * @param noWrap Optional, if set the wrapping quotes are not used
 * @returns { string } Data as CSV
 */
function generateCSVString(
  headers: Array<string>,
  content: Array<Array<string>>,
  separator?: string,
  noWrap?: boolean
): string {
  if (!separator) separator = ",";
  let csv;
  if (noWrap) csv = headers.join(`${separator}`) + "\n";
  else csv = '"' + headers.join(`"${separator}"`) + '"\n';
  for (let i = 0; i < content.length; i++) {
    const c = content[i];
    if (headers.length !== c.length) console.warn("Length of content line", i, "not equal to header length");
    if (noWrap) csv += c.join(`${separator}`) + "\n";
    else csv += '"' + c.join(`"${separator}"`) + '"\n';
  }
  return csv;
}
