import _ from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import Search from "../../../common/Search";
import { doFuseSearch } from "../../../../utils/baseUtils";
import BaseListing from "../../../common/BaseListing";
import CustomSelect, { SelectOption } from "../../../common/CustomSelect";
import { paginate } from "../../../common/Pagination";
import { transaction, UpdateAction, PRICEREQUESTWHITELIST } from "../../../../services/dbService";
import { PriceRequestWhitelist, PriceRequestWhitelistStatus } from "../../../../model/priceRequestWhitelist.types";
import { getDefaultSlackChannel, sendMessage } from "../../../../services/slackService";
import { DEFAULTMAILSUBJECTACCESSGRANTED, DEFAULTMAILSUBJECTACCESSREVOKED } from "../../../../utils/whitelistUtils";
import { convertToEmailHTML, sendEmail } from "../../../../utils/emailUtils";
import WhitelistActionModal from "./WhitelistActionModal";
import AddWhitelistEntryModal from "./AddWhitelistEntryModal";

const WHITELISTSELECTOPTIONS = [
  { value: "all", label: "All" },
  { value: PriceRequestWhitelistStatus.GRANTED, label: "Granted" },
  { value: PriceRequestWhitelistStatus.DENIED, label: "Denied" },
  { value: PriceRequestWhitelistStatus.PENDING, label: "Pending" },
];

const headerDefinition = [
  { title: "Requester", style: { width: "50%" } },
  { title: "State", style: { width: "25%" } },
  { title: "Action", className: "text-right", style: { width: "25%" } },
];

interface WhitelistOverviewProps {
  priceRequestWhitelistCollection: Array<PriceRequestWhitelist>;
}

const WhitelistOverview: React.FunctionComponent<WhitelistOverviewProps> = ({ priceRequestWhitelistCollection }) => {
  const [search, setSearch] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(25);
  const [type, setType] = useState<SelectOption>(WHITELISTSELECTOPTIONS[0]);

  const handleChangeSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  }, []);

  const handleChangePageSize = useCallback((pageSize: number) => {
    setPageSize(pageSize);
    setCurrentPage(1);
  }, []);

  const handleChangePage = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  const handleChangeType = useCallback((e: SelectOption | undefined) => {
    setType(e ?? WHITELISTSELECTOPTIONS[0]);
    setCurrentPage(1);
  }, []);

  const filteredWhitelist = useMemo(() => {
    let filteredWhitelist = priceRequestWhitelistCollection;
    if (type.value !== "all")
      filteredWhitelist = priceRequestWhitelistCollection.filter((t) => t.status === type.value);
    if (!search.trim()) return _.orderBy(filteredWhitelist, "mail", "asc");
    return doFuseSearch(filteredWhitelist, search, ["mail"]);
  }, [search, type, priceRequestWhitelistCollection]);

  const paginatedWhitelist = useMemo(() => {
    return paginate(filteredWhitelist, currentPage, pageSize);
  }, [filteredWhitelist, currentPage, pageSize]);

  const handleWhitelistActionConfirmed = useCallback(
    async (
      requester: PriceRequestWhitelist,
      newStatus: PriceRequestWhitelistStatus,
      useMailNotification: boolean,
      mailText: string
    ) => {
      try {
        const actions: Array<UpdateAction> = [];
        actions.push({
          collection: PRICEREQUESTWHITELIST,
          filter: { _id: requester._id },
          update: { status: newStatus },
        });
        const res = await transaction(actions);
        if (res) {
          toast.success("Whitelist successfully updated");
          let message;
          if (newStatus === PriceRequestWhitelistStatus.GRANTED) {
            message = `:information_source: Mail ${requester.mail} has been whitelisted for sending price request to the RAWBIDS-BOT.`;
          } else {
            message = `:information_source: Whitelist for mail ${requester.mail} has been revoked.`;
          }
          sendMessage(getDefaultSlackChannel(false), message);
          if (useMailNotification) {
            const htmlMessage = convertToEmailHTML(mailText);
            sendEmail(
              newStatus === PriceRequestWhitelistStatus.GRANTED
                ? DEFAULTMAILSUBJECTACCESSGRANTED
                : DEFAULTMAILSUBJECTACCESSREVOKED,
              htmlMessage,
              mailText,
              requester.mail
            );
          }
          return true;
        } else {
          toast.error("Error updating whitelist entry");
          return false;
        }
      } catch (e) {
        toast.error("An error occurred: " + e);
        return false;
      }
    },
    []
  );

  return (
    <div className="content d-flex flex-column flex-column-fluid">
      <div className="post d-flex flex-column-fluid">
        <div className="container-xxl">
          <div className="card bg-white">
            <div className="card-body">
              <h3 className="card-title align-items-start flex-column mb-15">
                <span className="card-label fw-bolder mb-3 fs-3rem">
                  Whitelist Configuration for Price Requests (RAWBIDS-BOT)
                </span>
              </h3>
              <div className="row">
                <div className="col-12">
                  <div className="d-flex align-items-center my-5">
                    <div className="w-50">
                      <Search
                        onSearch={handleChangeSearch}
                        value={search}
                        placeholder="Search..."
                        inputClasses="form-control custom-form-control"
                      />
                    </div>
                    <div style={{ width: "20%" }} className="ml-auto">
                      <CustomSelect
                        options={WHITELISTSELECTOPTIONS}
                        value={type}
                        onChange={handleChangeType}
                        isClearable={true}
                      />
                    </div>
                  </div>
                  <BaseListing
                    headerDefinition={headerDefinition}
                    documents={filteredWhitelist}
                    noHover={true}
                    bodyContent={
                      <>
                        {paginatedWhitelist.map((wh) => (
                          <tr key={wh._id.toString()}>
                            <td className="text-white align-middle">{wh.mail}</td>
                            <td className="align-middle">
                              <div style={{ display: "flex" }}>
                                <span
                                  className="badge badge-sm"
                                  style={
                                    wh.status === PriceRequestWhitelistStatus.GRANTED
                                      ? { backgroundColor: "#50CD89" }
                                      : wh.status === PriceRequestWhitelistStatus.PENDING
                                      ? { backgroundColor: "#ffc700" }
                                      : { backgroundColor: "#ff265a" }
                                  }
                                >
                                  {wh.status === PriceRequestWhitelistStatus.GRANTED
                                    ? "Granted"
                                    : wh.status === PriceRequestWhitelistStatus.PENDING
                                    ? "Pending"
                                    : "Denied"}
                                </span>
                              </div>
                            </td>
                            <td className="align-middle text-right">
                              {(wh.status === PriceRequestWhitelistStatus.PENDING ||
                                wh.status === PriceRequestWhitelistStatus.GRANTED) && (
                                <WhitelistActionModal
                                  grantAccess={false}
                                  whitelistObject={wh}
                                  onWhitelistActionConfirmed={handleWhitelistActionConfirmed}
                                />
                              )}
                              {(wh.status === PriceRequestWhitelistStatus.PENDING ||
                                wh.status === PriceRequestWhitelistStatus.DENIED) && (
                                <WhitelistActionModal
                                  grantAccess={true}
                                  whitelistObject={wh}
                                  onWhitelistActionConfirmed={handleWhitelistActionConfirmed}
                                />
                              )}
                            </td>
                          </tr>
                        ))}
                      </>
                    }
                    currentPage={currentPage}
                    pageSize={pageSize}
                    baseSize={25}
                    onPageChange={handleChangePage}
                    onPageSizeChange={handleChangePageSize}
                  />
                  <div className="card-footer text-right border-0">
                    <AddWhitelistEntryModal />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhitelistOverview;
