import _ from "lodash";
import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import { CustomerCommodityExtended } from "../../../model/customer/customerCommodity.types";
import { DataContextAnonymousType, DataContextCustomerType } from "../../../context/dataContext";
import { SelectOption } from "../../common/CustomSelect";
import {
  AlternativePrice,
  CO_CUSTOMERFILE,
  CO_REQUESTCONFIRMATION,
  formatDateFromType,
  getAlternativePrices,
  getAvailableStock,
  getCustomerOrderCalculation,
  getCustomerOrderTimelineEntry,
  getEarliestDeliveryDate,
  getIncomingOrderableStock,
  getIncomingStockDeliveryDate,
  getPaymentTermsFromCompany,
  placeCustomerOrder,
  sendSlackNotification,
  validateAmount,
} from "../../../utils/customerOrderUtils";
import { callPushToArray, formatUnit, getDocFromCollection, getNumericValue } from "../../../utils/baseUtils";
import { CUSTOMER_BASE_CURRENCY } from "../../../utils/currencyUtils";
import { CustomerCustomerOrder } from "../../../model/customer/customerCustomerOrder.types";
import { CustomerPriceRequestResult, handleCustomerPriceUpdateRequest } from "../../../utils/commodityUtils";
import userService from "../../../services/userService";
import {
  CO_ORDEREDBYCUSTOMER,
  CO_REQUESTEDSTOCK,
  CO_T_CREATED,
  CO_T_REQUESTEDSTOCK,
  CO_TRANSPORT,
  CO_TYPES,
  T_AIRFREIGHT,
  T_CONTRACT,
  T_SEAFREIGHT,
  T_SPECIALREQUEST,
  T_WAREHOUSE,
} from "../../../model/customerOrder.types";
import { CO_FOOTER_HTML, createPDF } from "../../../utils/pdfUtils";
import { REQUESTTEXTSHORT } from "../../../utils/pdf/templateUtils";
import { uploadAndGetOrderFileObject } from "../../../utils/fileUtils";
import { CUSTOMERORDER } from "../../../services/dbService";
import CreateCustomerOrderSettings from "./CreateCustomerOrderSettings";
import CreateCustomerOrderOverview from "./CreateCustomerOrderOverview";
import { Address, CustomerPriceInfo, OrderFile } from "../../../model/commonTypes";
import { MatchingIncomingOrderableStock } from "../../common/CustomTypes";
import { createRequestConfirmationHTML } from "../../../utils/pdf/requestConfirmationGenerationUtils";
import CreateCustomerOrderFinalization from "./CreateCustomerOrderFinalization";
import { CC_DEFAULT_PERIOD_OPTION, ContractPeriods, ContractRequestData } from "../../../utils/customerContractUtils";
import { getDefaultSlackChannel, NotificationType, sendMessage } from "../../../services/slackService";
import {
  CustomerRequest,
  CustomerRequestState,
  CustomerRequestTimelineTypes,
  CustomerRequestType,
} from "../../../model/customerRequest.types";
import { getCustomerRequestTimelineEntry, insertCustomerRequest } from "../../../utils/customerRequestUtils";
import { AddressSelectOption, formatAddress } from "../../../utils/addressUtils";
import { Supplier } from "../../../model/supplier.types";
import { AIR_MAX_ORDER_QUANTITY, DateType, SEA_MIN_ORDER_QUANTITY } from "../../../utils/orderUtils";
import { CommoditySnapshot } from "../../../model/commodity.types";
import {
  CustomerArticleExtended,
  formatArticleUnit,
  getArticleSnapshot,
  isAnyFinishedProduct,
} from "../../../utils/productArticleUtils";
import { isCustomerFinishedProduct } from "../../../utils/finishedProductUtils";
import { CUSTOMER, getUserName } from "../../../utils/userUtils";
import { LABEL_CLEAN_OPTION, LabelDesign } from "../../../utils/companyUtils";

interface CreateCustomerOrderProps extends RouteComponentProps {
  article?: CustomerArticleExtended;
  onBlurAmount?: (amount: number) => void;
  context: DataContextCustomerType | DataContextAnonymousType;
}

interface CreateCustomerOrderState {
  article?: CustomerArticleExtended;
  amount: number;
  shippingAddress?: AddressSelectOption;
  supplier?: SelectOption<Supplier>;
  labelDesign: SelectOption | null;
  reference: string;
  method: CO_TYPES;
  step: number;
  priceInfo: CustomerPriceInfo | null;
  contractRequestData: ContractRequestData;
  alternativePrices?: Array<AlternativePrice>;
  files?: Array<File>;
  targetDate: Date;
  targetDateType?: DateType;
  userSetTargetDate: boolean;
  tosAgreed: boolean;
  generating: boolean;
  availableStock: number;
  incomingOrderableStock?: MatchingIncomingOrderableStock;
  earliestExpiry?: Date;
  note: string;
  earliestDeliveryDate: Date;
  loading: boolean;
}

const COMPONENT_NAME = "CreateCustomerOrder";

class CreateCustomerOrder extends PureComponent<CreateCustomerOrderProps, CreateCustomerOrderState> {
  refreshStockDataInterval: NodeJS.Timer | undefined;

  constructor(props: CreateCustomerOrderProps) {
    super(props);
    this.state = {
      priceInfo: null,
      method: isAnyFinishedProduct(props.article) ? T_SPECIALREQUEST : T_SEAFREIGHT,
      article: props.article,
      amount: 1000,
      labelDesign: getDocFromCollection(props.context.company, userService.getCompany())?.cleanLabel
        ? LABEL_CLEAN_OPTION
        : null,
      reference: "",
      shippingAddress: this.getDefaultAddress(),
      tosAgreed: false,
      generating: false,
      step: 0,
      availableStock: 0,
      incomingOrderableStock: undefined,
      targetDate: new Date(),
      userSetTargetDate: false,
      contractRequestData: {
        period: CC_DEFAULT_PERIOD_OPTION,
        minimumCallQuantity: 500,
      },
      note: "",
      earliestDeliveryDate: new Date(),
      loading: false,
    };
  }

  async componentDidMount() {
    const { context } = this.props;
    const { article, amount, method, earliestExpiry, supplier, targetDate, userSetTargetDate } = this.state;
    if (article) {
      const priceInfo = getCustomerOrderCalculation(
        article,
        amount,
        method === T_CONTRACT ? T_SEAFREIGHT : method, // For contracts check if there is a valid sea price
        context.currencies,
        undefined,
        undefined,
        undefined,
        true
      );
      const alternativePrices = getAlternativePrices(article, amount, context.currencies);
      const [stock, expiry, incomingOrderableStock] = await this.getAvailableAndIncomingStock(article?._id.toString());
      const earliestDeliveryDate =
        method === T_WAREHOUSE && incomingOrderableStock
          ? getIncomingStockDeliveryDate(incomingOrderableStock)
          : await getEarliestDeliveryDate(method as CO_TRANSPORT, supplier?.value, article, amount);
      this.setState({
        priceInfo: await priceInfo,
        alternativePrices: await alternativePrices,
        availableStock: stock,
        earliestExpiry: expiry,
        incomingOrderableStock,
        targetDate: userSetTargetDate ? targetDate : earliestDeliveryDate,
        earliestDeliveryDate: earliestDeliveryDate,
      });
      this.refreshStockDataInterval = setInterval(async () => {
        const { availableStock, incomingOrderableStock: incomingOrderableStockState } = this.state;
        const [stock, expiry, newIncomingStock] = await this.getAvailableAndIncomingStock(article?._id.toString());

        if (
          !_.isEqual(stock, availableStock) ||
          !_.isEqual(newIncomingStock, incomingOrderableStockState) ||
          !_.isEqual(earliestExpiry, expiry)
        )
          this.setState({ availableStock: stock, incomingOrderableStock: newIncomingStock, earliestExpiry: expiry });
      }, 15 * 1000); // 15s
    }
    context.saveRef(COMPONENT_NAME, this);
  }

  async componentDidUpdate(
    prevProps: Readonly<CreateCustomerOrderProps>,
    prevState: Readonly<CreateCustomerOrderState>
  ) {
    const article = this.props.article || this.state.article;
    if (!_.isEqual(prevProps.article, this.props.article) || !_.isEqual(prevState.article, this.state.article)) {
      const { context } = this.props;
      const { amount, method, supplier } = this.state;
      let priceInfo = null;
      let alternativePrices, newSupplier, stock, incomingOrderableStock, earliestExpiry;
      if (article) {
        // If no price was found, we reset the supplier
        const invalidAmount = validateAmount(article, amount, method);
        priceInfo = !invalidAmount
          ? await getCustomerOrderCalculation(
              article,
              amount,
              method === T_CONTRACT ? T_SEAFREIGHT : method, // For contracts check if there is a valid sea price
              context.currencies,
              undefined,
              undefined,
              supplier?.value,
              false
            )
          : null;
        const hasPrice = priceInfo && priceInfo.unitPrice && isFinite(priceInfo.unitPrice);
        priceInfo = hasPrice
          ? priceInfo
          : !invalidAmount
          ? await getCustomerOrderCalculation(
              article,
              amount,
              method === T_CONTRACT ? T_SEAFREIGHT : method, // For contracts check if there is a valid sea price
              context.currencies,
              undefined,
              undefined,
              undefined,
              true
            )
          : null;
        alternativePrices =
          (!invalidAmount &&
            (await getAlternativePrices(
              article,
              amount,
              context.currencies,
              undefined,
              undefined,
              hasPrice ? supplier?.value : undefined
            ))) ||
          undefined;
        newSupplier = hasPrice ? supplier : undefined;
        [stock, earliestExpiry, incomingOrderableStock] = await this.getAvailableAndIncomingStock(
          article?._id.toString()
        );
      }

      this.setState({
        priceInfo,
        alternativePrices,
        supplier: newSupplier,
        availableStock: stock || 0,
        incomingOrderableStock,
        earliestExpiry,
        article,
      });
    }
  }

  componentWillUnmount() {
    if (this.refreshStockDataInterval) clearInterval(this.refreshStockDataInterval);
    this.props.context.deleteRef(COMPONENT_NAME);
  }

  handleChangeCommodity = (e: SelectOption<CustomerCommodityExtended>) => {
    const { context } = this.props;
    const { amount } = this.state;
    if (!e.object) return;
    this.setState(
      {
        article: e.object,
        availableStock: 0,
        priceInfo: null,
        method: T_SEAFREIGHT,
        supplier: undefined,
        incomingOrderableStock: undefined,
      },
      async () => {
        if (!e.object) return; // Should never be possible but for typing issues
        const [stock, earliestExpiry, incomingOrderableStock] = await this.getAvailableAndIncomingStock(
          e.object._id.toString()
        );
        this.setState({
          priceInfo: await getCustomerOrderCalculation(
            e.object,
            amount,
            T_SEAFREIGHT,
            context.currencies,
            undefined,
            undefined,
            undefined,
            true
          ),
          alternativePrices: await getAlternativePrices(e.object, amount, context.currencies),
          availableStock: stock,
          incomingOrderableStock,
          earliestExpiry,
        });
      }
    );
  };

  handleFiles = (e?: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.target.files && e.target.files.length > 0) this.setState({ files: Array.from(e.target.files) });
    else this.setState({ files: undefined });
  };

  handleChangeAddress = (e: AddressSelectOption) => this.setState({ shippingAddress: e });

  handleAddNewAddress = (address: Address) => {
    const addrString = formatAddress(address, { withoutBreak: true });
    this.setState({ shippingAddress: { value: addrString, label: addrString, address: address } });
  };

  handleContinue = () => {
    const { step } = this.state;
    if (step < 2) this.setState({ step: step + 1 });
  };

  handleBack = () => {
    const { step } = this.state;
    if (step > 0) this.setState({ step: step - 1 });
  };

  handleChangeInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangeContractData = (e: React.ChangeEvent<HTMLInputElement>) => {
    const contractRequestData = _.cloneDeep(this.state.contractRequestData);
    if (e.target.name in contractRequestData) {
      const numericValue = getNumericValue(e);
      if (!numericValue) return;
      _.set(contractRequestData, e.target.name, +numericValue);
      this.setState({ contractRequestData });
    }
  };

  handleChangeContractPeriod = (e: SelectOption) => {
    const contractRequestData = _.cloneDeep(this.state.contractRequestData);
    contractRequestData.period = e;
    this.setState({ contractRequestData });
  };

  handleChangeTargetDateType = (newType: DateType) => {
    const { targetDateType } = this.state;
    this.setState({ targetDateType: targetDateType === newType ? undefined : newType });
  };

  handleChangeTargetDate = (e: React.ChangeEvent<HTMLInputElement> | Date) => {
    let targetDate;
    if (e instanceof Date) {
      targetDate = e;
    } else {
      targetDate = new Date(e.target.value);
    }
    if (!targetDate) return;
    targetDate.setHours(12, 0, 0, 0);
    this.setState({ targetDate, userSetTargetDate: true });
  };

  handleSelectSupplier = async (e: SelectOption<Supplier>) => {
    const { context } = this.props;
    const { amount, article, method, incomingOrderableStock, targetDate, userSetTargetDate } = this.state;
    if (!article) return;
    this.setState({ loading: true });
    try {
      const earliestDeliveryDate =
        method === T_WAREHOUSE && incomingOrderableStock
          ? getIncomingStockDeliveryDate(incomingOrderableStock)
          : await getEarliestDeliveryDate(method as CO_TRANSPORT, e?.value ?? undefined, article, amount);
      // When a supplier is selected or unselected no need to use fallback since suppliers exist then
      this.setState({
        supplier: e,
        priceInfo: await getCustomerOrderCalculation(
          article,
          amount,
          method === T_CONTRACT ? T_SEAFREIGHT : method, // For contracts check if there is a valid sea price,
          context.currencies,
          undefined,
          undefined,
          e?.value
        ),
        alternativePrices: await getAlternativePrices(
          article,
          amount,
          context.currencies,
          undefined,
          undefined,
          e?.value
        ),
        earliestDeliveryDate: earliestDeliveryDate,
        targetDate: userSetTargetDate ? targetDate : earliestDeliveryDate,
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  handleSelectLabelDesign = (e: SelectOption) => this.setState({ labelDesign: e });

  handleChangeMethod = (method: CO_TYPES) => {
    const { context } = this.props;
    const {
      amount,
      article,
      alternativePrices,
      supplier,
      incomingOrderableStock,
      targetDate,
      targetDateType,
      userSetTargetDate,
    } = this.state;
    if (!article || method === this.state.method) return;
    if (method === T_SPECIALREQUEST) {
      // No calculation required here
      this.setState({ files: undefined, method, priceInfo: null });
      return;
    }
    const invalidAmount = validateAmount(article, amount, method);
    this.setState({ loading: true });
    try {
      this.setState(
        {
          files: undefined,
          method,
          priceInfo: null,
          targetDateType: method === T_SEAFREIGHT && targetDateType === DateType.FIX ? undefined : targetDateType,
        },
        async () => {
          const earliestDeliveryDate =
            method === T_WAREHOUSE && incomingOrderableStock
              ? getIncomingStockDeliveryDate(incomingOrderableStock)
              : await getEarliestDeliveryDate(method as CO_TRANSPORT, supplier?.value, article, amount);
          const priceInfo = invalidAmount
            ? null
            : alternativePrices?.find((price) => price.method === method && price.priceInfo?.unitPrice)?.priceInfo ||
              (await getCustomerOrderCalculation(
                article,
                amount,
                method === T_CONTRACT ? T_SEAFREIGHT : method, // For contracts check if there is a valid sea price,
                context.currencies,
                undefined,
                undefined,
                method === T_WAREHOUSE ? undefined : supplier?.value,
                !supplier // Only allow fallback if no supplier is selected
              ));
          // In case of method switch in the meantime don't update
          if (this.state.method === method) {
            this.setState({
              priceInfo,
              targetDate: userSetTargetDate ? targetDate : earliestDeliveryDate,
              earliestDeliveryDate: earliestDeliveryDate,
            });
          }
        }
      );
    } finally {
      this.setState({ loading: false });
    }
  };

  handleBlurAmount = async (e: React.ChangeEvent<HTMLInputElement> | number, resetStep?: boolean) => {
    const { onBlurAmount, context } = this.props;
    const { article, method, supplier, availableStock, userSetTargetDate, targetDate } = this.state;
    if (!article) return;
    let amount;
    if (typeof e === "number") amount = e;
    else amount = Number(getNumericValue(e));
    let transport: CO_TYPES = method;

    this.setState({ loading: true });
    try {
      if (resetStep) {
        // Reset data
        this.setState({
          amount,
          priceInfo: null,
          step: 0,
          tosAgreed: false,
          incomingOrderableStock: undefined,
        });
      } else
        this.setState({
          amount,
          priceInfo: null,
          incomingOrderableStock: undefined,
        });

      // In 2 steps since the calculation could need some seconds - customer can see that we are calculating
      if (transport === T_AIRFREIGHT && amount > AIR_MAX_ORDER_QUANTITY) transport = T_SEAFREIGHT;
      if (transport === T_SEAFREIGHT && amount < SEA_MIN_ORDER_QUANTITY) transport = T_AIRFREIGHT;
      const invalidAmount = validateAmount(article, amount, transport);
      const priceInfo =
        !invalidAmount &&
        (await getCustomerOrderCalculation(
          article,
          amount,
          transport === T_CONTRACT ? T_SEAFREIGHT : transport, // For contracts check if there is a valid sea price,
          context.currencies,
          undefined,
          undefined,
          supplier?.value
        ));

      // If no price was found, we reset the supplier
      const hasPrice = priceInfo && priceInfo.unitPrice && isFinite(priceInfo.unitPrice);
      const incomingOrderableStock =
        amount > availableStock
          ? await getIncomingOrderableStock(article._id.toString(), amount, context.currencies)
          : undefined;
      const earliestDeliveryDate =
        transport === T_WAREHOUSE && incomingOrderableStock
          ? getIncomingStockDeliveryDate(incomingOrderableStock)
          : transport === T_SPECIALREQUEST || transport === T_CONTRACT
          ? new Date()
          : await getEarliestDeliveryDate(transport as CO_TRANSPORT, supplier?.value, article, amount);
      this.setState({
        priceInfo: hasPrice
          ? priceInfo
          : !invalidAmount
          ? await getCustomerOrderCalculation(
              article,
              amount,
              transport === T_CONTRACT ? T_SEAFREIGHT : transport, // For contracts check if there is a valid sea price,
              context.currencies,
              undefined,
              undefined,
              undefined,
              true
            )
          : null,
        alternativePrices:
          (!invalidAmount &&
            (await getAlternativePrices(
              article,
              amount,
              context.currencies,
              undefined,
              undefined,
              hasPrice ? supplier?.value : undefined
            ))) ||
          undefined,
        method: transport,
        supplier: hasPrice ? supplier : undefined,
        incomingOrderableStock,
        earliestDeliveryDate: earliestDeliveryDate,
        targetDate: userSetTargetDate ? targetDate : earliestDeliveryDate,
      });
    } finally {
      this.setState({ loading: false });
    }
    if (onBlurAmount) onBlurAmount(amount);
  };

  handleRequestPriceUpdate = async () => {
    const { article, amount, targetDate, supplier } = this.state;
    if (!article || isCustomerFinishedProduct(article, CUSTOMER)) return;
    const res = await handleCustomerPriceUpdateRequest(
      article,
      amount,
      targetDate,
      supplier?.expired ? supplier.value : undefined
    );
    switch (res) {
      case CustomerPriceRequestResult.ALREADY_REQUESTED:
        toast.success("Price update already requested");
        break;
      case CustomerPriceRequestResult.REQUEST_FAILED:
        toast.success("Price update could not be requested. Please try again later.");
        break;
      case CustomerPriceRequestResult.REQUEST_SUCCESSFUL:
        toast.success("Price update successfully requested");
        break;
    }
  };

  handleConfirmOrder = async () => {
    const { context, history } = this.props;
    const {
      article,
      amount,
      priceInfo,
      shippingAddress,
      reference,
      method,
      supplier,
      labelDesign,
      files,
      contractRequestData,
      targetDate,
      targetDateType,
      note,
      incomingOrderableStock,
    } = this.state;
    const company = getDocFromCollection(context.company, userService.getCompany());
    if (
      !company ||
      !article ||
      (!priceInfo && !(method === T_SPECIALREQUEST || method === T_CONTRACT)) ||
      !shippingAddress
    ) {
      toast.error(
        method === T_SPECIALREQUEST || method === T_CONTRACT ? "Error sending request" : "Error creating order"
      );
      return;
    }

    // Upload files
    const orderFiles: Array<OrderFile> = [];
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const orderFile = uploadAndGetOrderFileObject(files[i], CO_CUSTOMERFILE, company);
        if (orderFile) orderFiles.push(orderFile);
      }
    }

    if (method === T_SPECIALREQUEST || method === T_CONTRACT) {
      const isFP = isAnyFinishedProduct(article);
      const customerRequest: CustomerRequest = {
        _id: new BSON.ObjectId(),
        state: CustomerRequestState.OPEN,
        requestNo: "-1", // Will be replaced in backend
        requestedOn: new Date(),
        commodity: getArticleSnapshot(article) as CommoditySnapshot,
        company: { _id: company._id, name: company.name, snapshotDate: new Date() },
        amount,
        type: method === T_SPECIALREQUEST ? CustomerRequestType.STANDARDREQUEST : CustomerRequestType.CONTRACTREQUEST,
        customerReference: reference,
        requestData:
          method === T_SPECIALREQUEST
            ? { targetDate: targetDate, destination: shippingAddress.address, targetDateType: targetDateType }
            : {
                supplier: supplier?.value,
                period: contractRequestData.period.value as ContractPeriods,
                minimumCallQuantity: contractRequestData.minimumCallQuantity,
              },
        attachments: orderFiles,
        message: note.trim(),
        timeline: [getCustomerRequestTimelineEntry(CustomerRequestTimelineTypes.CREATED)],
      };

      const result = await insertCustomerRequest(customerRequest);

      const message = `
      :information_source: *NEW REQUEST* ${
        result && result.requestNumber
          ? `<https://${process.env.REACT_APP_BASE_URL || ""}/${
              method === T_CONTRACT ? "createCustomerContract" : "createCustomerOrder"
            }/request/${result.res.insertedId.toString()}|*RB-${result.requestNumber}*>`
          : ""
      }\r\n${
        !(result && result.res && result.res.insertedId)
          ? `:warning: Request creation failed in database. Please contact the customer\r\n`
          : ""
      }${isFP ? "Finished Product" : "Commodity"}: <https://${process.env.REACT_APP_BASE_URL || ""}/${
        isFP ? "finishedProduct" : "commodity"
      }/${article._id.toString()}|*${article.title.en || ""}*>\r\nTotal Amount: *${amount}${formatArticleUnit(
        article.unit,
        article
      )}* \r\nCustomer: <https://${process.env.REACT_APP_BASE_URL || ""}/customer/${company._id.toString()}|*${
        company.name || ""
      }*> \r\nUser: ${getUserName(userService.getUserData())}\r\nContract: ${
        method === T_CONTRACT ? "*Yes*" : "No"
      } \r\n${method === T_SPECIALREQUEST ? "Target " + formatDateFromType(targetDate, targetDateType) : ""}\r\n${
        method === T_CONTRACT ? `Validity Period: ${contractRequestData.period.label} \r\n` : ""
      }${
        method === T_CONTRACT
          ? `Minimum Call-Off Quantity: ${formatUnit(contractRequestData.minimumCallQuantity, article.unit)} \r\n`
          : ""
      }Destination: ${shippingAddress.label} \r\nCustomer Reference: ${reference.trim() || "-"} \r\n${
        method === T_CONTRACT
          ? `Preferred Supplier: ${
              supplier
                ? `<https://${process.env.REACT_APP_BASE_URL || ""}/supplier/${supplier.value}|${supplier.label}>`
                : "-"
            } \r\n`
          : ""
      }Message: ${note.trim() || "-"} \r\n${
        orderFiles.length > 0
          ? `Attachments: ${orderFiles
              .map((f) => `<${process.env.REACT_APP_MEDIAHUB_FILE_BASE || ""}${f.path}|${f.path.replace(/.*\//, "")}>`)
              .join("\r\n")}`
          : ""
      }
      `;
      const res = await sendMessage(getDefaultSlackChannel(false, NotificationType.REQUEST), message);
      if (res) toast.success("Request sent");
      this.setState({ step: 2 }, () =>
        setTimeout(() => {
          history.push("/requests");
        }, 3000)
      );
    } else {
      // Already checked above but double makes the type check work
      if (!priceInfo) return;
      this.setState({ generating: true });
      const snapshot = getArticleSnapshot(article);

      try {
        const priceServices = 0;
        const priceCommodities =
          priceInfo && isFinite(priceInfo.totalPrice)
            ? priceInfo.totalPrice
            : incomingOrderableStock && method === T_WAREHOUSE
            ? incomingOrderableStock.price * amount
            : Infinity;
        if (priceCommodities === Infinity) return;
        // Create a wrapper object with the customer order information
        const customerOrder: CustomerCustomerOrder = {
          _id: new BSON.ObjectId(),
          commodity: snapshot,
          amount,
          unit: article.unit as "kg" | "ltr",
          priceCommodities,
          currency: CUSTOMER_BASE_CURRENCY,
          targetDate,
          targetDateType,
          destination: shippingAddress.address,
          customerReference: reference,
          transport: method,
          company: company._id.toString(),
          person: userService.getUserId(),
          timeline: [getCustomerOrderTimelineEntry(method === T_WAREHOUSE ? CO_T_REQUESTEDSTOCK : CO_T_CREATED)],
          services: [],
          priceServices,
          files: orderFiles,
          orderNo: "-1", // Will be replaced in backend
          noteCustomer: note,
          state: method === T_WAREHOUSE ? CO_REQUESTEDSTOCK : CO_ORDEREDBYCUSTOMER,
          totalPrice: priceCommodities + priceServices,
          createdAt: new Date(),
          terms: getPaymentTermsFromCompany(
            company,
            shippingAddress.address,
            labelDesign !== null && labelDesign.value === LabelDesign.CLEAN
          ),
        };
        if (supplier) customerOrder.supplier = supplier.object?._id.toString();

        let result:
          | { res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>; orderNumber: string; error?: string }
          | undefined;
        if (method === T_WAREHOUSE && incomingOrderableStock)
          result = await placeCustomerOrder(customerOrder, incomingOrderableStock.order, context.currencies);
        else result = await placeCustomerOrder(customerOrder);
        if (result && result.res && result.res.insertedId) {
          customerOrder.orderNo = result.orderNumber;
          const path = await createPDF(
            createRequestConfirmationHTML(customerOrder, REQUESTTEXTSHORT, context),
            "Request-Confirmation-" + result.orderNumber,
            company._id.toString(),
            {
              marginLeft: "2cm",
              marginBottom: "4.2cm",
              footerHtml: CO_FOOTER_HTML,
            }
          );
          if (path) {
            await callPushToArray(CUSTOMERORDER, result.res.insertedId, "files", {
              _id: new BSON.ObjectId(),
              date: new Date(),
              path,
              type: CO_REQUESTCONFIRMATION,
            });
          }
          sendSlackNotification(customerOrder, result.res.insertedId.toString(), company);
          toast.success("Placed new order");
          this.setState({ step: 2 }, () =>
            setTimeout(() => {
              history.push("/order/" + result?.res.insertedId.toString());
            }, 3000)
          );
        } else {
          toast.error(`Order could not be placed: ${result && result.error ? result.error : "Unknown error"}`);
          if (result && result.error) console.error(result.error);
        }
      } catch (e) {
        toast.error(`Order could not be placed: ${e as string}`);
      } finally {
        this.setState({ generating: false });
      }
    }
  };

  /**
   * Get available and incoming stock
   * @param articleId optional, commodity or finished product id
   * @returns {[stock: number, incomingStock: MatchingIncomingOrderableStock | undefined]} tuple with available stock and incoming stock object
   */
  getAvailableAndIncomingStock = async (
    articleId?: string
  ): Promise<
    [stock: number, earliestExpiry: Date | undefined, incomingStock: MatchingIncomingOrderableStock | undefined]
  > => {
    const { context } = this.props;
    const { amount, article } = this.state;
    if (!article) return [0, undefined, undefined];
    const stock = await getAvailableStock(article);
    if (amount <= stock[0]) return [stock[0], stock[2], undefined];
    const incomingStock = await getIncomingOrderableStock(
      articleId ?? article._id.toString(),
      amount,
      context.currencies
    );
    return [stock[0], stock[2], incomingStock];
  };

  getDefaultAddress = () => {
    const { context } = this.props;
    const company = getDocFromCollection(context.company, userService.getCompany() || "");
    if (company && company.address.length > 0) {
      const address = formatAddress(company.address[0], { withoutBreak: true });
      return { label: address, value: address, address: company.address[0] } as AddressSelectOption;
    }
    return undefined;
  };

  validateData = () => {
    const {
      step,
      article,
      amount,
      shippingAddress,
      method,
      availableStock,
      priceInfo,
      targetDate,
      note,
      incomingOrderableStock,
      earliestDeliveryDate,
    } = this.state;
    const errors = [];
    if (!article) {
      errors.push("Select a commodity");
      return errors;
    }
    const suitableAmount = validateAmount(article, amount, method);
    if (article && (article.disabled || !article.approved)) {
      errors.push("Commodity can currently not be ordered");
      return errors;
    }
    if (method === T_AIRFREIGHT && amount > AIR_MAX_ORDER_QUANTITY) {
      errors.push(`Air freight only available up to ${formatUnit(AIR_MAX_ORDER_QUANTITY, "kg")}`);
    }
    if (method === T_SEAFREIGHT && amount < SEA_MIN_ORDER_QUANTITY) {
      errors.push(`Sea freight only available from ${formatUnit(SEA_MIN_ORDER_QUANTITY, "kg")}`);
    }
    if (step === 1 && method === T_SPECIALREQUEST && note.trim().length < 10)
      errors.push("Please leave a message for your request");
    if (!amount) errors.push("Select an amount to order");
    if (suitableAmount) errors.push("Amount cannot be ordered");
    if (!shippingAddress) errors.push("Select a shipping address");
    if (
      method === T_WAREHOUSE &&
      amount > availableStock &&
      (!incomingOrderableStock || incomingOrderableStock.availableAmount < amount)
    )
      errors.push("Not enough stock currently available. Please create a request instead");
    if (([T_AIRFREIGHT, T_SEAFREIGHT, T_WAREHOUSE] as Array<CO_TYPES>).includes(method)) {
      if (!priceInfo || priceInfo.unitPrice === 0 || !isFinite(priceInfo.unitPrice)) {
        if (method !== T_WAREHOUSE) errors.push("Commodity currently not available");
      }
      if ([0, 6].includes(targetDate.getDay())) {
        errors.push("Weekends can't be selected as target date");
      } else if (targetDate < earliestDeliveryDate)
        errors.push("Target date is too early and cannot be guaranteed. Please create a request instead");
    }
    return errors;
  };

  render() {
    const { article, context } = this.props;
    const {
      alternativePrices,
      method,
      article: articleState,
      amount,
      shippingAddress,
      reference,
      priceInfo,
      step,
      tosAgreed,
      generating,
      availableStock,
      incomingOrderableStock,
      files,
      supplier,
      labelDesign,
      contractRequestData,
      earliestExpiry,
      targetDate,
      targetDateType,
      earliestDeliveryDate,
      note,
      loading,
    } = this.state;

    let suitableAmount;
    if (articleState) suitableAmount = validateAmount(articleState, amount, method);
    const errors = this.validateData();
    if (articleState && (articleState.disabled || !articleState.approved))
      return (
        <div className="card bg-white">
          <div className="card-header border-0 mt-5">
            <h3 className="card-title flex-column">
              <span className="card-label fw-bolder fs-3 ">Order Settings</span>
              <span className="text-muted fw-normal" style={{ fontSize: "13px" }}>
                Specify the desired amount, destination and other preferences
              </span>
            </h3>
          </div>
          <div className="card-body d-flex flex-column">
            <h6 className="text-muted text-center py-20">This commodity is currently not available</h6>
          </div>
        </div>
      );
    return (
      <div className="card bg-white">
        <div className="card-header border-0 pt-5">
          <h3 className="card-title flex-column">
            <span className="card-label fw-bolder fs-2rem mb-1">
              {step === 0
                ? "Order Settings"
                : step === 1
                ? method === T_SPECIALREQUEST || method === T_CONTRACT
                  ? "Request Summary"
                  : "Order Summary"
                : step === 2 && "Order Confirmation"}
            </span>
            <span className="fs-6 text-muted fw-normal">
              {step === 0
                ? "Specify the desired amount, destination and other preferences"
                : step === 1
                ? method === T_SPECIALREQUEST || method === T_CONTRACT
                  ? "Review and confirm your request"
                  : "Review and confirm your order"
                : step === 2 && "Order process is completed"}
            </span>
          </h3>
        </div>
        <div className="card-body d-flex flex-column" style={{ minHeight: "600px" }}>
          {step === 0 && (
            <CreateCustomerOrderSettings
              alternativePrices={alternativePrices}
              article={article}
              articleState={articleState}
              shippingAddress={shippingAddress}
              availableStock={availableStock}
              incomingOrderableStock={incomingOrderableStock}
              earliestExpiry={earliestExpiry}
              priceInfo={priceInfo}
              targetDate={targetDate}
              targetDateType={targetDateType}
              earliestDeliveryDate={earliestDeliveryDate}
              amount={amount}
              reference={reference}
              note={note}
              method={method}
              errors={errors}
              context={context}
              suitableAmount={suitableAmount}
              files={files}
              supplier={supplier}
              labelDesign={labelDesign}
              contractRequestData={contractRequestData}
              loading={loading}
              onChangeContractData={this.handleChangeContractData}
              onChangeContractPeriod={this.handleChangeContractPeriod}
              onSelectSupplier={this.handleSelectSupplier}
              onSelectLabelDesign={this.handleSelectLabelDesign}
              onFiles={this.handleFiles}
              onChangeCommodity={this.handleChangeCommodity}
              onChangeAddress={this.handleChangeAddress}
              onBlurAmount={this.handleBlurAmount}
              onChangeInput={this.handleChangeInput}
              onChangeMethod={this.handleChangeMethod}
              onChangeTargetDate={this.handleChangeTargetDate}
              onChangeTargetDateType={this.handleChangeTargetDateType}
              onContinue={this.handleContinue}
              onRequestPriceUpdate={this.handleRequestPriceUpdate}
              onAddNewAddress={this.handleAddNewAddress}
            />
          )}
          {step === 1 &&
            article &&
            (priceInfo || ([T_CONTRACT, T_SPECIALREQUEST] as Array<CO_TYPES>).includes(method)) &&
            shippingAddress && (
              <CreateCustomerOrderOverview
                article={article}
                priceInfo={priceInfo}
                amount={amount}
                shippingAddress={shippingAddress}
                reference={reference}
                method={method}
                errors={errors}
                generating={generating}
                tosAgreed={tosAgreed}
                targetDate={targetDate}
                targetDateType={targetDateType}
                earliestDeliveryDate={earliestDeliveryDate}
                note={note}
                context={context}
                supplier={supplier}
                labelDesign={labelDesign}
                files={files}
                contractRequestData={contractRequestData}
                incomingOrderableStock={incomingOrderableStock}
                onFiles={this.handleFiles}
                onChangeNote={this.handleChangeInput}
                onBack={this.handleBack}
                onToggleToS={() => this.setState({ tosAgreed: !tosAgreed })}
                onConfirmOrder={this.handleConfirmOrder}
              />
            )}
          {step === 2 && <CreateCustomerOrderFinalization method={method} />}
        </div>
      </div>
    );
  }
}

export default CreateCustomerOrder;
