import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import Position from "./Position";
import {
  AdjustedCalculationEntry,
  SO_T_CALCULATION_ADJUSTED,
  SO_T_CALCULATION_RESET,
  SupplierOrderExtended,
} from "../../../../model/supplierOrder.types";
import {
  ADJUSTABLE_CALCULATION_VALUES,
  getAdjustableFieldDescription,
  getSupplierOrderTimelineEntry,
  getSystemEstimateForAdjustableField,
  getValueForAdjustableField,
} from "../../../../utils/supplierOrderUtils";
import { formatCurrency } from "../../../../utils/baseUtils";
import { convertCurrency, EURO } from "../../../../utils/currencyUtils";
import { DataContextInternal } from "../../../../context/dataContext";
import { getOrderNumber } from "../../../../utils/orderUtils";
import { PositionType } from "../../../../utils/controllingDashboardUtils";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import { SUPPLIERORDER, transaction, UpdateAction } from "../../../../services/dbService";

interface AdjustCalculationModalProps {
  onCloseModal: () => void;
  order?: SupplierOrderExtended;
  field?: ADJUSTABLE_CALCULATION_VALUES;
}

const AdjustCalculationModal: React.FC<AdjustCalculationModalProps> = ({ order, field, onCloseModal }) => {
  const context = useContext(DataContextInternal);

  const [positions, setPositions] = useState<Array<PositionType>>([]);
  const [systemEstimate, setSystemEstimate] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);

  const handleToggleSystemEstimate = useCallback(() => setSystemEstimate(!systemEstimate), [systemEstimate]);

  const handleAddPosition = useCallback(() => {
    const newPosition = { _id: new BSON.ObjectId(), description: "", value: 0, isEditing: true };
    const newPositions = Array.from(positions);
    newPositions.push(newPosition);
    setPositions(newPositions);
  }, [positions]);

  const handleUpdatePosition = useCallback(
    (updatedPosition: PositionType) => {
      setPositions(() => {
        const newPositions = [...positions];
        const index = newPositions.findIndex((p) => p._id.toString() === updatedPosition._id.toString());
        newPositions[index] = { ...updatedPosition, isEditing: false };
        return newPositions;
      });
    },
    [positions]
  );

  const handleRemovePosition = useCallback(
    (data: PositionType) => {
      const newPositions = [...positions];
      const index = newPositions.findIndex((p) => p._id.toString() === data._id.toString());
      newPositions.splice(index, 1);
      setPositions(newPositions);
    },
    [positions]
  );

  const handleFinalizePosition = useCallback(
    (updatedPosition: PositionType) => {
      handleUpdatePosition(updatedPosition);
      setSystemEstimate(false);
    },
    [positions]
  );

  const handleSaveAdjustedCalculation = useCallback(async () => {
    if (order && field) {
      setSaving(true);
      try {
        const actions: Array<UpdateAction> = [];
        let calculationField;
        switch (field) {
          case ADJUSTABLE_CALCULATION_VALUES.TRANSPORT:
            calculationField = "calculationDetails.adjustedCalculation.totalTransportationCost";
            break;
          case ADJUSTABLE_CALCULATION_VALUES.INSURANCE:
            calculationField = "calculationDetails.adjustedCalculation.totalInsuranceCost";
            break;
          case ADJUSTABLE_CALCULATION_VALUES.CUSTOMS:
            calculationField = "calculationDetails.adjustedCalculation.totalCustomsCost";
            break;
          case ADJUSTABLE_CALCULATION_VALUES.WAREHOUSE:
            calculationField = "calculationDetails.adjustedCalculation.totalWarehouseCost";
            break;
          case ADJUSTABLE_CALCULATION_VALUES.FOLLOWUP:
            calculationField = "calculationDetails.adjustedCalculation.totalFollowUpCost";
            break;
        }

        // We need to recalculate the margin. If the field is the currently active one we need to check if the system
        // estimate was enabled or not
        const customsCost =
          field === ADJUSTABLE_CALCULATION_VALUES.CUSTOMS
            ? systemEstimate
              ? order.priceCustoms
              : positions.reduce((sum, p) => sum + p.value, 0)
            : order.calculationDetails?.adjustedCalculation?.totalCustomsCost?.reduce((sum, p) => sum + p.value, 0) ??
              order.priceCustoms;
        const totalB2BFollowUpCost =
          (field === ADJUSTABLE_CALCULATION_VALUES.FOLLOWUP
            ? systemEstimate
              ? order.calculationDetails?.details.finalValues.totalB2bFollowUpCost
              : positions.reduce((sum, p) => sum + p.value, 0)
            : order.calculationDetails?.adjustedCalculation?.totalFollowUpCost?.reduce((sum, p) => sum + p.value, 0) ??
              order.calculationDetails?.details.finalValues.totalB2bFollowUpCost) || 0;
        const transportCost =
          field === ADJUSTABLE_CALCULATION_VALUES.TRANSPORT
            ? systemEstimate
              ? order.priceTransport
              : positions.reduce((sum, p) => sum + p.value, 0)
            : order.calculationDetails?.adjustedCalculation?.totalTransportationCost?.reduce(
                (sum, p) => sum + p.value,
                0
              ) ?? order.priceTransport;
        const insuranceCost =
          (field === ADJUSTABLE_CALCULATION_VALUES.INSURANCE
            ? systemEstimate
              ? order.calculationDetails && "totalInsuranceCost" in order.calculationDetails.details.finalValues
                ? order.calculationDetails.details.finalValues.totalInsuranceCost
                : 0
              : positions.reduce((sum, p) => sum + p.value, 0)
            : order.calculationDetails?.adjustedCalculation?.totalInsuranceCost?.reduce(
                (sum, p) => sum + p.value,
                0
              )) || 0;
        const warehouseHandlingCost =
          (field === ADJUSTABLE_CALCULATION_VALUES.WAREHOUSE
            ? systemEstimate
              ? order.calculationDetails?.details.finalValues.totalWarehouseHandlingCost
              : positions.reduce((sum, p) => sum + p.value, 0)
            : order.calculationDetails?.adjustedCalculation?.totalWarehouseCost?.reduce(
                (sum, p) => sum + p.value,
                0
              )) || 0;
        const totalPrice =
          order.priceCommodities +
          customsCost +
          totalB2BFollowUpCost +
          transportCost +
          insuranceCost +
          warehouseHandlingCost;
        const totalMargin = order.totalTurnover - totalPrice + (order.totalWarehouse || 0);

        // If we restore the system estimate we need to clear the adjusted calculation value and update the margin
        if (systemEstimate) {
          actions.push({
            collection: SUPPLIERORDER,
            filter: { _id: order._id },
            unset: { [calculationField]: "" },
            update: { totalMargin, totalPrice },
            push: { timeline: getSupplierOrderTimelineEntry(SO_T_CALCULATION_RESET, { type: field }) },
          });
        } else {
          // If we adjust the adjusted calculation we need to transform the positions and update the total margin
          const update: { [key: string]: Array<AdjustedCalculationEntry> } = { [calculationField]: [] };
          for (let i = 0; i < positions.length; i++) {
            const p = positions[i];
            const u: AdjustedCalculationEntry = {
              _id: p._id,
              value: convertCurrency(p.value, EURO, order.currency, order.exchangeRates ?? context.currencies),
              description: p.description,
            };
            if (p.document) u.document = p.document;
            update[calculationField].push(u);
          }
          actions.push({
            collection: SUPPLIERORDER,
            filter: { _id: order._id },
            update: { ...update, totalMargin, totalPrice },
            push: { timeline: getSupplierOrderTimelineEntry(SO_T_CALCULATION_ADJUSTED, { type: field }) },
          });
        }
        const res = await transaction(actions);
        if (res) {
          toast.success("Calculation successfully updated");
        } else {
          toast.error("Error updating calculation");
        }
      } catch (e) {
        console.error(e);
        toast.error("Error updating calculation");
      } finally {
        setSaving(false);
      }
    }
    onCloseModal();
  }, [order, field, positions, systemEstimate]);

  // Out of normal order since it is needed for the effect below.
  const showModal = useMemo(() => Boolean(order && field), [order, field]);

  useEffect(() => {
    setSystemEstimate(false);
    setPositions([]);
  }, [showModal]);

  useEffect(() => {
    if (!order || !field) {
      setPositions([]);
    } else {
      const positions = getValueForAdjustableField(order, field);
      if (!positions) setPositions([]);
      else
        setPositions(
          positions.map((p) => ({
            ...p,
            value: convertCurrency(p.value, order.currency, EURO, order.exchangeRates ?? context.currencies),
            id: p._id,
            isEditing: false,
          }))
        );
    }
  }, [order, field]);

  const systemEstimateValue = useMemo(() => {
    if (order && field) {
      const value = getSystemEstimateForAdjustableField(order, field);
      return convertCurrency(value, order.currency, EURO, order.exchangeRates ?? context.currencies);
    }
    return 0;
  }, [order, field]);

  const calculateTotal = () => {
    let value = systemEstimateValue;
    if (!systemEstimate) {
      value = positions.reduce((total, position) => total + (position.isEditing ? 0 : position.value), 0);
    }
    return formatCurrency(value, EURO);
  };

  const fieldDescription = useMemo(() => {
    return field ? getAdjustableFieldDescription(field) : "";
  }, [field]);

  const errors: Array<string> = useMemo(() => {
    if (!systemEstimate && positions.some((p) => p.isEditing)) return ["All positions need to be confirmed"];
    return [];
  }, [systemEstimate, positions]);

  if (!order || !field) return null;

  return (
    <Modal contentClassName="bg-dark " show={showModal} centered onHide={onCloseModal}>
      <Modal.Header className="border-0 pb-0">
        <Modal.Title>
          <h1 className="fw-bolder d-flex align-items-center text-white">
            Refine Calculation for {getOrderNumber(order)}
          </h1>
          <div className="fs-6 fw-bold text-muted">
            Override the system estimate for {fieldDescription} by adding costs below.
          </div>
        </Modal.Title>
        <CloseButton variant="white" onClick={onCloseModal} style={{ marginTop: -50 }} />
      </Modal.Header>
      <Modal.Body className="pt-5 pb-4">
        <div className="row my-2">
          <div className="col-6">
            <span className={"text-white font-weight-bold " + (!systemEstimate && "opacity-25")}>System Estimate</span>
          </div>
          <div className="col-6 d-flex align-items-center">
            <span className={"text-white " + (!systemEstimate && "text-decoration-line-through opacity-25")}>
              {formatCurrency(systemEstimateValue, EURO)}
            </span>
            <div className="d-flex ml-auto">
              <i
                className={"pr-0 cursor-pointer flaticon2-" + (systemEstimate ? "cross" : "refresh-button")}
                style={{ height: 16, width: 16, cursor: "pointer" }}
                onClick={handleToggleSystemEstimate}
              />
            </div>
          </div>
        </div>
        <hr style={{ borderTop: "1px solid rgb(127 127 127" }} />
        {positions.map((position) => (
          <Position
            key={position._id.toString()}
            data={position}
            onRemove={handleRemovePosition}
            onFinalize={handleFinalizePosition}
            disabled={systemEstimate}
          />
        ))}
        <div className="d-flex justify-content-end mt-3">
          <button
            className={"btn btn-text text-white font-weight-bold pr-0 " + (systemEstimate && "disabled")}
            disabled={systemEstimate}
            onClick={systemEstimate ? undefined : handleAddPosition}
          >
            Add Position
          </button>
        </div>
        <hr style={{ borderTop: "1px solid rgb(127 127 127" }} />
        <div className="row mt-3">
          <div className="col-6 d-flex align-items-center">
            <span className="text-white font-weight-bold">Total</span>
          </div>
          <div className="col-6 d-flex align-items-center justify-content-between">
            <span className="text-white font-weight-bold">
              <u>{calculateTotal()}</u>
            </span>
            <ErrorOverlayButton
              className="btn btn-sm btn-success"
              errors={errors}
              saving={saving}
              onClick={handleSaveAdjustedCalculation}
            >
              Update
            </ErrorOverlayButton>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AdjustCalculationModal;
