import _ from "lodash";
import { BSON } from "realm-web";
import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import draftToHtml from "draftjs-to-html";
import { convertToRaw, EditorState } from "draft-js";
import { DataContextInternalType } from "../../../../context/dataContext";
import {
  CO_ARCHIVED,
  CO_CANCELED,
  CO_ORDEREDBYCUSTOMER,
  CO_PROCESSINGATWAREHOUSE,
  CO_SHIPPEDTOCUSTOMER,
  CO_STATES,
  CO_T_DOCUMENTUPLOADED,
  CO_T_SHIPPEDCUSTOMER,
  CO_T_SHIPPINGINFORMATION,
  CO_T_TRACKINGINFORMATION,
  CustomerOrder,
  CustomerOrderExtended,
  CustomerOrderShippingInformation,
  CustomerOrderTrackingInformation,
  T_WAREHOUSE,
} from "../../../../model/customerOrder.types";
import { callPushToArray, formatCurrency, formatDate, toAbsoluteUrl } from "../../../../utils/baseUtils";
import { calculateArrivalInformation, getOrderNumber } from "../../../../utils/orderUtils";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import { resolveFilePath, shortenAlias } from "../../../../utils/fileUtils";
import {
  CO_CUSTOMERFILE,
  CO_DELIVERYNOTE,
  CO_ORDERCONFIRMATION,
  CO_OTHER,
  getCustomerOrderFile,
  getCustomerOrderTimelineEntry,
  getDocumentVersion,
  updateCustomerOrder,
} from "../../../../utils/customerOrderUtils";
import WorkflowDeliveryInformationModal from "./tabPanels/workflowTabPanels/modals/WorkflowDeliveryInformationModal";
import { Batch } from "../../../../model/batch.types";
import userService from "../../../../services/userService";
import WorkflowTrackingInformationModal from "./tabPanels/workflowTabPanels/modals/WorkflowTrackingInformationModal";
import UsedBatchesModal from "./tabPanels/workflowTabPanels/modals/UsedBatchesModal";
import { getTimeDiffString } from "../../../../utils/dateUtils";
import { CO_FOOTER_HTML, createPDF } from "../../../../utils/pdfUtils";
import { createDeliveryNoteHTML } from "../../../../utils/pdf/deliveryNoteGenerationUtils";
import { Action, CUSTOMERCONTRACT, CUSTOMERORDER, transaction } from "../../../../services/dbService";
import { OrderFile } from "../../../../model/commonTypes";
import { Invoice } from "../../../../model/invoice.types";
import SimpleConfirmationModal from "../../../common/SimpleConfirmationModal";
import Tooltip from "../../../common/Tooltip";
import { CC_T_CALLSHIPPED, getCustomerContractTimelineEntry } from "../../../../utils/customerContractUtils";
import { formatArticleUnit } from "../../../../utils/productArticleUtils";
import { reduceCustomerOrder } from "../../../../utils/dataTransformationUtils";

interface CustomerSpecificTaskProps {
  order: CustomerOrderExtended;
  editable: boolean;
  batches?: Array<Batch>;
  context: DataContextInternalType;
}

interface CustomerSpecificTaskState {
  saving: boolean;
  shippingInformation?: CustomerOrderShippingInformation;
  trackingInformation?: CustomerOrderTrackingInformation;
}

class CustomerSpecificTask extends PureComponent<CustomerSpecificTaskProps, CustomerSpecificTaskState> {
  constructor(props: CustomerSpecificTaskProps) {
    super(props);
    this.state = {
      saving: false,
      shippingInformation: props.order.shippingInformation,
      trackingInformation: props.order.trackingInformation,
    };
  }

  componentDidUpdate(prevProps: Readonly<CustomerSpecificTaskProps>) {
    const { order } = this.props;
    if (
      order.shippingInformation &&
      (!_.isEqual(order.shippingInformation, this.state.shippingInformation) ||
        !_.isEqual(prevProps.order.shippingInformation, order.shippingInformation))
    ) {
      this.setState({ shippingInformation: order.shippingInformation });
    }
    if (
      order.trackingInformation &&
      (!_.isEqual(order.trackingInformation, this.state.trackingInformation) ||
        !_.isEqual(prevProps.order.trackingInformation, order.trackingInformation))
    ) {
      this.setState({ trackingInformation: order.trackingInformation });
    }
  }

  /**
   * Get html representation for delivery address
   * @param deliveryAddress the WYSIWYG editor content/state containing the formatted target delivery address
   * @returns {string} html representation of delivery address
   */
  getDeliveryAddressHTML = (deliveryAddress: EditorState): string => {
    let address: string = draftToHtml(convertToRaw(deliveryAddress.getCurrentContent()));
    address = address.replace(/<p/g, "<span");
    address = address.replace(/p>/g, "span><br>");
    return address;
  };

  handleCreateDeliveryNoteDraft = async (
    shippingInformation: CustomerOrderShippingInformation,
    deliveryAddress: EditorState
  ) => {
    const { order } = this.props;
    this.setState({ saving: true });
    try {
      const path = await createPDF(
        createDeliveryNoteHTML(order, shippingInformation, this.getDeliveryAddressHTML(deliveryAddress), true),
        "DRAFT-DeliveryNote",
        order.company._id.toString(),
        { marginLeft: "2cm", marginBottom: "4.2cm", footerHtml: CO_FOOTER_HTML }
      );

      if (path) {
        window.open(resolveFilePath(path));
      } else {
        toast.error("Error creating delivery note preview");
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  handleSaveDeliveryInformation = async (shippingInformation: CustomerOrderShippingInformation) => {
    const { order } = this.props;
    this.setState({ saving: true });
    try {
      const timelineEntry = {
        _id: new BSON.ObjectId(),
        date: new Date(),
        type: CO_T_SHIPPINGINFORMATION,
        person: userService.getUserId(),
        payload: null,
      };
      const update: Partial<CustomerOrder> = {
        shippingInformation,
        deliveryDate: shippingInformation.estimatedDeliveryDate,
      };
      if (order.transport === T_WAREHOUSE && order.state === CO_ORDEREDBYCUSTOMER)
        update["state"] = CO_PROCESSINGATWAREHOUSE;
      const result = await updateCustomerOrder(update, order._id, timelineEntry);
      if (result && result.res.modifiedCount > 0) {
        toast.success("Shipping information successfully saved");
        this.setState({ shippingInformation });
      } else toast.error("Saving shipping information failed");
    } finally {
      this.setState({ saving: false });
    }
  };

  handleAddDeliveryInformation = async (
    shippingInformation: CustomerOrderShippingInformation,
    deliveryAddress: EditorState
  ) => {
    const { order, context } = this.props;
    const { requestDocumentUpdate } = context;
    this.setState({ saving: true });
    try {
      const path = await createPDF(
        createDeliveryNoteHTML(order, shippingInformation, this.getDeliveryAddressHTML(deliveryAddress), false),
        "DeliveryNote-" + order.orderNo,
        order.company._id.toString(),
        { marginLeft: "2cm", marginBottom: "4.2cm", footerHtml: CO_FOOTER_HTML }
      );
      if (!path) {
        toast.error("Delivery note creation failed");
        return;
      }
      const actions: Array<Action> = [
        {
          collection: CUSTOMERORDER,
          filter: { _id: order._id },
          update: { shippingInformation },
          push: {
            files: getCustomerOrderFile(path, CO_DELIVERYNOTE),
            timeline: {
              $each: [
                getCustomerOrderTimelineEntry(CO_T_SHIPPINGINFORMATION),
                getCustomerOrderTimelineEntry(CO_T_DOCUMENTUPLOADED, { type: CO_DELIVERYNOTE }),
              ],
            },
          },
        },
      ];
      if (order.transport === T_WAREHOUSE && order.state === CO_ORDEREDBYCUSTOMER) {
        actions.push({
          collection: CUSTOMERORDER,
          filter: { _id: order._id },
          update: { state: CO_PROCESSINGATWAREHOUSE },
        });
      }
      const result = await transaction(actions);
      if (result) {
        toast.success("Delivery information successfully created");
        this.setState({ shippingInformation });
        requestDocumentUpdate(CUSTOMERORDER, order._id);
      } else toast.error("Delivery information could not be created. Please try again later.");
    } finally {
      this.setState({ saving: false });
    }
  };

  handleSaveTrackingInformation = async (trackingInformation: CustomerOrderTrackingInformation) => {
    const { order } = this.props;
    this.setState({ saving: true });
    try {
      const timelineEntry = {
        _id: new BSON.ObjectId(),
        date: new Date(),
        type: CO_T_TRACKINGINFORMATION,
        person: userService.getUserId(),
        payload: null,
      };
      const update: Partial<CustomerOrder> = { trackingInformation };
      if (order.transport === T_WAREHOUSE && order.state === CO_ORDEREDBYCUSTOMER)
        update["state"] = CO_PROCESSINGATWAREHOUSE;
      const result = await updateCustomerOrder(update, order._id, timelineEntry);
      if (result && result.res.modifiedCount > 0) {
        toast.success("Tracking information successfully saved");
        this.setState({ trackingInformation });
      } else toast.error("Saving tracking information failed");
    } finally {
      this.setState({ saving: false });
    }
  };

  handleShipped = async () => {
    const { order } = this.props;
    this.setState({ saving: true });
    try {
      const timelineEntry = {
        _id: new BSON.ObjectId(),
        date: new Date(),
        type: CO_T_SHIPPEDCUSTOMER,
        person: userService.getUserId(),
        payload: null,
      };
      const result = await updateCustomerOrder(
        { state: CO_SHIPPEDTOCUSTOMER, deliveryDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24) }, // #TODO RB-329: Allow insertion of precise date. Until then next day is enough
        order._id,
        timelineEntry
      );
      if (result && result.res.modifiedCount > 0) {
        toast.success("Customer Order shipped successfully");
        if (order.contractInformation) {
          // Fire and forget update contract timeline
          const contractTimelineEntry = getCustomerContractTimelineEntry(CC_T_CALLSHIPPED, {
            name: getOrderNumber(order),
          });
          callPushToArray(CUSTOMERCONTRACT, order.contractInformation._id, "timeline", contractTimelineEntry);
        }
      } else {
        toast.error("Error shipping Customer Order");
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  validateData = () => {
    const { order } = this.props;
    const deliveryNote = order.files.find((f) => f.type === "deliveryNote");
    const missingServices = order.services.some((s) => !s.performed);
    const errors = [];
    if (order.state === CO_CANCELED) errors.push("Order is canceled");
    if (missingServices) errors.push("Services not performed");
    if (!order.shippingInformation) errors.push("No delivery information available yet");
    if (!deliveryNote) errors.push("Delivery note missing");
    if (!order.trackingInformation) errors.push("No tracking information available yet");
    if (!order.usedBatches) errors.push("No batches booked out yet");
    return errors;
  };

  render() {
    const { order, batches, editable, context } = this.props;
    const { trackingInformation, saving, shippingInformation } = this.state;
    const orderReduced = reduceCustomerOrder(order);
    const plannedArrival = calculateArrivalInformation(orderReduced);
    const arrival = calculateArrivalInformation(orderReduced, order.changedETA);
    const deliveryNote = order.files.some((f) => f.type === "deliveryNote");
    const confirmation = order.files.find((f) => f.type === CO_ORDERCONFIRMATION);
    const invoices = context.invoice.filter((i) => i.relatedOrder === order._id.toString());
    const invoice = invoices.length > 0;
    const files: Array<OrderFile | Invoice> = (order.files as Array<OrderFile | Invoice>).concat(invoices);
    const errors = this.validateData();
    const shipped = ([CO_SHIPPEDTOCUSTOMER, CO_ARCHIVED] as Array<CO_STATES>).includes(order.state);

    return (
      <div className="py-2">
        <div className="bg-light2 rounded p-5 mb-7">
          <div className="d-flex align-items-center ">
            <div className="flex-grow-1 me-2">
              {shipped && (
                <span className="fw-bold text-success mr-2" style={{ fontSize: "1.15rem" }}>
                  [SHIPPED]
                </span>
              )}
              <Link className="text-white fs-5 mb-1 custom-link" to={"/customer/" + order.company._id.toString()}>
                {order.company.name}
              </Link>
              <span className="text-muted fw-bold d-block">
                as{" "}
                <Link className="text-muted custom-link" to={"/customerOrder/" + order._id.toString()}>
                  {getOrderNumber(order)}
                </Link>
              </span>
            </div>
            <div className="text-right mr-4">
              {confirmation ? (
                <a href={resolveFilePath(confirmation.path)} target="_blank" rel="noopener noreferrer">
                  <img src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")} style={{ height: 24 }} alt="PDF" />
                </a>
              ) : (
                <img
                  src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")}
                  className="image-gray"
                  style={{ height: 24 }}
                  alt="PDF"
                />
              )}
            </div>
            <div className="text-right mr-4">
              <div className="fw-bold fs-6 text-gray-400">
                {order.amount} {formatArticleUnit(order.unit, order.commodity)}
              </div>
              <span className="text-muted mb-1">{formatCurrency(order.totalPrice, order.currency)}</span>
            </div>
          </div>
          {plannedArrival.target && (
            <div className="row mt-2">
              <div className="col-4 fw-bolder text-white">Original Target Date:</div>
              <div className="col-4 fw-bold text-gray-400">
                {plannedArrival.dateIsCW
                  ? `CW ${plannedArrival.cw}-${plannedArrival.year}`
                  : plannedArrival.dateIsFix
                  ? `${formatDate(plannedArrival.arrivalDate)} (fixed)`
                  : `${formatDate(plannedArrival.arrivalDate)}`}
              </div>
              <div className={"col-4 mb-1 " + (plannedArrival.late ? "text-danger" : "text-success")}>
                {getTimeDiffString(order.targetDate)}
              </div>
            </div>
          )}
          {arrival.target && order.changedETA && (
            <div className="row">
              <div className="col-4 fw-bolder text-white">Target Date (ETA)</div>
              <div className="col-4 fw-bold text-gray-400">
                {arrival.dateIsCW
                  ? `CW ${arrival.cw}-${arrival.year}`
                  : arrival.dateIsFix
                  ? `${formatDate(arrival.arrivalDate)} (fixed)`
                  : `${formatDate(arrival.arrivalDate)}`}
              </div>
              <div className={"col-4 mb-1 " + (arrival.late ? "text-danger" : "text-success")}>
                {getTimeDiffString(order.changedETA)}
              </div>
            </div>
          )}
          {plannedArrival.target && order.shippingInformation?.estimatedDeliveryDate && (
            <div className="row">
              <div className="col-4 fw-bolder text-white">Estimated Delivery Date:</div>
              <div className="col-4 fw-bold text-gray-400">
                {formatDate(order.shippingInformation?.estimatedDeliveryDate)}
              </div>
              <div className={"col-4 mb-1 " + (plannedArrival.late ? "text-danger" : "text-success")}>
                {getTimeDiffString(order.shippingInformation?.estimatedDeliveryDate)}
              </div>
            </div>
          )}
          {!plannedArrival.target && (
            <div className="row">
              <div className="col-4 fw-bolder text-white">Delivery Date:</div>
              <div className="col-4 fw-bold text-gray-400">{formatDate(plannedArrival.arrivalDate)}</div>
              <div className={"col-4 mb-1 " + (plannedArrival.late ? "text-danger" : "text-success")}>
                {getTimeDiffString(order.deliveryDate)}
              </div>
            </div>
          )}

          <div className="row mt-6">
            <div className="col-6 col-xl-4">
              <div className="fw-bolder text-white fs-5 mt-2 mb-3">Fulfillment</div>
              <div className="row my-2">
                <div className="col-12">
                  <UsedBatchesModal order={order} batches={batches} context={context} disabled={!editable} />
                </div>
              </div>
              <div className="row my-2">
                <div className="col-12">
                  <WorkflowDeliveryInformationModal
                    deliveryNote={false}
                    exists={!!shippingInformation}
                    order={order}
                    saving={saving}
                    disabled={!editable}
                    batches={batches}
                    shippingInformation={shippingInformation}
                    context={context}
                    onAddDeliveryInformation={this.handleAddDeliveryInformation}
                    onSaveDeliveryInformation={this.handleSaveDeliveryInformation}
                    onCreateDeliveryNoteDraft={this.handleCreateDeliveryNoteDraft}
                  />
                </div>
              </div>
              <div className="row my-2">
                <div className="col-12">
                  <WorkflowDeliveryInformationModal
                    deliveryNote={true}
                    exists={deliveryNote}
                    order={order}
                    saving={saving}
                    disabled={!editable}
                    batches={batches}
                    shippingInformation={shippingInformation}
                    context={context}
                    onAddDeliveryInformation={this.handleAddDeliveryInformation}
                    onSaveDeliveryInformation={this.handleSaveDeliveryInformation}
                    onCreateDeliveryNoteDraft={this.handleCreateDeliveryNoteDraft}
                  />
                </div>
              </div>
              <div className="row my-2">
                <div className="col-12">
                  <WorkflowTrackingInformationModal
                    order={order}
                    saving={saving}
                    trackingInformation={trackingInformation}
                    disabled={!editable}
                    onAddTrackingInformation={this.handleSaveTrackingInformation}
                  />
                </div>
              </div>
              <div className="row my-2">
                <div className="col-12">
                  <Tooltip show={invoice ? false : undefined} tooltipText="Create an invoice">
                    <span className="fw-bolder">
                      <span className="text-white">Invoice: </span>
                      <Link to={`/createInvoice/${order._id.toString()}?redirect`}>
                        <span
                          className={invoice ? "text-success text-success-hover" : "text-warning text-warning-hover"}
                        >
                          {invoice ? "Done" : "Missing"}
                        </span>
                      </Link>
                    </span>
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className="col-6 col-xl-4 ">
              <div className="fw-bolder text-white fs-5 mt-2 mb-3">Documents</div>
              <div className="overflow-auto" style={{ maxHeight: "150px" }}>
                {files.length > 0 ? (
                  files
                    .slice()
                    .reverse()
                    .map((f) => (
                      <div className="mb-2" key={f._id.toString()}>
                        <a
                          href={resolveFilePath("file" in f ? f.file : f.path)}
                          className="d-block "
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span className="text-white fw-bold text-ellipsis">
                            <div className="text-ellipsis" style={{ maxWidth: "100%" }}>
                              <span className="custom-link">
                                {shortenAlias("file" in f ? f.file : f.path)}{" "}
                                <span className="text-muted">
                                  {"date" in f &&
                                    f.type !== CO_OTHER &&
                                    f.type !== CO_CUSTOMERFILE &&
                                    "v" + getDocumentVersion(order, f.date, f.type)}
                                </span>
                              </span>
                            </div>
                          </span>
                        </a>
                      </div>
                    ))
                ) : (
                  <div className="text-muted ">No documents available</div>
                )}
              </div>
            </div>
            <div className="col-6 col-xl-4">
              <div className="fw-bolder text-white fs-5 my-2">Pending Services</div>
              <div className="overflow-auto pt-1" style={{ maxHeight: "150px" }}>
                {order.services.length > 0 ? (
                  order.services.map((oS) => (
                    <div key={oS.service._id.toString()} className="form-check">
                      <input className="form-check-input" checked={oS.performed} type="checkbox" disabled={true} />
                      <label
                        className={"fw-bold text-ellipsis mb-0 " + (oS.performed ? "text-muted" : "text-white")}
                        style={{ maxWidth: "100%" }}
                      >
                        RBS-{oS.service.serviceNo} {oS.service.title.en}
                      </label>
                    </div>
                  ))
                ) : (
                  <span className="text-muted">No services selected for this order</span>
                )}
              </div>
            </div>
          </div>
          {editable && (
            <div className="row">
              <div className="col-12 mt-4 text-right">
                {errors.length > 0 ? (
                  <ErrorOverlayButton
                    errors={errors}
                    className="btn btn-success btn-sm ml-2"
                    buttonText="Done"
                    onClick={() => true}
                    disabled={saving}
                  />
                ) : (
                  <SimpleConfirmationModal.SimpleConfirmationModalButton
                    size="md"
                    saving={saving}
                    modalTitle="Finish Order"
                    onConfirm={this.handleShipped}
                    confirmButtonText="Confirm"
                    buttonText="Done"
                    buttonClasses={"btn btn-success btn-sm " + (saving && "disabled")}
                    cancelButtonText="Close"
                    modalDescription={
                      <span className="text-white">
                        Do you really want to finish order <em>{getOrderNumber(order)}</em> and notify the customer that
                        the ordered commodities are on their way.
                      </span>
                    }
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default CustomerSpecificTask;
