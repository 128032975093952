import _ from "lodash";
import React, { Component, FunctionComponent, PureComponent } from "react";
import { RouteComponentProps, Switch, withRouter } from "react-router-dom";
import { BSON } from "realm-web";
import ProtectedRoute from "../routes/ProtectedRoute";
import CreateInvoice from "../../components/finance/internal/CreateInvoice";
import { DataContextInternal, getDataContextInternal } from "../../context/dataContext";
import { ActiveSubstance } from "../../model/activeSubstance.types";
import PropertyOverview from "../../components/properties/internal/PropertyOverview";
import { Property } from "../../model/property.types";
import { Commodity } from "../../model/commodity.types";
import { Notification } from "../../model/notification.types";
import { Article } from "../../model/article.types";
import Settings from "../../components/settings/internal/Settings";
import ActiveSubstanceOverview from "../../components/activeSubstances/internal/ActiveSubstanceOverview";
import CommodityListingWrapper from "../../components/commodities/common/CommodityListingWrapper";
import { Invoice } from "../../model/invoice.types";
import CommodityWrapper from "../../components/commodities/internal/CommodityWrapper";
import { Batch } from "../../model/batch.types";
import { CustomerOrder } from "../../model/customerOrder.types";
import { Service } from "../../model/service.types";
import { Supplier } from "../../model/supplier.types";
import Aside from "../../components/layout/Aside";
import Privacy from "../../components/formality/Privacy";
import { getDocumentDB, listenerInternal } from "../../services/dbService";
import { Company } from "../../model/company.types";
import { UserData } from "../../model/userData.types";
import UserProfile from "../../components/userData/UserProfile";
import ServiceOverview from "../../components/services/internal/ServiceOverview";
import WarehouseListingWrapper from "../../components/warehouse/WarehouseListingWrapper";
import SupplierListingWrapper from "../../components/suppliers/internal/SupplierListingWrapper";
import CustomerListingWrapper from "../../components/customers/internal/CustomerListingWrapper";
import InvoicesWrapper from "../../components/finance/internal/InvoicesWrapper";
import OrdersWrapper from "../../components/orders/internal/OrdersWrapper";
import { SupplierOrder } from "../../model/supplierOrder.types";
import CustomerWrapper from "../../components/customers/internal/CustomerWrapper";
import SupplierWrapper from "../../components/suppliers/internal/SupplierWrapper";
import CreateSupplierOrderWrapper from "../../components/order/CreateSupplierOrderWrapper";
import ChangePassword from "../../components/userData/ChangePassword";
import CustomerOrderWrapper from "../../components/orders/internal/customerOrder/CustomerOrderWrapper";
import SupplierOrderWrapper from "../../components/orders/internal/supplierOrder/SupplierOrderWrapper";
import SplashScreen from "../../components/common/SplashScreen";
import ScrollTop from "../../components/common/ScrollTop";
import { Currencies, getCurrencies } from "../../utils/currencyUtils";
import InternalDashboard from "../../components/dashboard/internal/InternalDashboard";
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import Tools from "../../components/tools/Tools";
import CommodityParsingTool from "../../components/tools/CommodityParsingTool";
import CustomerParsingTool from "../../components/tools/CustomerParsingTool";
import SupplierParsingTool from "../../components/tools/SupplierParsingTool";
import { General } from "../../model/general.types";
import VersionCheck from "../../components/common/VersionCheck";
import { INTERNAL, SCM } from "../../utils/userUtils";
import { VersionHistory } from "../../model/versionHistory.types";
import { SampleOrder } from "../../model/sampleOrder.types";
import SampleOrderWrapper from "../../components/orders/internal/sampleOrder/SampleOrderWrapper";
import GeneralValuesSetting from "../../components/settings/internal/GeneralValuesSetting";
import VersionHistorySetting from "../../components/settings/internal/VersionHistorySetting";
import { CommodityOfferRequest } from "../../model/commodityOfferRequest.types";
import { Seaport } from "../../model/seaport.types";
import SeaportOverview from "../../components/settings/internal/seaports/SeaportOverview";
import MasterSpecificationGenerationTool from "../../components/tools/MasterSpecificationGenerationTool";
import PriceCalculator from "../../components/tools/PriceCalculator";
import SeaportParsingTool from "../../components/tools/SeaportParsingTool";
import NewsWrapperInternal from "../../components/news/internal/NewsWrapperInternal";
import { Airport } from "../../model/airport.types";
import AirportOverview from "../../components/settings/internal/airports/AirportOverview";
import { Notify } from "../../model/notify.types";
import CalculationValuesConfiguration from "../../components/settings/internal/calculationValues/CalculationValuesConfiguration";
import { DATABASE_DOCUMENT } from "../../utils/dataContextUtils";
import NotifySetting from "../../components/settings/internal/NotifySetting";
import CreateCustomerContractWrapper from "../../components/customerContract/internal/CreateCustomerContractWrapper";
import { CustomerContract } from "../../model/customerContract.types";
import ContractListingWrapper from "../../components/contracts/internal/ContractListingWrapper";
import CustomerContractWrapper from "../../components/customerContract/internal/CustomerContractWrapper";
import { ControllingStatistics } from "../../model/statistics/controllingStatistics.types";
import ControllingDashboardWrapper from "../../components/dashboard/controlling/ControllingDashboardWrapper";
import { CustomerRequest } from "../../model/customerRequest.types";
import CreateCustomerOrderWrapper from "../../components/orders/internal/customerOrder/CreateCustomerOrderWrapper";
import { RefMap } from "../../components/common/CustomTypes";
import { ForwardingOrder } from "../../model/forwardingOrder.types";
import LogisticsListingWrapper from "../../components/logistics/internal/LogisticsListingWrapper";
import { sendMessage, getDefaultSlackChannel } from "../../services/slackService";
import ForwardingOrderWrapper from "../../components/logistics/internal/forwardingOrder/ForwardingOrderWrapper";
import StorageOrderWrapper from "../../components/logistics/internal/storageOrder/StorageOrderWrapper";
import { StorageOrder } from "../../model/storageOrder.types";
import { SystemNotification } from "../../model/systemNotification.types";
import SystemNotificationConfiguration from "../../components/tools/SystemNotificationConfiguration";
import { FinishedProduct } from "../../model/finishedProduct.types";
import FinishedProductWrapper from "../../components/finishedProducts/internal/FinishedProductWrapper";
import SCMDashboardWrapper from "../../components/dashboard/scm/SCMDashboardWrapper";
import AnonymousViewArticlePrices from "../../components/settings/internal/anonymousView/AnonymousViewArticlePrices";
import GraduationSettings from "../../components/settings/internal/graduationValues/GraduationSettings";
import { BaseConfiguration } from "../../model/configuration/configuration.types";
import { Forwarder } from "../../model/forwarder.types";
import ForwarderWrapper from "../../components/forwarder/internal/ForwarderWrapper";
import ForwarderListingWrapper from "../../components/forwarder/internal/ForwarderListingWrapper";
import EditSupplierOrderWrapper from "../../components/order/EditSupplierOrderWrapper";
import { PriceGraph } from "../../model/priceGraph.types";
import ResponsibleConfiguration from "../../components/settings/internal/responsible/ResponsibleConfiguration";
import CreditNoteWrapper from "../../components/finance/internal/CreditNoteWrapper";
import ExpenseValues from "../../components/settings/internal/expenseValues/ExpenseValues";
import DeliveryTimeSetting from "../../components/settings/internal/deliveryTimeSetting/DeliveryTimeSetting";
import CanceledOrders from "../../components/orders/common/CanceledOrders";
import CanceledContracts from "../../components/contracts/internal/CanceledContracts";
import RequestListing from "../../components/request/internal/RequestListing";
import PriceRequestWhitelistWrapper from "../../components/settings/internal/priceRequestWhitelist/PriceRequestWhitelistWrapper";
import CreateCommissionStockSupplierOrderWrapper from "../../components/order/CreateCommissionStockSupplierOrderWrapper";

interface InternalViewProps extends RouteComponentProps {}

interface InternalViewState {
  currentView: string;
  loadingData: boolean;
  currencies: Currencies;
  collections: {
    activeSubstance: Array<ActiveSubstance>;
    airport: Array<Airport>;
    batch: Array<Batch>;
    configuration: Array<BaseConfiguration>;
    commodity: Array<Commodity>;
    company: Array<Company>;
    customerContract: Array<CustomerContract>;
    customerOrder: Array<CustomerOrder>;
    customerRequest: Array<CustomerRequest>;
    finishedProduct: Array<FinishedProduct>;
    priceGraph: Array<PriceGraph>;
    forwarder: Array<Forwarder>;
    forwardingOrder: Array<ForwardingOrder>;
    sampleOrder: Array<SampleOrder>;
    property: Array<Property>;
    notification: Array<Notification>;
    invoice: Array<Invoice>;
    news: Array<Article>;
    service: Array<Service>;
    storageOrder: Array<StorageOrder>;
    supplierOrder: Array<SupplierOrder>;
    supplier: Array<Supplier>;
    userData: Array<UserData>;
    general: Array<General>;
    commodityOfferRequest: Array<CommodityOfferRequest>;
    versionHistory: Array<VersionHistory>;
    seaport: Array<Seaport>;
    notify: Array<Notify>;
    controllingStatistics: Array<ControllingStatistics>;
    systemNotification: Array<SystemNotification>;
  };
  innerWidth: number;
  savedState: { [key: string]: { state: object; date: Date } };
  refMap: RefMap;
}

class InternalView extends Component<InternalViewProps, InternalViewState> {
  _changeInProgress = false;

  constructor(props: InternalViewProps) {
    super(props);
    this.state = {
      currentView: INTERNAL,
      loadingData: true,
      currencies: {},
      collections: {
        activeSubstance: [],
        airport: [],
        batch: [],
        configuration: [],
        commodity: [],
        company: [],
        customerContract: [],
        customerOrder: [],
        customerRequest: [],
        finishedProduct: [],
        priceGraph: [],
        forwarder: [],
        forwardingOrder: [],
        sampleOrder: [],
        property: [],
        notification: [],
        invoice: [],
        news: [],
        service: [],
        storageOrder: [],
        supplierOrder: [],
        supplier: [],
        userData: [],
        general: [],
        commodityOfferRequest: [],
        versionHistory: [],
        seaport: [],
        notify: [],
        controllingStatistics: [],
        systemNotification: [],
      },
      innerWidth: window.innerWidth,
      savedState: {},
      refMap: {},
    };
  }

  async componentDidMount() {
    const t = new Date().getTime();
    const data = await getDataContextInternal();
    const currencies = await getCurrencies();
    // Saving loading time in local storage
    localStorage.setItem("t", (new Date().getTime() - t).toString());
    window.addEventListener("resize", this.handleResize);
    this.setState(
      {
        loadingData: false,
        currencies: currencies.currencies ?? {},
        collections: {
          ...data,
        },
      },
      () => listenerInternal(this.updateDatabase)
    );
    this.getCurrencies(); // fire and forget
  }

  handleResize = () => {
    this.setState({ innerWidth: window.innerWidth });
  };

  handleChangeToggleView = () => {
    const { history } = this.props;
    const { currentView } = this.state;
    const newView = currentView === INTERNAL ? SCM : INTERNAL;
    history.push(newView === SCM ? "/scm" : "/dashboard");
    this.setState({ currentView: newView });
  };

  getCurrencies = async () => {
    const currencies = await getCurrencies();
    if (currencies.currencies) this.setState({ currencies: currencies.currencies });
    setTimeout(() => this.getCurrencies(), 2 * 60 * 60 * 1000);
  };

  updateDatabase = async (change: Realm.Services.MongoDB.ChangeEvent<Realm.Services.MongoDB.Document>) => {
    // Ignore events we don't care about
    if (!("ns" in change) || !("coll" in change.ns) || !("documentKey" in change)) return;
    const collection = change.ns.coll as keyof typeof this.state.collections;
    const action = change.operationType;
    const documentKey = change.documentKey._id as BSON.ObjectId;
    if (action === "delete") {
      const { collections } = this.state;
      // @ts-ignore
      collections[collection] = collections[collection].filter(
        (item: DATABASE_DOCUMENT) => item && item._id.toString() !== documentKey.toString()
      ) as Array<DATABASE_DOCUMENT>;
      this.setState({
        collections,
      });
      return;
    }
    let indexNew = null;
    switch (action) {
      case "replace":
      case "update":
        try {
          let iterations = 0;
          while (this._changeInProgress) {
            if (iterations > 100) break; // Just risk it after 5s now - we can't wait too long
            await new Promise((resolve) => setTimeout(resolve, 50));
            iterations++;
          }
          this._changeInProgress = true;
          const collNew = { ...this.state.collections };
          const newCollection = collNew[collection].slice() as Array<DATABASE_DOCUMENT>;
          indexNew = newCollection.findIndex((d: DATABASE_DOCUMENT) => d._id.toString() === documentKey.toString());
          newCollection.splice(indexNew, 1, change.fullDocument as DATABASE_DOCUMENT);
          // @ts-ignore
          collNew[collection] = newCollection;
          this.setState({ collections: collNew });
        } finally {
          this._changeInProgress = false;
        }
        break;
      case "insert":
        try {
          while (this._changeInProgress) {
            await new Promise((resolve) => setTimeout(resolve, 50));
          }
          this._changeInProgress = true;
          const collNew = { ...this.state.collections };
          const collCopy = collNew[collection].slice() as Array<DATABASE_DOCUMENT>;
          collCopy.push(change.fullDocument as DATABASE_DOCUMENT);
          // @ts-ignore
          collNew[collection] = collCopy;
          this.setState({ collections: collNew });
        } finally {
          this._changeInProgress = false;
        }
        break;
      default: {
        const message = `INTERNAL - Unhandled event received: ${action}`;
        console.warn(message, change);
        sendMessage(
          getDefaultSlackChannel(true),
          message + `\n *Change Event:* \`\`\` ${JSON.stringify(change, null, 2)} \`\`\`\n`
        );
        break;
      }
    }
  };

  /**
   * Add the given documents to the context and the given collection. Only documents that were not already inside context are added.
   * @param collection Collection that should be updated
   * @param documents Documents that should be added to the context
   */
  addDocuments = (collection: string, documents: Array<DATABASE_DOCUMENT>) => {
    const { collections } = this.state;

    // @ts-ignore
    if (collection in collections && collections[collection]) {
      // @ts-ignore
      const coll = collections[collection] as Array<DATABASE_DOCUMENT>;
      let updated = false;
      const newDocuments = [];
      for (const doc of documents) {
        if (coll.some((d: DATABASE_DOCUMENT) => d._id.toString() === doc._id.toString())) {
          newDocuments.push(doc);
          updated = true;
        }
      }
      coll.concat(newDocuments);
      if (updated) this.setState({ collections });
    }
  };

  /**
   * Requests a document update from database. If the collection is invalid or no document is found nothing happens
   * @param collection Database collection name
   * @param documentId ID of the requested document
   */
  requestDocumentUpdate = async (collection: string, documentId: BSON.ObjectId) => {
    const { collections } = this.state;
    if (collection in collections) {
      const databaseDocument = await getDocumentDB<DATABASE_DOCUMENT>(collection, documentId);
      if (databaseDocument) {
        this.setState((prevState) => {
          const col: Array<DATABASE_DOCUMENT> = _.get(prevState.collections, collection);
          const colFiltered = col.filter((c) => c._id.toString() !== documentId.toString());
          colFiltered.push(databaseDocument);
          return { collections: { ...prevState.collections, [collection]: colFiltered } };
        });
      }
    }
  };

  /**
   * Save a components state
   * @param key key, i.e. class name
   * @param state the state to save
   */
  saveComponentState = (key: string, state: object) => {
    const savedState = { ...this.state.savedState };
    savedState[key] = { state, date: new Date() };
    this.setState({ savedState });
  };

  /**
   * Save a ref under the given key
   * @param key Key of the ref, e.g. a class name
   * @param ref Ref that should be saved (a component)
   */
  saveRef = (key: string, ref: PureComponent | FunctionComponent) => {
    this.setState((prevState) => {
      return { refMap: { ...prevState.refMap, [key]: ref } };
    });
  };

  /**
   * Deletes the given key from the ref map.
   * @param key Key that should be deleted
   */
  deleteRef = (key: string) => {
    const refMap = { ...this.state.refMap };
    delete refMap[key];
    this.setState({ refMap });
  };

  render() {
    const { currentView, loadingData, collections, currencies, innerWidth, savedState, refMap } = this.state;
    const context = {
      type: INTERNAL,
      ...collections,
      currencies,
      addDocuments: this.addDocuments,
      savedState,
      refMap,
      innerWidth,
      saveComponentState: this.saveComponentState,
      saveRef: this.saveRef,
      deleteRef: this.deleteRef,
      currentView: currentView,
      requestDocumentUpdate: this.requestDocumentUpdate,
    };
    return (
      <DataContextInternal.Provider value={context}>
        {loadingData ? (
          <SplashScreen />
        ) : (
          <>
            <div className="d-flex flex-column flex-root">
              <div className="page d-flex flex-row flex-column-fluid">
                <Aside view="internal" />
                <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                  <Header context={context} onChangeToggleView={this.handleChangeToggleView} />
                  <div className="mb-20 mt-10">
                    <Switch>
                      <ProtectedRoute exact path="/dashboard" component={InternalDashboard} />
                      <ProtectedRoute exact path="/scm" component={SCMDashboardWrapper} />
                      <ProtectedRoute exact path="/controlling" component={ControllingDashboardWrapper} />
                      <ProtectedRoute
                        exact
                        path="/createSupplierOrder/:type/:id"
                        component={CreateSupplierOrderWrapper}
                      />
                      <ProtectedRoute exact path="/createSupplierOrder/:id" component={CreateSupplierOrderWrapper} />
                      <ProtectedRoute
                        exact
                        path="/createCommissionStockOrder/:type/:id"
                        component={CreateCommissionStockSupplierOrderWrapper}
                      />
                      <ProtectedRoute exact path="/editSupplierOrder/:type/:id" component={EditSupplierOrderWrapper} />
                      <ProtectedRoute exact path="/editSupplierOrder/:id" component={EditSupplierOrderWrapper} />
                      <ProtectedRoute exact path="/editSupplierOrder/" component={EditSupplierOrderWrapper} />
                      <ProtectedRoute
                        exact
                        path="/createCustomerOrder/:type/:id"
                        component={CreateCustomerOrderWrapper}
                      />
                      <ProtectedRoute exact path="/createCustomerOrder" component={CreateCustomerOrderWrapper} />
                      <ProtectedRoute
                        exact
                        path="/createCustomerContract/:type/:id"
                        component={CreateCustomerContractWrapper}
                      />
                      <ProtectedRoute exact path="/createCustomerContract" component={CreateCustomerContractWrapper} />
                      <ProtectedRoute
                        exact
                        path="/createStockOrder/:id"
                        render={() => <CreateSupplierOrderWrapper forStock={true} />}
                      />
                      <ProtectedRoute exact path="/orders" component={OrdersWrapper} />
                      <ProtectedRoute exact path="/canceledOrders" component={CanceledOrders} />
                      <ProtectedRoute exact path="/canceledContracts" component={CanceledContracts} />
                      <ProtectedRoute exact path="/contracts" component={ContractListingWrapper} />
                      <ProtectedRoute exact path="/customerContract/:id" component={CustomerContractWrapper} />
                      <ProtectedRoute exact path="/customerOrder/:id" component={CustomerOrderWrapper} />
                      <ProtectedRoute exact path="/supplierOrder/:id" component={SupplierOrderWrapper} />
                      <ProtectedRoute exact path="/sampleOrder/:id" component={SampleOrderWrapper} />
                      <ProtectedRoute exact path="/articles" component={CommodityListingWrapper} />
                      <ProtectedRoute exact path="/commodity/:id" component={CommodityWrapper} />
                      <ProtectedRoute exact path="/commodity" component={CommodityWrapper} />
                      <ProtectedRoute exact path="/finishedProduct" component={FinishedProductWrapper} />
                      <ProtectedRoute exact path="/finishedProduct/:id" component={FinishedProductWrapper} />
                      <ProtectedRoute exact path="/supplier/:id/:cor" component={SupplierWrapper} />
                      <ProtectedRoute exact path="/supplier/:id" component={SupplierWrapper} />
                      <ProtectedRoute exact path="/suppliers" component={SupplierListingWrapper} />
                      <ProtectedRoute exact path="/customer/:id" component={CustomerWrapper} />
                      <ProtectedRoute exact path="/customers" component={CustomerListingWrapper} />
                      <ProtectedRoute exact path="/warehouse" component={WarehouseListingWrapper} />
                      <ProtectedRoute exact path="/customerInvoices" component={InvoicesWrapper} />
                      <ProtectedRoute exact path="/logistics" component={LogisticsListingWrapper} />
                      <ProtectedRoute exact path="/forwarder/:id" component={ForwarderWrapper} />
                      <ProtectedRoute exact path="/forwarders" component={ForwarderListingWrapper} />
                      <ProtectedRoute exact path="/tools" component={Tools} />
                      <ProtectedRoute exact path="/commodityParsingTool" component={CommodityParsingTool} />
                      <ProtectedRoute exact path="/customerParsingTool" component={CustomerParsingTool} />
                      <ProtectedRoute exact path="/supplierParsingTool" component={SupplierParsingTool} />
                      <ProtectedRoute exact path="/seaportParsingTool" component={SeaportParsingTool} />
                      <ProtectedRoute exact path="/priceCalculator" component={PriceCalculator} />
                      <ProtectedRoute
                        exact
                        path="/masterSpecGenerationTool"
                        component={MasterSpecificationGenerationTool}
                      />
                      <ProtectedRoute
                        exact
                        path="/systemNotificationConfiguration"
                        render={() => (
                          <SystemNotificationConfiguration
                            systemNotifications={collections.systemNotification}
                            commodities={collections.commodity}
                          />
                        )}
                      />
                      <ProtectedRoute
                        exact
                        path="/priceRequestWhitelistConfiguration"
                        component={PriceRequestWhitelistWrapper}
                      />
                      <ProtectedRoute
                        exact
                        path="/anonymousViewArticlePrices"
                        render={() => <AnonymousViewArticlePrices context={context} />}
                      />
                      <ProtectedRoute
                        exact
                        path="/priceGraduationConfiguration"
                        render={() => <GraduationSettings context={context} />}
                      />
                      <ProtectedRoute
                        exact
                        path="/generalValuesSetting"
                        render={() => <GeneralValuesSetting general={collections.general} />}
                      />
                      <ProtectedRoute exact path="/expenseValues" component={ExpenseValues} />
                      <ProtectedRoute exact path="/news/:id" component={NewsWrapperInternal} />
                      <ProtectedRoute exact path="/news" component={NewsWrapperInternal} />
                      <ProtectedRoute
                        exact
                        path="/versionHistorySetting"
                        render={() => <VersionHistorySetting versionHistory={collections.versionHistory} />}
                      />
                      <ProtectedRoute exact path="/privacy" component={Privacy} />
                      <ProtectedRoute exact path="/settings" component={Settings} />
                      <ProtectedRoute exact path="/properties" component={PropertyOverview} />
                      <ProtectedRoute exact path="/activeSubstances" component={ActiveSubstanceOverview} />
                      <ProtectedRoute exact path="/seaports" component={SeaportOverview} />
                      <ProtectedRoute exact path="/airports" component={AirportOverview} />
                      <ProtectedRoute exact path="/priceCalculationValues" component={CalculationValuesConfiguration} />
                      <ProtectedRoute exact path="/responsibleConfiguration" component={ResponsibleConfiguration} />
                      <ProtectedRoute exact path="/deliveryTimesSetting" component={DeliveryTimeSetting} />
                      <ProtectedRoute exact path="/services" component={ServiceOverview} />
                      <ProtectedRoute exact path="/notifies" component={NotifySetting} />
                      <ProtectedRoute exact path="/createForwardingOrder" component={ForwardingOrderWrapper} />
                      <ProtectedRoute exact path="/createForwardingOrder/:id" component={ForwardingOrderWrapper} />
                      <ProtectedRoute exact path="/createStorageOrder" component={StorageOrderWrapper} />
                      <ProtectedRoute exact path="/createStorageOrder/:id" component={StorageOrderWrapper} />
                      <ProtectedRoute exact path="/createInvoice" component={CreateInvoice} />
                      <ProtectedRoute exact path="/createInvoice/:type/:id" component={CreateInvoice} />
                      <ProtectedRoute exact path="/createInvoice/:id" component={CreateInvoice} />
                      <ProtectedRoute exact path="/createCreditNote" component={CreditNoteWrapper} />
                      <ProtectedRoute exact path="/createCreditNote/:id" component={CreditNoteWrapper} />
                      <ProtectedRoute
                        exact
                        path="/createReminder/:id"
                        render={() => <CreateInvoice reminder={true} />}
                      />
                      <ProtectedRoute
                        exact
                        path="/createCancelation/:id"
                        render={() => <CreateInvoice cancelation={true} />}
                      />
                      <ProtectedRoute path="/requests" component={RequestListing} />
                      <ProtectedRoute path="/profile" component={UserProfile} />
                      <ProtectedRoute path="/changePassword" component={ChangePassword} />
                    </Switch>
                  </div>
                  <Footer versionHistory={collections.versionHistory} />
                  <ScrollTop />
                </div>
              </div>
            </div>
            <VersionCheck view={INTERNAL} />
          </>
        )}
      </DataContextInternal.Provider>
    );
  }
}

export default withRouter(InternalView);
