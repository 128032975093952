import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import CustomSelect, { SelectOption } from "../common/CustomSelect";
import { ALLOWED_SEPARATORS, generateCustomersCSVString, parseCSV } from "../../utils/csvUtils";
import { DataContextInternal } from "../../context/dataContext";
import { CompanyExtended } from "../../model/company.types";
import { UserData } from "../../model/userData.types";
import { INTERNAL } from "../../utils/userUtils";
import { insertCompaniesAndRelatedDocuments } from "../../utils/companyUtils";
import userService from "../../services/userService";
import { AddressType } from "../../model/commonTypes";
import { reduceCompany } from "../../utils/dataTransformationUtils";
import { exportDatasheet } from "../../utils/excelUtils";

interface CustomerParsingToolProps {}

interface CustomerParsingToolState {
  content: Array<string>;
  separator: SelectOption;
  processing: boolean;
  addUsers: Array<UserData>;
  customers: Array<CompanyExtended>;
  errors: Array<string>;
}

class CustomerParsingTool extends PureComponent<CustomerParsingToolProps, CustomerParsingToolState> {
  static contextType = DataContextInternal;
  context!: React.ContextType<typeof DataContextInternal>;
  selectFileRef: React.RefObject<HTMLInputElement>;

  constructor(props: CustomerParsingToolProps) {
    super(props);
    this.selectFileRef = React.createRef();
    this.state = this.getDefaultState();
  }

  handleDownloadExampleXLSX = () => {
    const csv = generateCustomersCSVString([], this.context, true);
    exportDatasheet(csv, "CustomerExample");
  };

  handleChangeSeparator = (separator: SelectOption) => this.setState({ separator });

  handleParseCustomerCSV = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const { separator } = this.state;
    const { company, userData } = this.context;
    this.setState({ processing: true });
    const file = await e.target.files[0].text();
    const content: Array<string> = file.split(/[\r\n]+/);
    this.setState({ content });
    const parsedCSV = parseCSV(content, separator.value);
    const errors = [];
    const addUsers = [];
    const customers = [];
    for (let i = 1; i < parsedCSV.length; i++) {
      try {
        const l = parsedCSV[i];
        // When there is no name we do not create the customer
        if (!l[0].trim()) {
          errors.push("Line " + i + " contained no tile, thus it was skipped.");
          console.error("LINE", i, "CONTAINED NO TITLE - SKIPPING");
          continue;
        }
        if (company.find((c) => c.name.toLowerCase() === l[0].trim().toLowerCase())) {
          errors.push("Company " + l[0] + " already exists, thus it was skipped.");
          console.error("COMPANY", l[0], "ALREADY EXISTS - SKIPPING");
          continue;
        }
        const internalContact = userData.find(
          (u) => u.type === INTERNAL && (u.prename + " " + u.surname).trim().toLowerCase() === l[1].trim().toLowerCase()
        );
        if (!internalContact) {
          errors.push(
            "Internal contact of company " + l[0] + " does not exist. Please replace with existing one - thus skipped."
          );
          console.error("INTERNAL CONTACT OF COMPANY", l[0], "DOES NOT EXIST - PLEASE REPLACE WITH EXISTING ONE");
          continue;
        }
        const paymentTarget =
          l[5].trim().toLowerCase() === "in advance" ? -1 : Number(l[5].toLowerCase().split("days")[0]);
        const paymentTerms = {
          paymentTarget: l[5].trim().toLowerCase() === "in advance" ? "-1" : l[5].toLowerCase().split("days")[0],
          paymentTargetConditions: "",
        };
        const companyID = new BSON.ObjectId();
        const primaryPerson: UserData = {
          _id: new BSON.ObjectId(),
          prename: l[14].trim().split(" ")[0],
          surname: l[14].trim().split(" ")[1] ?? "",
          company: companyID.toString(),
          emails: [],
          position: "",
          image: "",
          phones: [],
          notifications: { language: "en", settings: [] },
          type: "customer",
          onboardingDone: false,
          roles: ["Customer"],
          userId: "",
        };
        addUsers.push(primaryPerson);
        const customer: CompanyExtended = {
          _id: companyID,
          name: l[0].trim(),
          internalContact,
          rating: Number(l[2]),
          vat: l[3].trim(),
          creditLimit: Number(l[4]),
          paymentTarget,
          paymentTerms,
          mail: l[6].trim(),
          phone: l[7].trim(),
          address: [
            {
              _id: new BSON.ObjectId(),
              name: l[8],
              street: l[9],
              houseNo: l[10],
              postalCode: l[11],
              city: l[12],
              country: l[13],
              type: AddressType.A_PRIMARY,
            },
          ],
          primaryPerson,
          notes: l[15].trim(),
          persons: [],
          disabled: false,
          activated: false,
        };
        customers.push(customer);
      } catch (e) {
        console.error("ERROR IN LINE", i, ":", e);
      }
    }
    this.setState({ customers, addUsers, errors, processing: false });
  };

  handleClickCancel = () => {
    this.setState(this.getDefaultState());
  };

  handleClickAddToDatabase = async () => {
    const { addUsers, customers } = this.state;
    try {
      const res = await insertCompaniesAndRelatedDocuments(
        addUsers,
        customers.map((c) => reduceCompany(c))
      );
      if (res) {
        toast.success("Added companies and related documents successfully");
      } else {
        toast.error("Error adding companies and related documents");
      }
    } catch (e) {
      console.error("ERROR WRITING TO DB:", e);
    }
  };

  getDefaultState = () => {
    return {
      content: [],
      customers: [],
      addUsers: [],
      errors: [],
      processing: false,
      separator: ALLOWED_SEPARATORS[0],
    };
  };

  render() {
    const { content, separator, processing, customers, errors } = this.state;
    if (!userService.isAdmin())
      return (
        <div className="content d-flex flex-column flex-column-fluid">
          <div className="post d-flex flex-column-fluid">
            <div className="container-xxl">
              <div className="card bg-white min-h-100">
                <div className="card-body">
                  <h3 className="card-title ">
                    <span className="card-label fw-bolder fs-3rem">Customer Parsing Tool</span>
                  </h3>
                  <h5 className="mt-20 text-center">
                    <span className="text-muted">You do not have access to this section</span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl">
            <div className="card bg-white">
              <div className="card-body">
                <h3 className="card-title align-items-start flex-column mb-15">
                  <span className="card-label fw-bolder mb-3 fs-3rem">Customer Parsing Tool</span>
                </h3>
                {processing && customers.length === 0 && content.length > 0 ? (
                  <div className="row">
                    <div className="col text-center m-10">
                      <span className="h3 text-white">Processing Data - Please wait!</span>
                    </div>
                  </div>
                ) : content.length === 0 ? (
                  <>
                    <span className="text-white">Please provide a CSV in the following format:</span>
                    <div className="my-4">
                      <button
                        className="btn btn-outline btn-outline-light"
                        onClick={processing ? undefined : this.handleDownloadExampleXLSX}
                        disabled={processing}
                      >
                        Download Example XLSX
                      </button>
                    </div>
                    <div>
                      <span className="text-white h3">Important Information:</span>
                      <br />
                      <span className="text-white ">- Do NOT use the selected separator inside any fields</span>
                      <br />
                      <span className="text-white ">- Provide the credit limit in Euros</span>
                      <br />
                      <span className="text-white ">- The payment target needs to be "In Advance" or "XX Days"</span>
                      <br />
                      <span className="text-white ">
                        - Double check that there are no typos in the name of the internal contact!
                      </span>
                      <br />
                    </div>
                    <div className="border-bottom-dark-gray mt-4" />
                    <div className="my-4">
                      <span className="text-white">
                        Please provide a CSV which fits the specified format and upload it - you can still check your
                        input before any database operation is performed.
                      </span>
                      <div className="my-4 row">
                        <div className="col-4">
                          <button
                            className="btn btn-outline btn-outline-light"
                            type="button"
                            disabled={processing}
                            onClick={() => this.selectFileRef.current?.click()}
                          >
                            Upload Customer CSV
                          </button>
                          <input
                            type="file"
                            ref={this.selectFileRef}
                            accept="text/csv"
                            style={{ display: "none" }}
                            onChange={processing ? undefined : this.handleParseCustomerCSV}
                          />
                        </div>
                        <div className="col-6 text-right align-self-center">
                          <span className="text-white">Separator:</span>
                        </div>
                        <div className="col-2">
                          <CustomSelect
                            options={ALLOWED_SEPARATORS}
                            onChange={this.handleChangeSeparator}
                            value={separator}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : processing ? (
                  <div className="row">
                    <div className="col text-center m-10">
                      <span className="h3 text-white">
                        CSV was parsed - Generating Customers.
                        <br />
                        {content.length - 1} Lines
                        <br />
                        {customers.length} Customer were parsable
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-12 m-10">
                      <span className="h3 text-white">
                        {content.length - 1} Lines were provided
                        <br />
                        {customers.length} Customers were parsable
                        <br />
                        {errors.length > 0 && (
                          <span>
                            The following errors were detected:
                            <br />
                            {errors.map((e, idx) => (
                              <div key={idx}>{e}</div>
                            ))}
                          </span>
                        )}
                      </span>
                    </div>
                    <div className="border-bottom-dark-gray" />
                    <div className="col-12 mt-4">
                      <button
                        className="btn btn-outline btn-outline-light float-right ml-2"
                        onClick={this.handleClickAddToDatabase}
                      >
                        Add to Database
                      </button>
                      <button
                        className="btn btn-outline btn-outline-light float-right"
                        onClick={this.handleClickCancel}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomerParsingTool;
