import { utils, writeFile } from "xlsx";
import { DEFAULTSEPARATOR } from "./csvUtils";

/**
 * Helper function to convert csv strings into an Array of Arrays
 * @param csv The csv represented as string
 * @param separator The separator to use
 * @returns {Array<Array<string>>} Result as Array
 */
function convertCSVStringToArray(csv: string, separator: string): Array<Array<string>> {
  return csv.split("\n").map((row) => row.split(separator));
}

/**
 * Function to export a .xlsx file
 * @param datasheet The datasheet to export represented as csv string or Columns and Rows as Array of Arrays
 * @param filename The filename to use
 * @param separator Optional, a given separator to use
 */
export function exportDatasheet(datasheet: string | Array<Array<string>>, filename: string, separator?: string) {
  let datasheetAsArray: Array<Array<string>>;
  if (typeof datasheet === "string") {
    datasheetAsArray = convertCSVStringToArray(datasheet, separator ?? DEFAULTSEPARATOR);
  } else {
    datasheetAsArray = datasheet;
  }
  const workSheet = utils.aoa_to_sheet(datasheetAsArray);
  const workBook = utils.book_new();
  utils.book_append_sheet(workBook, workSheet);
  writeFile(workBook, filename + ".xlsx");
}
