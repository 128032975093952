import _ from "lodash";
import React, { PureComponent } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { DataContextInternalType } from "../../../../context/dataContext";
import { PROPERTYOPTIONS, PropertyType } from "../../../../utils/propertyUtils";
import {
  FinishedProduct,
  FinishedProductExtended,
  TRANSPORTCONDITIONTYPE,
} from "../../../../model/finishedProduct.types";
import { FINISHEDPRODUCT } from "../../../../services/dbService";
import { Property } from "../../../../model/property.types";
import { COMMODITYCREATIONKEYS, generateSubtitle } from "../../../../utils/commodityUtils";
import { SelectOption } from "../../../common/CustomSelect";
import { ActiveSubstance } from "../../../../model/activeSubstance.types";
import CreatePropertyModal from "../../../properties/internal/modals/CreatePropertyModal";
import CloneCommodityModal from "../../../commodities/internal/modals/CloneCommodityModal";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import Search from "../../../common/Search";
import CommodityActiveSubstanceEditListing from "../../../commodities/internal/CommodityActiveSubstanceEditListing";
import CommodityActiveSubstanceListing from "../../../commodities/common/CommodityActiveSubstanceListing";
import PropertiesSelection from "../../../common/PropertiesSelection";
import PropertiesWidget from "../../../common/PropertiesWidget";
import {
  FP_G_TYPES,
  getDefaultPackagingSize,
  getFinishedProductEditTimelineEntry,
  getFinishedProductTimelineEntry,
  insertFinishedProduct,
  T_FINISHEDPRODUCTAPPROVED,
  T_FINISHEDPRODUCTCREATED,
  T_FINISHEDPRODUCTDISABLED,
  T_FINISHEDPRODUCTENABLED,
  TC_TEMPERATURE,
  updateFinishedProduct,
} from "../../../../utils/finishedProductUtils";
import FinishedProductPageAdvancedInformationBlock from "../FinishedProductPageAdvancedInformationBlock";
import FinishedProductPageGeneralInformationBlock, {
  TransportConditionSelect,
} from "../FinishedProductPageGeneralInformationBlock";
import MasterSpecification from "../../../specification/internal/MasterSpecification";
import { reduceFinishedProduct } from "../../../../utils/dataTransformationUtils";
import TableOfContents from "../../../common/TableOfContents";
import { TableOfContentsEntry } from "../../../common/CustomTypes";

interface FinishedProductPageGeneralProps extends RouteComponentProps {
  finishedProduct: FinishedProductExtended;
  context: DataContextInternalType;
}

interface FinishedProductPageGeneralState {
  edit: boolean;
  create: boolean;
  finishedProductEdit: FinishedProductExtended;
  showPropertyCreationModal: boolean;
  propertyCreationType?: PropertyType;
  search: string;
  saving: boolean;
}

class FinishedProductPageGeneral extends PureComponent<
  FinishedProductPageGeneralProps,
  FinishedProductPageGeneralState
> {
  tableOfContents: Array<TableOfContentsEntry> = [
    { href: "#basicInformation", label: "Basic Information" },
    { href: "#advancedInformation", label: "Advanced Information" },
    { href: "#activeSubstances", label: "Active Substances" },
    { href: "#allergens", label: "Allergens" },
    { href: "#tags", label: "Tags" },
    { href: "#masterSpecification", label: "Master Specification" },
  ];

  constructor(props: FinishedProductPageGeneralProps) {
    super(props);
    const create = !!new URLSearchParams(props.location.search).get("create");
    const clone = !!new URLSearchParams(props.location.search).get("clone");

    this.state = {
      edit: create || clone ? true : !props.finishedProduct,
      create: create || clone,
      finishedProductEdit: _.cloneDeep(props.finishedProduct),
      showPropertyCreationModal: false,
      search: "",
      saving: false,
    };
  }

  componentDidUpdate(prevProps: Readonly<FinishedProductPageGeneralProps>) {
    const create = !!new URLSearchParams(this.props.location.search).get("create");
    const clone = !!new URLSearchParams(this.props.location.search).get("clone");
    if (clone && !this.state.create && !this.state.edit) {
      this.setState({ create: true, edit: true });
    }
    if (!_.isEqual(prevProps.finishedProduct, this.props.finishedProduct)) {
      const finishedProduct = _.cloneDeep(this.props.finishedProduct);
      this.setState({
        finishedProductEdit: finishedProduct,
        edit: create || clone ? true : !this.props.finishedProduct,
        create: create || clone,
      });
    }
  }

  handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value });

  handleEditToggle = () =>
    this.setState({ edit: !this.state.edit, finishedProductEdit: _.cloneDeep(this.props.finishedProduct) });

  handleCloneFinishedProduct = () => {
    this.props.history.push(`/finishedProduct/${this.state.finishedProductEdit._id.toString()}?clone=true`);
  };

  handleCancelCreate = () => {
    const clone = !!new URLSearchParams(this.props.location.search).get("clone");
    if (clone) {
      this.props.history.goBack();
    } else {
      this.props.history.push("/articles");
    }
  };

  handleEditFinishedProductInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const finishedProduct = _.cloneDeep(this.state.finishedProductEdit);
    _.set(finishedProduct, e.target.name, e.target.type === "number" ? +e.target.value : e.target.value);
    this.setState({ finishedProductEdit: finishedProduct });
  };

  handleEditFinishedProductSelectExclusiveProperty = (e: SelectOption<Property>, key: PropertyType) => {
    const finishedProduct = _.cloneDeep(this.state.finishedProductEdit);
    const { showPropertyCreationModal } = this.state;
    if (e && e.value === "new") {
      this.setState({ showPropertyCreationModal: true, propertyCreationType: key });
    } else {
      if (!e.object) {
        finishedProduct.properties = finishedProduct.properties.filter((p) => p.type !== key);
      } else {
        const index = finishedProduct.properties.findIndex((p) => p.type === key);
        if (index > -1) finishedProduct.properties.splice(index, 1, e.object);
        else finishedProduct.properties.push(e.object);
      }
      this.setState({ finishedProductEdit: finishedProduct, showPropertyCreationModal });
    }
  };

  handleEditFinishedProductListProperty = (e: Array<SelectOption<Property>>, key: PropertyType) => {
    const finishedProduct = _.cloneDeep(this.state.finishedProductEdit);
    finishedProduct.properties = finishedProduct.properties.filter((p) => p.type !== key);
    for (let i = 0; i < e.length; i++) {
      const obj = e[i].object;
      if (obj) finishedProduct.properties.push(obj);
    }
    this.setState({ finishedProductEdit: finishedProduct });
  };

  handleEditVATPercentage = (e: SelectOption) => {
    const finishedProduct = _.cloneDeep(this.state.finishedProductEdit);
    finishedProduct.vatPercentage = Number(e.value);
    this.setState({ finishedProductEdit: finishedProduct });
  };

  handleClickGenerateSubtitle = () => {
    const finishedProduct = _.cloneDeep(this.state.finishedProductEdit);
    finishedProduct.subtitle.en = generateSubtitle(finishedProduct);
    this.setState({ finishedProductEdit: finishedProduct });
  };

  handleEditFinishedProductSelectCountry = (e: SelectOption) => {
    const finishedProduct = _.cloneDeep(this.state.finishedProductEdit);
    finishedProduct.country = { code: e.value, name: e.label };
    this.setState({ finishedProductEdit: finishedProduct });
  };

  handleEditFinishedProductOrganic = () => {
    const finishedProduct = _.cloneDeep(this.state.finishedProductEdit);
    finishedProduct.organic = !finishedProduct.organic;
    this.setState({ finishedProductEdit: finishedProduct });
  };

  handleEditFlagValue = (field: "veg" | "law", value: string) => {
    const finishedProduct = _.cloneDeep(this.state.finishedProductEdit);
    if (field === "veg") {
      finishedProduct.vegan = value === "vegan";
      finishedProduct.vegetarian = value !== "none";
    } else if (field === "law") {
      finishedProduct.kosher = ["kosher", "both"].includes(value);
      finishedProduct.halal = ["halal", "both"].includes(value);
    }
    this.setState({ finishedProductEdit: finishedProduct });
  };

  handleEditGrades = (values: Array<SelectOption>) => {
    const finishedProduct = _.cloneDeep(this.state.finishedProductEdit);
    const vString = values.map((v) => v.value);
    finishedProduct.foodGrade = vString.includes("food");
    finishedProduct.pharmaceuticalGrade = vString.includes("pharmaceutical");
    finishedProduct.feedGrade = vString.includes("feed");
    finishedProduct.cosmeticGrade = vString.includes("cosmetic");
    finishedProduct.uspGrade = vString.includes("usp");
    finishedProduct.medicineGrade = vString.includes("medicine");
    finishedProduct.industrialGrade = vString.includes("industrial");
    this.setState({ finishedProductEdit: finishedProduct });
  };

  handleSelectProperty = (property: Property) => {
    const finishedProductEdit = _.cloneDeep(this.state.finishedProductEdit);
    if (!finishedProductEdit.properties.some((p) => p._id.toString() === property._id.toString())) {
      finishedProductEdit.properties.push(property);
      this.setState({ finishedProductEdit });
    }
  };

  handleRemoveProperty = (property: Property) => {
    const finishedProductEdit = _.cloneDeep(this.state.finishedProductEdit);
    finishedProductEdit.properties = finishedProductEdit.properties.filter(
      (p) => p._id.toString() !== property._id.toString()
    );
    this.setState({ finishedProductEdit });
  };

  handleFinishedProductDisabledFlag = async (disable: boolean) => {
    const { finishedProduct } = this.props;
    if (!finishedProduct) return;
    const timelineEntry = getFinishedProductTimelineEntry(
      disable ? T_FINISHEDPRODUCTDISABLED : T_FINISHEDPRODUCTENABLED
    );
    const result = await updateFinishedProduct({ disabled: disable }, finishedProduct._id, timelineEntry);
    if (result && result.modifiedCount > 0) {
      toast.success("Finished product " + (disable ? "disabled" : "enabled") + " successfully");
    } else {
      toast.error("Error " + (disable ? "disabling" : "enabling") + " finished product");
    }
  };

  handleApproveFinishedProduct = async () => {
    const { finishedProduct } = this.props;
    if (!finishedProduct) return;
    const timelineEntry = getFinishedProductTimelineEntry(T_FINISHEDPRODUCTAPPROVED);
    const result = await updateFinishedProduct({ approved: true }, finishedProduct._id, timelineEntry);
    if (result && result.modifiedCount > 0) {
      toast.success("Finished product approved successfully");
    } else {
      toast.error("Error approving finished product");
    }
  };

  handleAddActiveSubstance = (activeSubstance: ActiveSubstance, percentage: number) => {
    const finishedProductEdit = _.cloneDeep(this.state.finishedProductEdit);
    finishedProductEdit.activeSubstances.push({ substance: activeSubstance, percentage });
    this.setState({ finishedProductEdit });
  };

  handleEditActiveSubstance = (idx: number, activeSubstance: ActiveSubstance) => {
    const finishedProductEdit = _.cloneDeep(this.state.finishedProductEdit);
    finishedProductEdit.activeSubstances[idx].substance = activeSubstance;
    this.setState({ finishedProductEdit });
  };

  handleEditActiveSubstancePercentage = (id: string, percentage: number) => {
    const finishedProductEdit = _.cloneDeep(this.state.finishedProductEdit);
    const aS = finishedProductEdit.activeSubstances.find((aS) => aS.substance._id.toString() === id);
    if (!aS) return;
    aS.percentage = percentage;
    this.setState({ finishedProductEdit });
  };

  handleRemoveActiveSubstance = (id: string) => {
    const finishedProductEdit = _.cloneDeep(this.state.finishedProductEdit);
    finishedProductEdit.activeSubstances = finishedProductEdit.activeSubstances.filter(
      (aS) => aS.substance._id.toString() !== id
    );
    this.setState({ finishedProductEdit });
  };

  handleAddPackagingSize = () => {
    const finishedProductEdit = _.cloneDeep(this.state.finishedProductEdit);
    if (!finishedProductEdit.packagingSizes) finishedProductEdit.packagingSizes = [];
    finishedProductEdit.packagingSizes.push(getDefaultPackagingSize());
    this.setState({ finishedProductEdit });
  };

  handleEditPackagingSize = (idx: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const finishedProductEdit = _.cloneDeep(this.state.finishedProductEdit);
    const { value, name } = e.target;
    if (!finishedProductEdit.packagingSizes) return;
    _.set(finishedProductEdit.packagingSizes[idx], name, Number(value));
    this.setState({ finishedProductEdit });
  };

  handleEditPackagingType = (idx: number, e: SelectOption) => {
    const finishedProductEdit = _.cloneDeep(this.state.finishedProductEdit);
    if (!finishedProductEdit.packagingSizes) return;
    finishedProductEdit.packagingSizes[idx].type = e.value;
    this.setState({ finishedProductEdit });
  };

  handleRemovePackagingSize = (idx: number) => {
    const finishedProductEdit = _.cloneDeep(this.state.finishedProductEdit);
    if (!finishedProductEdit.packagingSizes) return;
    finishedProductEdit.packagingSizes.splice(idx, 1);
    this.setState({ finishedProductEdit });
  };

  handleCreateFinishedProduct = async () => {
    const finishedProductEdit = _.cloneDeep(this.state.finishedProductEdit);
    finishedProductEdit.timeline = [getFinishedProductTimelineEntry(T_FINISHEDPRODUCTCREATED)];
    const result = await insertFinishedProduct(reduceFinishedProduct(finishedProductEdit) as FinishedProduct);
    if (result && result.insertedId) {
      toast.success("Finished product created successfully");
      this.setState({ edit: false }, () => this.props.history.push("/finishedProduct/" + result.insertedId.toString()));
    } else {
      toast.error("Error creating finished product");
    }
    COMMODITYCREATIONKEYS.forEach((key) => localStorage.removeItem(key));
  };

  handleSaveFinishedProduct = async () => {
    const { finishedProduct } = this.props;
    const { finishedProductEdit } = this.state;
    if (!finishedProductEdit) return;
    this.setState({ saving: true });
    try {
      const fp = reduceFinishedProduct(finishedProductEdit) as FinishedProduct;
      const timelineEntry = getFinishedProductEditTimelineEntry(fp, reduceFinishedProduct(finishedProduct));
      // Omit timeline to not get a conflict due to set and push on timeline
      const result = await updateFinishedProduct(_.omit(fp, "timeline", "suppliers", ""), undefined, timelineEntry);
      if (result && result.modifiedCount > 0) {
        toast.success("Finished product data updated successfully");
        this.setState({ edit: false });
      } else {
        toast.error("Error updating finished product");
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  handleAddTransportCondition = () => {
    const finishedProductEdit = _.cloneDeep(this.state.finishedProductEdit);
    if (!finishedProductEdit.transportConditions) finishedProductEdit.transportConditions = [];
    finishedProductEdit.transportConditions.push({
      type: TC_TEMPERATURE,
      property:
        this.props.context.property.find((p) => p.type === PropertyType.TRANSPORTCONDITIONS)?._id.toString() || "",
    });
    this.setState({ finishedProductEdit });
  };

  handleRemoveTransportCondition = (idx: number) => {
    const finishedProductEdit = _.cloneDeep(this.state.finishedProductEdit);
    if (!finishedProductEdit.transportConditions) return;
    finishedProductEdit.transportConditions.splice(idx, 1);
    this.setState({ finishedProductEdit });
  };

  handleEditTransportConditionType = (idx: number, e: SelectOption) => {
    const finishedProductEdit = _.cloneDeep(this.state.finishedProductEdit);
    if (!finishedProductEdit.transportConditions) return;
    finishedProductEdit.transportConditions[idx].type = e.value as TRANSPORTCONDITIONTYPE;
    this.setState({ finishedProductEdit });
  };

  handleEditTransportCondition = (idx: number, e: TransportConditionSelect) => {
    const finishedProductEdit = _.cloneDeep(this.state.finishedProductEdit);
    if (!finishedProductEdit.transportConditions) return;
    finishedProductEdit.transportConditions[idx].property = e.value;
    this.setState({ finishedProductEdit });
  };

  /**
   * Validate finished product data
   * @param approve optional, flag if it should be specifically checked for approval
   */
  validateFinishedProductData = (approve?: boolean) => {
    const finishedProduct = approve ? this.props.finishedProduct : this.state.finishedProductEdit;
    const { title, hsCode, properties, vegetarian, vegan, shelfLife } = finishedProduct;
    const errors: Array<string> = [];
    if (title.en.trim().length < 3) errors.push("Finished product name too short");
    if (hsCode.trim().length < 6) errors.push("HS Code missing or malformed");
    if (!properties.some((p) => p.type === PropertyType.COMPOSITION))
      errors.push("Finished product composition not set");
    if (!properties.some((p) => p.type === PropertyType.CATEGORY)) errors.push("Finished product category not set");
    if (!vegetarian && vegan) errors.push("Vegan finished products have to be vegetarian as well"); // Should not be possible but as a sanity check
    if (!shelfLife) errors.push("Shelf life has to be set");
    if (!finishedProduct.unit) errors.push("Unit is not set");
    if (finishedProduct.packagingSizes?.some((pS) => pS.packagingSize === 0)) {
      errors.push("Packaging sizes of size 0 are not allowed");
    }
    if (finishedProduct.weightPerUnit <= 0) errors.push("Weight per Unit must be a positive");
    if (
      finishedProduct.packagingSizes?.some(
        (pS) => pS.dimension.length <= 0 || pS.dimension.height <= 0 || pS.dimension.width <= 0
      )
    ) {
      errors.push("Length, height and width of dimension must be positive");
    }
    if (
      finishedProduct.packagingSizes?.some((pSo) =>
        finishedProduct.packagingSizes?.some(
          (pSi) =>
            pSo.type === pSi.type &&
            pSo.packagingSize === pSi.packagingSize &&
            pSo._id.toString() !== pSi._id.toString()
        )
      )
    ) {
      errors.push("Duplicates in packaging sizes");
    }
    return errors;
  };

  render() {
    const clone = !!new URLSearchParams(this.props.location.search).get("clone");
    const { finishedProduct, context } = this.props;
    const { edit, finishedProductEdit, create, showPropertyCreationModal, propertyCreationType, search, saving } =
      this.state;
    const { property } = context;
    const errors = this.validateFinishedProductData();
    const approvalErrors = this.validateFinishedProductData(true);
    return (
      <>
        <TableOfContents contents={this.tableOfContents} />
        <div className="card bg-white">
          {edit && (
            <CreatePropertyModal
              onlyModal={true}
              show={showPropertyCreationModal}
              onHide={() => this.setState({ showPropertyCreationModal: false })}
              type={
                PROPERTYOPTIONS.find((po) => po.value === propertyCreationType) as {
                  value: PropertyType;
                  label: string;
                }
              }
            />
          )}
          <div className="card-header border-0 mt-5 d-block">
            <div className="card-title flex-column">
              <div className="row w-100">
                <div className="col-6">
                  <h2 className="mb-1">{create ? "Create New Finished Product" : "General Information"}</h2>
                  <div className="fs-6 fw-bold text-muted">
                    {finishedProduct.title.en}
                    {finishedProduct && finishedProduct.subtitle.en.trim() ? `-${finishedProduct.subtitle.en}` : ""}
                  </div>
                </div>
                <div className="col-6">
                  <div className="pt-3 float-right">
                    <div className="d-flex pt-3 align-items-center w-100">
                      {create ? (
                        <>
                          <button
                            className={
                              "btn btn-outline btn-outline-light btn-sm float-right " + (!finishedProduct && "disabled")
                            }
                            disabled={!finishedProduct}
                            onClick={this.handleCancelCreate}
                          >
                            Cancel
                          </button>
                          {clone ? (
                            <CloneCommodityModal
                              originalCommodity={finishedProduct}
                              clonedCommodity={finishedProductEdit}
                              onHandleCreate={this.handleCreateFinishedProduct}
                            />
                          ) : (
                            <ErrorOverlayButton
                              errors={errors}
                              className={"btn btn-outline btn-outline-light btn-sm float-right ml-2"}
                              buttonText={"Create Finished Product"}
                              onClick={this.handleCreateFinishedProduct}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          {edit ? (
                            <>
                              <button
                                className={
                                  "btn btn-outline btn-outline-light btn-sm float-right " +
                                  (!finishedProduct && "disabled")
                                }
                                disabled={!finishedProduct}
                                onClick={this.handleEditToggle}
                              >
                                Cancel
                              </button>
                              <ErrorOverlayButton
                                errors={finishedProduct.approved ? errors : []}
                                saving={saving}
                                className="btn btn-outline btn-outline-light btn-sm float-right ml-2"
                                buttonText="Save Finished Product"
                                disabled={finishedProduct.disabled}
                                onClick={this.handleSaveFinishedProduct}
                              />
                            </>
                          ) : (
                            <>
                              <button
                                className={
                                  "btn btn-outline btn-outline-light btn-sm float-right ml-2 " +
                                  (finishedProduct.disabled && "disabled")
                                }
                                disabled={finishedProduct.disabled}
                                onClick={finishedProduct.disabled ? undefined : this.handleCloneFinishedProduct}
                              >
                                Clone Finished Product
                              </button>
                              <button
                                className={
                                  "btn btn-outline btn-outline-light btn-sm float-right ml-2 " +
                                  (finishedProduct.disabled && "disabled")
                                }
                                disabled={finishedProduct.disabled}
                                onClick={finishedProduct.disabled ? undefined : this.handleEditToggle}
                              >
                                Edit Finished Product
                              </button>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body p-9 pt-4">
            <div className="pt-0">
              <FinishedProductPageGeneralInformationBlock
                finishedProduct={edit || !finishedProduct ? finishedProductEdit : finishedProduct}
                edit={edit}
                context={context}
                onEditFinishedProductInput={this.handleEditFinishedProductInput}
                onEditFinishedProductSelectExclusiveProperty={this.handleEditFinishedProductSelectExclusiveProperty}
                onEditFinishedProductListProperty={this.handleEditFinishedProductListProperty}
                onEditFinishedProductSelectCountry={this.handleEditFinishedProductSelectCountry}
                onEditFinishedProductOrganic={this.handleEditFinishedProductOrganic}
                onClickGenerateSubtitle={this.handleClickGenerateSubtitle}
                onAddPackagingSize={this.handleAddPackagingSize}
                onEditPackagingSize={this.handleEditPackagingSize}
                onEditPackagingType={this.handleEditPackagingType}
                onRemovePackagingSize={this.handleRemovePackagingSize}
                onAddTransportCondition={this.handleAddTransportCondition}
                onRemoveTransportCondition={this.handleRemoveTransportCondition}
                onEditTransportCondition={this.handleEditTransportCondition}
                onEditTransportConditionType={this.handleEditTransportConditionType}
                onEditVATPercentage={this.handleEditVATPercentage}
              />
              <div className="border-bottom-dark-gray" />
              <div id="advancedInformation" className="row w-100 align-items-center">
                <div className="col-6">
                  <div className="text-white fs-5 fw-bolder">Advanced Information</div>
                  <div className="text-muted">Detailed Properties</div>
                </div>
                <div className="col-6 my-4">
                  <Search onSearch={this.handleChangeSearch} value={search} placeholder="Search for properties..." />
                </div>
              </div>
              {FP_G_TYPES.map((t, idx) => (
                <React.Fragment key={t}>
                  {idx !== 0 && <div className="border-bottom-dark-gray" />}
                  <FinishedProductPageAdvancedInformationBlock
                    finishedProduct={edit || !finishedProduct ? finishedProductEdit : finishedProduct}
                    edit={edit}
                    context={context}
                    onEditFinishedProductInput={this.handleEditFinishedProductInput}
                    onEditFinishedProductSelectExclusiveProperty={this.handleEditFinishedProductSelectExclusiveProperty}
                    onEditFlagValue={this.handleEditFlagValue}
                    onEditGrades={this.handleEditGrades}
                    type={t}
                    search={search}
                  />
                </React.Fragment>
              ))}
              <div className="border-bottom-dark-gray" />
              <div id="activeSubstances" className="py-0">
                <div className="d-flex py-6 align-items-center w-100">
                  <div className="me-3">
                    <div className="text-white fs-5 fw-bolder">Active Substances</div>
                    <div className="text-muted">Substances like vitamins and their concentration</div>
                  </div>
                </div>
                {edit ? (
                  <CommodityActiveSubstanceEditListing
                    commodity={finishedProductEdit}
                    activeSubstances={context.activeSubstance}
                    onAddActiveSubstance={this.handleAddActiveSubstance}
                    onEditActiveSubstancePercentage={this.handleEditActiveSubstancePercentage}
                    onEditActiveSubstance={this.handleEditActiveSubstance}
                    onRemoveActiveSubstance={this.handleRemoveActiveSubstance}
                  />
                ) : (
                  <div className="py-5">
                    <CommodityActiveSubstanceListing article={finishedProduct} />
                  </div>
                )}
              </div>
              <div className="border-bottom-dark-gray" />
              <div id="allergens" className="py-0">
                <div className="d-flex py-6 align-items-center w-100">
                  <div className="me-3">
                    <div className="text-white fs-5 fw-bolder">Allergens</div>
                    <div className="text-muted">Substances possibly causing allergic reactions</div>
                  </div>
                </div>
                {edit ? (
                  <div className="py-5">
                    <PropertiesSelection
                      selectedProperties={finishedProductEdit.properties}
                      properties={property}
                      types={[PropertyType.ALLERGEN]}
                      onSelectProperty={this.handleSelectProperty}
                      onRemoveProperty={this.handleRemoveProperty}
                      searchPlaceholder={"Search for allergens..."}
                    />
                  </div>
                ) : (
                  <div className="py-5">
                    <PropertiesWidget
                      properties={finishedProduct?.properties || []}
                      allProperties={property}
                      allowShowMore={true}
                      types={[PropertyType.ALLERGEN]}
                      placeholder={"No allergens for this finished product"}
                    />
                  </div>
                )}
              </div>
              <div className="border-bottom-dark-gray" />
              <div id="tags" className="py-0">
                <div className="d-flex py-6 align-items-center w-100">
                  <div className="me-3">
                    <div className="text-white fs-5 fw-bolder">Tags</div>
                    <div className="text-muted">Keywords &amp; Additional Properties</div>
                  </div>
                </div>
                {edit ? (
                  <div className="py-5">
                    <PropertiesSelection
                      selectedProperties={finishedProductEdit.properties}
                      properties={property}
                      types={[PropertyType.TAG, PropertyType.OTHER]}
                      onSelectProperty={this.handleSelectProperty}
                      onRemoveProperty={this.handleRemoveProperty}
                      searchPlaceholder={"Search for tags and other properties..."}
                    />
                  </div>
                ) : (
                  <div className="py-5">
                    <PropertiesWidget
                      properties={finishedProduct?.properties || []}
                      allProperties={property}
                      allowShowMore={true}
                      types={[PropertyType.TAG, PropertyType.OTHER]}
                      placeholder={"No tags for this finished product"}
                    />
                  </div>
                )}
              </div>
              {create ? (
                <>
                  <div className="border-bottom-dark-gray" />
                  <div className="pt-6">
                    <ErrorOverlayButton
                      errors={errors}
                      className={"btn btn-outline btn-outline-light btn-sm float-right ml-2"}
                      buttonText={"Create Finished Product"}
                      onClick={this.handleCreateFinishedProduct}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="border-bottom-dark-gray" />
                  <MasterSpecification
                    article={{
                      type: FINISHEDPRODUCT,
                      finishedProduct: edit || !finishedProduct ? finishedProductEdit : finishedProduct,
                    }}
                    context={context}
                  />
                  <div className="border-bottom-dark-gray" />
                </>
              )}
              {finishedProduct && !create && (
                <div className="pt-3">
                  <div className="d-flex pt-3 align-items-center w-100">
                    {!finishedProduct.approved && (
                      <ErrorOverlayButton
                        errors={approvalErrors}
                        className="btn btn-text btn-text-success btn-sm"
                        buttonText="Approve Finished Product"
                        disabled={finishedProduct.disabled}
                        onClick={this.handleApproveFinishedProduct}
                      />
                    )}
                    {finishedProduct.disabled ? (
                      <button
                        className="btn btn-text btn-text-success btn-sm ml-auto"
                        onClick={() => this.handleFinishedProductDisabledFlag(false)}
                      >
                        Enable Finished Product
                      </button>
                    ) : (
                      <button
                        className="btn btn-text btn-text-danger btn-sm ml-auto"
                        onClick={() => this.handleFinishedProductDisabledFlag(true)}
                      >
                        Disable Finished Product
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(FinishedProductPageGeneral);
